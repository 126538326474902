<template>
  <div class="container">
    <div
      class="pane"
      v-for="resource in ['questions', 'rules', 'templates']"
      :class="resource"
      :key="resource"
    >
      <div class="subheader" v-if="initialized">
        <div class="title">
          <!--Wijzigingen in {{resource}} in versie {{version.version}}-->
          <!--{{version.open ? '' : '- ' + dateTime(version.updated_at)}}-->
          <!--{{version.message ? '- '+version.message : ''}}-->
          {{
            resource === 'questions'
              ? 'Vragen'
              : resource === 'rules'
              ? 'Regels'
              : 'Document'
          }}
        </div>
      </div>

      <div class="scroller" v-if="initialized">
        <div class="content">
          <template v-if="resource === 'questions'">
            <template
              v-if="
                changes.questionsections.length > 0 ||
                changes.questions.length > 0
              "
            >
              <div class="list" v-if="changes.questionsections.length > 0">
                <div
                  v-for="(section, index) in changes.questionsections"
                  :key="section.id"
                  class="change"
                >
                  <router-link
                    :to="{
                      name: 'questionssections',
                      params: { versionId: version.id, sectionId: section.id },
                    }"
                    >Sectie: {{ languageValue(section.title) }}
                  </router-link>
                  <button
                    class="btn btn-invisible"
                    v-if="version.open"
                    @click="undoQuestionSection(section, index)"
                    title="Wijzigingen ongedaan maken"
                  >
                    <font-awesome-icon class="icon" icon="undo" />
                  </button>
                </div>
              </div>
              <div class="list" v-if="changes.questions.length > 0">
                <div
                  v-for="(question, index) in changes.questions"
                  :key="question.id"
                  class="change"
                >
                  <router-link
                    :to="{
                      name: 'questions',
                      params: {
                        versionId: version.id,
                        sectionId: question.section
                          ? question.section.id
                          : null,
                        questionId: question.id,
                      },
                    }"
                    >#V{{ question.id }} -
                    {{ languageValue(question.text, { truncate: 100 }) }}
                  </router-link>
                  <button
                    class="btn btn-invisible"
                    v-if="version.open"
                    @click="undoQuestion(question, index)"
                    title="Wijzigingen ongedaan maken"
                  >
                    <font-awesome-icon class="icon" icon="undo" />
                  </button>
                </div>
              </div>
            </template>
            <div v-else class="center">
              De vragen zijn niet gewijzigd in versie {{ version.version }}
            </div>
          </template>

          <template v-if="resource === 'rules'">
            <template
              v-if="changes.rulesections.length > 0 || changes.rules.length > 0"
            >
              <div class="list" v-if="changes.rulesections.length > 0">
                <div
                  v-for="(section, index) in changes.rulesections"
                  :key="section.id"
                  class="change"
                >
                  <router-link
                    :to="{
                      name: 'rulessections',
                      params: { versionId: version.id, sectionId: section.id },
                    }"
                    >Sectie: {{ section.label }}
                  </router-link>
                  <button
                    class="btn btn-invisible"
                    v-if="version.open"
                    @click="undoRuleSection(section, index)"
                    title="Wijzigingen ongedaan maken"
                  >
                    <font-awesome-icon class="icon" icon="undo" />
                  </button>
                </div>
              </div>
              <div class="list" v-if="changes.rules.length > 0">
                <div
                  v-for="(rule, index) in changes.rules"
                  :key="rule.id"
                  class="change"
                >
                  <router-link
                    :to="{
                      name: 'rules',
                      params: {
                        versionId: version.id,
                        sectionId: rule.section ? rule.section.id : null,
                        ruleId: rule.id,
                      },
                    }"
                    >#R{{ rule.id }} - {{ rule.label }}
                  </router-link>
                  <button
                    class="btn btn-invisible"
                    v-if="version.open"
                    @click="undoRule(rule, index)"
                    title="Wijzigingen ongedaan maken"
                  >
                    <font-awesome-icon class="icon" icon="undo" />
                  </button>
                </div>
              </div>
            </template>
            <div v-else class="center">
              De regels zijn niet gewijzigd in versie {{ version.version }}
            </div>
          </template>

          <template v-if="resource === 'templates'">
            <template
              v-if="
                changes.templatesections.length > 0 ||
                changes.templates.length > 0
              "
            >
              <div class="list" v-if="changes.templatesections.length > 0">
                <div
                  v-for="(section, index) in changes.templatesections"
                  :key="section.id"
                  class="change"
                >
                  <router-link
                    :to="{
                      name: 'templatessections',
                      params: { versionId: version.id, sectionId: section.id },
                    }"
                    >Sectie: {{ languageValue(section.label) }}
                  </router-link>
                  <button
                    class="btn btn-invisible"
                    v-if="version.open"
                    @click="undoTemplateSection(section, index)"
                    title="Wijzigingen ongedaan maken"
                  >
                    <font-awesome-icon class="icon" icon="undo" />
                  </button>
                </div>
              </div>
              <div class="list" v-if="changes.templates.length > 0">
                <div
                  v-for="(template, index) in changes.templates"
                  :key="template.id"
                  class="change"
                >
                  <router-link
                    :to="{
                      name: 'templates',
                      params: {
                        versionId: version.id,
                        sectionId: template.section
                          ? template.section.id
                          : null,
                        templateId: template.id,
                      },
                    }"
                    >#D{{ template.id }} - {{ languageValue(template.label) }}
                  </router-link>
                  <button
                    class="btn btn-invisible"
                    v-if="version.open"
                    @click="undoTemplate(template, index)"
                    title="Wijzigingen ongedaan maken"
                  >
                    <font-awesome-icon class="icon" icon="undo" />
                  </button>
                </div>
              </div>
            </template>
            <div v-else class="center">
              Het document is niet gewijzigd in versie {{ version.version }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import api from '@/api';
import moment from 'moment';
import { supportedLanguages, useGetValue } from '@/languages';

const languageValue = useGetValue(supportedLanguages);

export default {
  props: ['version', 'language'],
  data() {
    return {
      changes: null,
    };
  },
  created() {
    this.load();
  },
  computed: {
    initialized() {
      return this.changes;
    },
    hasChanges() {
      return (
        this.changes.questions.length > 0 ||
        this.changes.questionsections.length > 0 ||
        this.changes.rules.length > 0 ||
        this.changes.rulesections.length > 0 ||
        this.changes.templates.length > 0 ||
        this.changes.templatesections.length > 0
      );
    },
  },
  methods: {
    load() {
      this.changes = null;
      api
        .getChanges(this.version.id)
        .then((response) => (this.changes = response.data));
    },
    truncate(value, length) {
      return value && value.length > length
        ? value.substr(0, length - 3) + '...'
        : value;
    },
    dateTime(value) {
      return moment(value).format('D MMM HH:mm');
    },
    undo(description, confirm) {
      this.$emit('popup', {
        title: 'Wijzigingen in ' + description + ' ongedaan maken?',
        content: 'Let op: deze wijzigingen gaan permanent verloren.',
        image: 'question',
        cancel: () => this.$emit('popup', null),
        confirm,
      });
    },
    undoQuestion(question, index) {
      this.undo('#V' + question.id, () => {
        api.undoQuestion(this.version, question).then(() => {
          this.$emit('popup', null);
          this.changes.questions.splice(index, 1);
        });
      });
    },
    undoQuestionSection(section, index) {
      this.undo('sectie ' + section.title, () => {
        this.$emit('popup', null);
        api.undoQuestionSection(this.version, section).then(() => {
          this.$emit('popup', null);
          this.changes.questionsections.splice(index, 1);
        });
      });
    },
    undoRule(rule, index) {
      this.undo('#R' + rule.id, () => {
        this.$emit('popup', null);
        api.undoRule(this.version, rule).then(() => {
          this.$emit('popup', null);
          this.changes.rules.splice(index, 1);
        });
      });
    },
    undoRuleSection(section, index) {
      this.undo('sectie ' + section.label, () => {
        this.$emit('popup', null);
        api.undoRuleSection(this.version, section).then(() => {
          this.$emit('popup', null);
          this.changes.rulesections.splice(index, 1);
        });
      });
    },
    undoTemplate(template, index) {
      this.undo('#D' + template.id, () => {
        this.$emit('popup', null);
        api.undoTemplate(this.version, template).then(() => {
          this.$emit('popup', null);
          this.changes.templates.splice(index, 1);
        });
      });
    },
    undoTemplateSection(section, index) {
      this.undo('sectie ' + section.label, () => {
        this.$emit('popup', null);
        api.undoTemplateSection(this.version, section).then(() => {
          this.$emit('popup', null);
          this.changes.templatesections.splice(index, 1);
        });
      });
    },
    languageValue(item) {
      return languageValue(this.language, item ?? {});
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  display: flex;
  flex-direction: row;

  .pane {
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .pane.rules {
    width: 34%;
  }

  .pane.questions,
  .pane.rules {
    border-right: solid 1px $bg-color-border;
  }

  .scroller {
    overflow-y: auto;

    .content {
      padding-left: 20px;
      height: 100%;

      .list {
        padding: 15px 0 0 0;

        .change {
          padding: 3px;
          display: flex;
          flex-direction: row;

          a {
            font-size: 14px;
            text-decoration: none;
            color: black;
            cursor: pointer;
          }

          a:hover {
            text-decoration: underline;
          }

          button {
            padding: 0 8px;
          }
        }
      }
    }
  }
}
</style>
