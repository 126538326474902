<template>
  <div class="select-field input-rule">
    <v-select
      v-model="newValue"
      :options="options"
      @input="(v) => $emit('input', v)"
      :clearable="true"
      :multiple="multiple"
      :searchable="searchable"
    />
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
    placeholder: String,
    label: Function,
    value: [Array, Object],
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newValue: this.value,
    };
  },
  watch: {
    newValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped lang="scss">
.select-field {
  width: 100%;
  min-width: 10rem;
  align-items: center;
}

.input-option {
  width: 100%;
}

.button {
  * + * {
    padding-left: 0.5em;
  }
}
</style>
