import Vue from 'vue';
import VueRouter from 'vue-router';

import layout from '@/components/Layout.vue';
import sections from '@/components/Sections.vue';
import questions from '@/components/Questions.vue';
import question from '@/components/Question.vue';
import rules from '@/components/Rules.vue';
import rule from '@/components/Rule.vue';
import templates from '@/components/Templates.vue';
import template from '@/components/Template.vue';

import exportfields from '@/components/ExportFields.vue';
import exportfield from '@/components/ExportField.vue';

import files from '@/components/Files.vue';
import file from '@/components/File.vue';

import editSections from '@/components/EditSections.vue';
import editSection from '@/components/EditSection.vue';
import login from '@/components/Login.vue';
import twofactor from '@/components/TwoFactor.vue';

import changes from '@/components/Changes.vue';
import TenantFilter from '@/components/tenants/TenantFilter.vue';
import TenantList from '@/components/tenants/TenantList.vue';
import TenantDetails from '@/components/tenants/TenantDetails.vue';
import TestSaml from '@/components/tenants/TestSaml.vue';
import UserProfile from '@/components/UserProfile.vue';
import ConfigValidator from '@/components/config-validator/ConfigValidator.vue';
import EditTemplateSection from '@/components/EditTemplateSection.vue';

Vue.use(VueRouter);

const Router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE,
  routes: [
    {
      path: '',
      name: 'root',
      redirect: { name: 'questions' },
    },
    {
      path: '/login',
      name: 'login',
      component: login,
    },
    {
      path: '/twofactor',
      name: 'twofactor',
      component: twofactor,
    },
    {
      path: '/wijzigingen',
      component: layout,
      props: { resource: 'changes' },
      children: [
        {
          path: ':versionId?',
          name: 'changes',
          components: {
            left: changes,
          },
          props: {
            left: true,
          },
        },
      ],
    },
    {
      path: '/vragen',
      redirect: { name: 'questions' },
      component: layout,
      props: {
        resource: 'questions',
      },
      name: 'questionslayout',
      children: [
        {
          path: 'vraag/:versionId?/:sectionId?/:questionId?',
          meta: {
            languageImportResource: 'questionnaire',
          },
          name: 'questions',
          components: {
            left: sections,
            middle: questions,
            right: question,
          },
          props: {
            left: true,
            middle: true,
            right: true,
          },
        },
        {
          path: 'secties/:versionId?/:sectionId?',
          name: 'questionssections',
          meta: {
            languageImportResource: 'questionnaire',
          },
          components: {
            left: editSections,
            middle: editSection,
          },
          props: {
            left: true,
            middle: true,
          },
        },
      ],
    },
    {
      path: '/files',
      component: layout,
      redirect: { name: 'files' },
      props: { resource: 'files' },
      meta: { isReportResource: true },
      children: [
        {
          path: 'file/:sectionId?/:fileId?',
          name: 'files',

          // This triggers the report language bar
          meta: { isReportResource: true },
          components: {
            left: sections,
            middle: files,
            right: file,
          },
          props: {
            left: true,
            middle: true,
            right: true,
          },
        },
        {
          path: 'section/:sectionId?',
          name: 'filesections',
          components: {
            left: editSections,
            middle: editSection,
          },
          props: {
            left: true,
            middle: true,
          },
        },
      ],
    },

    {
      path: '/regels',
      component: layout,
      props: { resource: 'rules' },
      children: [
        {
          path: ':versionId?/:sectionId?/:ruleId?',
          name: 'rules',
          components: {
            left: sections,
            middle: rules,
            right: rule,
          },
          props: {
            left: true,
            middle: true,
            right: true,
          },
        },
      ],
    },
    {
      path: '/regelsecties',
      component: layout,
      props: { resource: 'rules', editsections: true },
      children: [
        {
          path: ':versionId?/:sectionId?',
          name: 'rulessections',
          components: {
            left: editSections,
            middle: editSection,
          },
          props: {
            left: true,
            middle: true,
          },
        },
      ],
    },
    {
      path: '/document',
      component: layout,
      props: { resource: 'templates' },
      children: [
        {
          path: ':versionId?/:sectionId?/:templateId?',
          name: 'templates',
          meta: {
            isReportResource: true,
            languageImportResource: 'templates',
          },
          components: {
            left: sections,
            middle: templates,
            right: template,
          },
          props: {
            left: true,
            middle: true,
            right: true,
          },
        },
      ],
    },
    {
      path: '/documentsecties',
      component: layout,
      props: { resource: 'templates', editsections: true },
      children: [
        {
          path: ':versionId?/:sectionId?',
          name: 'templatessections',
          meta: {
            isReportResource: true,
            languageImportResource: 'templates',
          },
          components: {
            left: editSections,
            middle: EditTemplateSection,
          },
          props: {
            left: true,
            middle: true,
          },
        },
      ],
    },

    {
      path: '/exportfields',
      component: layout,
      props: { resource: 'exports' },
      children: [
        {
          path: ':sectionId?/:fieldId?',
          name: 'exportfields',
          components: {
            left: sections,
            middle: exportfields,
            right: exportfield,
          },
          props: {
            left: true,
            middle: true,
            right: true,
          },
        },
      ],
    },
    {
      path: '/exports',
      component: layout,
      props: { resource: 'exports', editsections: true },
      children: [
        {
          path: ':sectionId?',
          name: 'exports',
          components: {
            left: editSections,
            middle: editSection,
          },
          props: {
            left: true,
            middle: true,
          },
        },
      ],
    },
    {
      path: '/tenants',
      component: layout,
      props: { resource: 'tenants' },
      children: [
        {
          path: ':tenantId?',
          name: 'tenants',
          components: {
            left: TenantFilter,
            middle: TenantList,
            right: TenantDetails,
          },
          props: {
            left: true,
            middle: true,
            right: true,
          },
        },
        {
          path: ':tenantId?/test-saml',
          name: 'tenantSamlTest',
          components: {
            left: TenantFilter,
            middle: TenantList,
            right: TestSaml,
          },
          props: {
            left: true,
            middle: true,
            right: true,
          },
        },
      ],
    },
    {
      path: '/profile',
      component: layout,
      props: { resource: 'profile' },
      children: [
        {
          path: '',
          name: 'profile',
          components: {
            left: UserProfile,
          },
        },
      ],
    },
    {
      path: '/validator',
      name: 'validator',
      component: ConfigValidator,
    },
  ],
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

Router.beforeEach((to, from, next) => {
  if (to.path !== from.path && !hasQueryParams(to) && hasQueryParams(from)) {
    const newTo = {
      ...to,
      query: from.query,
    };

    next(newTo);
  } else {
    next();
  }
});

export default Router;
