<template>
  <div class="container">
    <div class="subheader">
      <div class="title" v-if="section">
        {{ languageFallback(section.label) }}
      </div>
      <div class="buttons">
        <button
          type="button"
          class="btn btn-invisible-grey"
          @click="revertOrder"
          v-if="changed"
          title="Volgorde herstellen"
        >
          herstellen
        </button>
        <button
          type="button"
          class="btn btn-dark"
          @click="saveOrder"
          v-if="changed"
        >
          Volgorde opslaan
        </button>
      </div>
    </div>
    <div class="scroller">
      <div
        class="templates cards"
        :class="{ dragging: dragging }"
        v-if="initialized"
      >
        <draggable
          v-model="templates"
          @start="startDrag"
          @end="endDrag"
          :forceFallback="true"
          animation="300"
          ghostClass="ghost"
          delay="50"
          touchStartThreshold="30"
          :disabled="!version.open"
        >
          <transition-group
            type="transition"
            :name="dragging ? '' : 'draglist'"
          >
            <div
              class="template card"
              v-for="template in templates"
              :key="template.id"
              :class="{ active: isActive(template) }"
              @click="open(template)"
            >
              <div class="col left">
                <div class="name">
                  <span class="identifier">#D{{ template.id }}</span> -
                  {{ languageFallback(template.label) }}
                </div>
              </div>
              <div class="col right">
                <div
                  class="changed"
                  :title="
                    'Laatst gewijzigd: ' + formatDateTime(template.updated_at)
                  "
                >
                  {{ fromNow(template.updated_at) }}
                </div>
                <div class="operator">
                  <div class="badge badge-count">
                    {{ template.lines.length }}
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
        </draggable>
        <router-link
          :to="{
            name: 'templates',
            params: {
              versionId: version.id,
              sectionId: sectionId,
              templateId: 0,
            },
          }"
          class="template card new"
          :class="{ active: templateId <= 0 }"
          title="Nieuw element toevoegen"
          v-if="version.open"
        >
          <font-awesome-icon class="icon" icon="plus-circle" size="2x" />
        </router-link>
      </div>
      <div class="center" v-if="!initialized && sectionId">
        <font-awesome-icon icon="spinner" size="2x" />
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import api from '@/api';
import moment from 'moment';
import _ from 'lodash';
import { supportedReportLanguages, useGetValue } from '@/languages';

const languageValue = useGetValue(supportedReportLanguages);

export default {
  props: ['sectionId', 'templateId', 'version', 'reportLanguage'],
  data() {
    return {
      templates: null,
      originalTemplates: null,
      section: null,
      dragging: false,
    };
  },
  created() {
    this.load();
  },
  beforeRouteLeave(to, from, next) {
    this.guard(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    this.guard(to, from, next);
  },
  computed: {
    initialized() {
      return this.templates !== null;
    },
    changed() {
      return !_.isEqual(this.templates, this.originalTemplates);
    },
  },
  methods: {
    guard(to, from, next) {
      if (
        parseInt(from.params.sectionId) !== parseInt(to.params.sectionId) &&
        this.changed
      ) {
        this.$emit('popup', {
          title: 'Weet je zeker dat je wilt weggaan?',
          content:
            'De wijzigingen in de volgorde zullen niet opgeslagen worden',
          image: 'question',
          confirm: () => {
            this.$emit('popup', null);
            next();
          },
          cancel: () => {
            this.$emit('popup', null);
            next(false);
          },
        });
      } else {
        next();
      }
    },
    load() {
      if (this.sectionId) {
        api
          .getTemplatesSection(this.version, this.sectionId)
          .then((response) => {
            this.section = response.data;
            this.templates = this.section.templates;
            this.originalTemplates = _.cloneDeep(this.templates);
          });
      }
    },
    formatDateTime(value) {
      return moment(value).format('DD-MM-YYYY hh:mm:ss');
    },
    fromNow(datetime) {
      return moment(datetime).fromNow();
    },

    isActive(template) {
      return template.id === parseInt(this.templateId);
    },
    saveOrder() {
      api
        .saveTemplatesOrder(this.version, this.section, this.templates)
        .then(() => (this.originalTemplates = _.cloneDeep(this.templates)));
    },
    startDrag() {
      this.dragging = true;
    },
    endDrag() {
      this.dragging = false;
    },
    revertOrder() {
      this.templates = _.cloneDeep(this.originalTemplates);
    },
    open(template) {
      this.$router.push({
        name: 'templates',
        params: {
          versionId: this.version.id,
          sectionId: template.section.id,
          templateId: template.id,
        },
      });
    },
    languageFallback(field) {
      return languageValue(this.reportLanguage, field ?? {}, {});
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  font-family: $font-family-gitlab;
  display: flex;
  flex-direction: column;
}

.scroller {
  overflow-y: auto;
}

.templates {
  display: flex;
  flex-direction: column;
}

.draglist-move {
  transition: transform 0.7s;
}

.template.active {
  outline-style: none;
}

.template.ghost {
  background-color: $bg-color-light-grey;
  /*opacity: 0.7;*/
}

.template.new {
  color: rgba(0, 0, 0, 0.125);
  border: 1px dashed rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
}

.template {
  background-color: white;
  font-weight: 400;
  font-size: 14px;
  height: 52px;
  padding: 5px 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: black;
  cursor: pointer;

  .col.left {
    width: calc(100% - 150px);
  }

  .col.right {
    width: 150px;
  }

  .col {
    justify-content: center;
    height: 100%;
    display: flex;
    flex-direction: column;

    .name {
      width: 100%;
      color: black;
      font-size: 14px;
    }

    .changed {
      font-size: 12px;
      color: grey;
      width: 100%;
      text-align: right;
    }

    .operator {
      .badge {
        display: inline-block;
        padding: 3px 4px 3px 4px;
        font-size: 9px;
        font-weight: 700;
        line-height: 1;
        width: 14px;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        border-radius: 0.25rem;

        margin: 0 2px;
        color: white;
      }

      .badge-operator {
        background-color: green;
      }

      .badge-count {
        background-color: #919191;
      }

      padding-top: 15px;
      text-align: right;
      color: grey;
      font-size: 12px;
    }
  }
}
</style>
