<template>
  <div class="container">
    <div class="subheader">
      <div class="title">Tenants</div>
      <div class="buttons"></div>
    </div>
    <div class="scroller">
      <div class="tenants cards">
        <router-link
          v-for="tenant in tenants"
          :key="tenant.id"
          :to="{ name: 'tenants', params: { tenantId: tenant.id.toString() } }"
          class="tenant card existing"
          :class="{ active: route.params.tenantId === tenant.id.toString() }"
        >
          <span class="id">#{{ tenant.id }}</span>
          <span class="name">{{ tenant.name }}</span>
        </router-link>

        <router-link
          :to="{ name: 'tenants', params: { tenantId: 'new' } }"
          class="tenant card new"
          :class="{ active: route.params.tenantId === 'new' }"
          title="Nieuwe tenant toevoegen"
        >
          <font-awesome-icon class="icon" icon="plus-circle" size="2x" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { getTenants } from '@/api';
import { useRoute } from 'vue-router/composables';

const tenants = ref([]);
const route = useRoute();

async function loadTenants() {
  const options = {};
  if (route.query.id) {
    options.id = route.query.id;
  }

  if (route.query.name) {
    options.name = route.query.name;
  }

  tenants.value = (await getTenants(options)).data.data;
}

watch([() => route.query], loadTenants, { immediate: true });
</script>

<style scoped lang="scss">
.tenant {
  background-color: white;
  font-weight: 400;
  font-size: 14px;
  height: 52px;
  padding: 5px 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: black;
  cursor: pointer;

  &.existing {
    align-items: center;
  }

  .id {
    width: 50px;
    margin-right: 8px;
  }
}

.scroller {
  overflow-y: auto;
}

.card.router-link-exact-active,
.card.active {
  background-color: $bg-color-light-blue;
}
</style>
