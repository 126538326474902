export default [
  {},
  { value: 'patient_date_of_birth', label: 'Geboortedatum' },
  { value: 'patient_mdn', label: 'MDN' },
  { value: 'patient_bsn', label: 'BSN' },
  { value: 'patient_name', label: 'Naam' },
  { value: 'patient_gender', label: 'Geslacht' },
  { value: 'screening_created_at', label: 'Screening - aangemaakt op (oud)' },
  { value: 'screening_duration', label: 'Screening - tijdsduur' },
  { value: 'screening_doctor', label: 'Beoordelende arts' },
  {
    value: 'screening_external_identifier',
    label: 'Screening - External Identifier',
  },
  { value: 'screening_step', label: 'Screening - voortgang' },
  { value: 'cancelled_at', label: 'Geannuleerd op' },
  { value: 'patient_created_at', label: 'Patient - aangemaakt' },
  { value: 'tenant_location_full_name', label: 'Locatie - volledige naam' },
  { value: 'doctor_operation_risk', label: 'Arts - chir. risico' },
  {
    value: 'epos_screening_result',
    label: 'Advies E-POS excl IC + contact gevraagd',
  },
  {
    value: 'epos_approved',
    label: 'Advies E-POS incl IC + contact aangevraagd',
  },
  { value: 'contact_requested', label: 'Contact aangevraagd' },
  { value: 'informed_consent_given', label: 'Informed consent gegeven' },
  { value: 'patient_approved', label: 'Arts - patient goedgekeurd' },
  { value: 'surgeon', label: 'Chirurg' },
  { value: 'final_result', label: 'Eindresultaat' },
  { value: 'answered_event_at', label: 'Beantwoord op' },
  { value: 'cancel_decline_event_at', label: 'Afkeuring ongedaan gemaakt op' },
  {
    value: 'cancel_decline_event_by',
    label: 'Afkeuring ongedaan gemaakt door',
  },
  { value: 'check_event_at', label: 'Fiat gegeven op' },
  { value: 'check_event_by', label: 'Fiat gegeven door' },
  { value: 'consult_date', label: 'Consult datum' },
  { value: 'consulted_event_at', label: 'Consult uitgevoerd op' },
  { value: 'consulted_event_by', label: 'Consult uitgevoerd door' },
  { value: 'document_download_event_at', label: 'Rapport gedownload op' },
  { value: 'document_download_event_by', label: 'Rapport gedownload door' },
  { value: 'document_upload_event_at', label: 'Rapport in EPD geplaatst op' },
  { value: 'document_upload_event_by', label: 'Rapport in EPD geplaatst door' },
  { value: 'finished_event_at', label: 'Screening afgerond op' },
  { value: 'finished_event_by', label: 'Screening afgerond door' },
  { value: 'informed_consent_event_at', label: 'Informed consent afgerond op' },
  {
    value: 'informed_consent_event_by',
    label: 'Informed consent afgerond door',
  },
  { value: 'invited_event_at', label: 'Uitnodiging verstuurd op' },
  { value: 'invited_event_by', label: 'Uitnodiging verstuurd door' },
  {
    value: 'manual_informed_consent_event_at',
    label: 'Informed consent aangepast op',
  },
  {
    value: 'manual_informed_consent_event_by',
    label: 'Informed consent aangepast door',
  },
  { value: 'new_consult_event_at', label: 'Extra consult benodigd op' },
  { value: 'new_consult_event_by', label: 'Extra consult benodigd door' },
  { value: 'operation_date', label: 'Operatiedatum' },
  {
    value: 'operation_reschedule_event_at',
    label: 'Operatiedatum aangepast op',
  },
  {
    value: 'operation_reschedule_event_by',
    label: 'Operatiedatum aangepast door',
  },
  { value: 'operation_schedule_event_at', label: 'Operatiedatum ingepland op' },
  {
    value: 'operation_schedule_event_by',
    label: 'Operatiedatum ingepland door',
  },
  { value: 'prepared_event_at', label: 'Screening voorbereid op' },
  { value: 'prepared_event_by', label: 'Screening voorbereid door' },
  { value: 'screening_created_event_at', label: 'Screening - aangemaakt op' },
  { value: 'screening_created_event_by', label: 'Screening - aangemaakt door' },
  { value: 'set_final_result_event_at', label: 'Resultaat aangepast op' },
  { value: 'set_final_result_event_by', label: 'Resultaat aangepast door' },
  { value: 'is_prosthetic', label: 'Prothese ja/nee' },
  { value: 'number_of_tasks', label: 'Aantal taken' },
  { value: 'number_of_tasks_finished', label: 'Aantal taken afgerond' },
  { value: 'number_of_tasks_unfinished', label: 'Aantal taken niet afgerond' },
  { value: 'operation_name', label: 'Operatie naam/code' },
  { value: 'operation_description', label: 'Operatie omschrijving voor pt' },
  { value: 'operation_side', label: 'Operatiezijde' },
  { value: 'at_hospital', label: 'Poli / Thuis' },
  { value: 'processed_event_by', label: 'Rapport beoordeeld door' },
  { value: 'processed_event_at', label: 'Rapport beoordeeld op' },
  { value: 'schedule_event_by', label: 'Consult ingepland door' },
  { value: 'schedule_event_at', label: 'Consult ingepland op' },
  { value: 'chosen_anesthesia_method', label: 'Gekozen anesthesietechniek' },
  { value: 'asa_score', label: 'ASA score' },
];
