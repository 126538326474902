<template>
  <RootLayout :resource="props.resource" v-slot="args">
    <router-view
      :language="args.language"
      :report-language="args.reportLanguage"
      :key="args.version.id + 'left' + args.resource + '' + leftKey"
      name="left"
      class="left"
      :class="contentClass"
      @popup="args.setPopup"
      :resource="args.resource"
      :version="args.version"
      :config="args.config"
    />
    <router-view
      :language="args.language"
      :report-language="args.reportLanguage"
      :key="
        args.version.id + 'middle' + $route.params.sectionId + '' + middleKey
      "
      name="middle"
      class="middle"
      :class="contentClass"
      @popup="args.setPopup"
      :resource="args.resource"
      :version="args.version"
      :config="args.config"
      @refreshLeft="refreshLeft"
    />
    <router-view
      :language="args.language"
      :report-language="args.reportLanguage"
      :key="$route.fullPath"
      name="right"
      class="right"
      :class="contentClass"
      @refreshMiddle="refreshMiddle"
      @popup="args.setPopup"
      :resource="args.resource"
      :version="args.version"
      :config="args.config"
    />
  </RootLayout>
</template>

<script setup>
// General layout, combining the root layout with three configurable columns
/* eslint vue/multi-word-component-names: 0 */
import RootLayout from '@/components/RootLayout.vue';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router/composables';

const props = defineProps({
  resource: String,
});

const route = useRoute();

const editSections = computed(
  () => route.name?.includes('sections') || route.name === 'exports'
);
const contentClass = computed(() =>
  editSections.value ? 'sections' : props.resource
);

const leftKey = ref(0);
const middleKey = ref(0);

function refreshLeft() {
  leftKey.value++;
}

function refreshMiddle() {
  middleKey.value++;
}
</script>

<style scoped lang="scss">
.left,
.middle {
  border-right: solid 1px $bg-color-border;
}

.left,
.middle,
.right {
  height: 100%;
}

// Questions
.left.questions {
  width: 330px;
}

.middle.questions {
  width: 550px;
}

.right.questions {
  width: calc(100% - 972px);
}

// Sections
.left.sections {
  width: 400px;
}

.middle.sections {
  width: 600px;
}

// Rules
.left.rules {
  width: 330px;
}

.middle.rules {
  width: 550px;
}

.right.rules {
  width: calc(100% - 972px);
}

// Templates
.left.templates {
  width: 330px;
}

.middle.templates {
  width: 550px;
}

.right.templates {
  width: calc(100% - 972px);
}

// Exports
.left.exports {
  width: 330px;
}

.middle.exports {
  width: 550px;
}

.right.exports {
  width: calc(100% - 972px);
}

// Files
.left.files {
  width: 330px;
}

.middle.files {
  width: 450px;
}

.right.files {
  width: calc(100% - 872px);
}

// Changes
.left.changes {
  width: 100%;
}

.middle.changes {
  width: 0;
}

.right.changes {
  width: 0;
}

// Tenants
.left.tenants {
  width: 300px;
}

.middle.tenants {
  width: 380px;
}

.right.tenants {
  width: calc(100% - 772px);
}
</style>
