import axios from 'axios';

let downloadFile = (response, filename = 'vragenlijst.pdf') => {
  let contentType = response.headers['content-type'] || 'text/plain';
  let contentDisposition = response.headers['content-disposition'] || '';

  contentDisposition.split(';').forEach((setting) => {
    let entry = setting.split('=').map((s) => s.trim());
    if (entry[0] === 'filename') {
      filename = entry[1];
    }
  });

  let blob = new Blob([response.data], { type: contentType });
  let tag = document.createElement('a');

  if ('download' in tag && window.navigator.userAgent.indexOf('Edge') === -1) {
    tag.download = filename;
    let windowUrl = window.URL || window.webkitURL;
    let url = windowUrl.createObjectURL(blob);
    tag.href = url;
    tag.target = '_blank';
    document.body.appendChild(tag);
    tag.click();
    windowUrl.revokeObjectURL(url);
  } else {
    navigator.msSaveBlob(blob, filename);
  }
};

// Produces a URL for template language import / export with an optional language parameter
function getLanguageUrl(config, version, resource, language = null) {
  let url = `/version/${version.id}/translations/${resource}`;
  if (language) {
    url = `${url}?lang=${language.toLowerCase()}`;
  }

  return url;
}

// Creates a file download for a template language
export async function exportTemplateLanguage(config, version, language = null) {
  const basename = `templates_${config.name}_v${version.version}`;
  const filename = language
    ? `${basename}_${language}.json`
    : `${basename}.json`;
  const url = getLanguageUrl(config, version, 'templates', language);
  const response = await axios.get(url, { responseType: 'blob' });
  downloadFile(response, filename);
}

// Uploads template language contents
export function importTemplateLanguage(
  config,
  version,
  contents,
  language = null
) {
  const url = getLanguageUrl(config, version, 'templates', language);
  const payload = JSON.parse(contents);
  return axios.put(url, payload);
}

export async function exportQuestionnaireLanguage(
  config,
  version,
  language = null
) {
  const basename = `questionnaire_${config.name}_v${version.version}`;
  const filename = language
    ? `${basename}_${language}.json`
    : `${basename}.json`;
  const url = getLanguageUrl(config, version, 'questionnaire', language);
  const response = await axios.get(url, { responseType: 'blob' });
  downloadFile(response, filename);
}

export function importQuestionnaireLanguage(
  config,
  version,
  contents,
  language = null
) {
  const url = getLanguageUrl(config, version, 'questionnaire', language);
  const payload = JSON.parse(contents);
  return axios.put(url, payload);
}

export function getTenants(options = {}) {
  const params = {
    sort: options.sort || 'name',
  };

  if (options.id) {
    params.id = options.id;
  }

  if (options.name) {
    params.name = options.name;
  }

  return axios.get('/tenants', { params });
}

export function getTenant(id) {
  return axios.get('/tenants/' + id);
}

export function createTenant(data) {
  return axios.post('/tenants', data);
}

export function updateTenant(data) {
  const id = data.id;
  return axios.put('/tenants/' + id, data);
}

export function getConfigs() {
  return axios.get('/configs');
}

export function getWorkflows() {
  return axios.get('/tenants/workflows');
}

export function getDomains() {
  return axios.get('/tenants/domains');
}

export function generateTenantSamlResponse(data) {
  return axios.post('/tenants/saml-response', data);
}

export function getExportTemplates() {
  return axios.get('/export/templates');
}

export function login(email, password) {
  return axios.post('/login', { email, password });
}

export function twoFactor(code) {
  return axios.post('/login/two-factor', { code });
}

export function resendSms() {
  return axios.post('/login/resend-sms');
}

export function requestOTPSecret(password) {
  return axios.post('/user/otp-secret', { password });
}

export function enableAuthenticator(code) {
  return axios.put('/user/enable-authenticator', { code });
}

export function disableAuthenticator(password) {
  return axios.put('/user/disable-authenticator', { password });
}

export function updatePassword(currentPassword, newPassword) {
  return axios.put('/user/password', {
    current_password: currentPassword,
    new_password: newPassword,
  });
}

export function getProfile() {
  return axios.get('/user');
}

export function getOperators() {
  return axios.get('/rules/operators');
}

export function getRuleOutputNames() {
  return axios.get('/rules/output-names');
}

export function getRuleSections(version, rules = false) {
  const query = rules ? '?rules=1&compact=1' : '';
  return axios.get('/version/' + version.id + '/rules/sections' + query);
}

export function getRule(version, id) {
  return axios.get('/version/' + version.id + '/rule/' + id);
}

export function getRules(version) {
  return axios.get('/version/' + version.id + '/rules');
}

export function getExternalInputs() {
  return axios.get('/resources/external-inputs');
}

export function getSpecialConditions() {
  return axios.get('/resources/special-conditions');
}

export function getSpecialQuestionTypes() {
  return axios.get('/resources/special-question-types');
}

export function getQuestionsSection(version, id) {
  return axios.get('/version/' + version.id + '/questions/section/' + id);
}

export function getQuestionSections(version) {
  return axios.get('/version/' + version.id + '/questions/sections');
}

export function getQuestion(version, id) {
  return axios.get('/version/' + version.id + '/question/' + id);
}

export function getVersion(id) {
  return axios.get('/version/' + id);
}

export function evaluateVersion(tenantId, versionId) {
  return axios.post('/validate/evaluate', {
    tenant_id: tenantId,
    version_id: versionId,
  });
}

export function analyzeVersion(versionId) {
  return axios.post('/validate/analyze', {
    version_id: versionId,
  });
}

export function getTemplateSeparators() {
  return axios.get('/resources/template-separators');
}

export function getReportColumnTypes() {
  return axios.get('/resources/report-column-types');
}

export function getSupportedLanguages() {
  return axios.get('/resources/supported-languages');
}

export function getTemplatesSection(version, id) {
  return axios.get('/version/' + version.id + '/templates/section/' + id);
}

export function updateTemplateSection(version, section) {
  return axios.put(
    '/version/' + version.id + '/templates/section/' + section.id,
    section
  );
}

export function deleteTemplatesSection(version, section) {
  return axios.delete(
    '/version/' + version.id + '/templates/section/' + section.id
  );
}

export function insertTemplateSection(version, section) {
  return axios.post('/version/' + version.id + '/templates/sections', section);
}

export function updateRule(version, rule) {
  return axios.put('/version/' + version.id + '/rule/' + rule.id, rule);
}

export function insertRule(version, rule) {
  return axios.post('/version/' + version.id + '/rules', rule);
}

export default {
  uploadVersionMigration(title, filecontent, settings = {}) {
    const payload = JSON.parse(filecontent);

    return axios.post('/import/migration', { title, payload, settings });
  },
  downloadVersionMigration(config, version) {
    let filename = config.name + ' v' + version.version + '.json';
    return axios
      .get('/export/version/' + version.id + '/migration', {
        responseType: 'blob',
      })
      .then((response) => downloadFile(response, filename));
  },
  getAllQuestions() {
    return axios.get('/allversions/questions');
  },
  getAllRules() {
    return axios.get('/allversions/rules');
  },

  getExportTemplates,

  saveExportTemplatesOrder(items) {
    return axios.put('/export/templates/order', items);
  },
  getExportTemplate(id) {
    return axios.get('/export/template/' + id);
  },
  updateExportTemplate(item) {
    return axios.put('/export/template/' + item.id, item);
  },
  insertExportTemplate(item) {
    return axios.post('/export/templates', item);
  },
  deleteExportTemplate(item) {
    return axios.delete('/export/template/' + item.id);
  },
  insertExportField(item) {
    return axios.post(
      '/export/template/' + item.export_template_id + '/fields',
      item
    );
  },
  saveExportFieldsOrder(item, items) {
    return axios.put('/export/template/' + item.id + '/fields/order', items);
  },
  getExportField(id) {
    return axios.get('/export/field/' + id);
  },
  updateExportField(item) {
    return axios.put('/export/field/' + item.id, item);
  },
  deleteExportField(id) {
    return axios.delete('/export/field/' + id);
  },

  exportQuestionsPdf(version, languageCode = 'nl') {
    return axios
      .get('/export/version/' + version.id + `/questions/pdf/${languageCode}`, {
        responseType: 'blob',
      })
      .then(downloadFile);
  },

  duplicateExportTemplate(exportTemplateId, name) {
    return axios.post(`/export/template/${exportTemplateId}/duplicate`, {
      name,
    });
  },

  exportQuestionsExcel(version, languageCode = 'nl') {
    return axios
      .get(
        '/export/version/' + version.id + `/questions/excel/${languageCode}`,
        { responseType: 'blob' }
      )
      .then(downloadFile);
  },
  createConfig(data) {
    return axios.post('/configs', data);
  },
  deleteConfig(id) {
    return axios.post('/config/' + id + '/hide');
  },
  duplicateConfig(id, data) {
    return axios.post('/version/' + id + '/duplicate', data);
  },
  getChanges(id) {
    return axios.get('/version/' + id + '/changes');
  },
  closeVersion(id, message) {
    return axios.put('/version/' + id + '/close', { message });
  },
  getQuestions(version) {
    return axios.get('/version/' + version.id + '/questions');
  },
  updateQuestion(version, question) {
    return axios.put(
      '/version/' + version.id + '/question/' + question.id,
      question
    );
  },
  insertQuestion(version, question) {
    return axios.post('/version/' + version.id + '/questions', question);
  },
  deleteQuestion(version, question) {
    return axios.delete('/version/' + version.id + '/question/' + question.id);
  },

  getRuleSections,
  getRulesSection(version, id) {
    return axios.get('/version/' + version.id + '/rules/section/' + id);
  },
  getRules,
  deleteRule(version, rule) {
    return axios.delete('/version/' + version.id + '/rule/' + rule.id);
  },
  getTemplatesSection,
  getTemplateSections(version) {
    return axios.get('/version/' + version.id + '/templates/sections');
  },

  getTemplate(version, id) {
    return axios.get('/version/' + version.id + '/template/' + id);
  },
  deleteTemplate(version, template) {
    return axios.delete('/version/' + version.id + '/template/' + template.id);
  },
  updateQuestionSection(version, section) {
    return axios.put(
      '/version/' + version.id + '/questions/section/' + section.id,
      section
    );
  },
  insertQuestionSection(version, section) {
    return axios.post(
      '/version/' + version.id + '/questions/sections',
      section
    );
  },
  updateRuleSection(version, section) {
    return axios.put(
      '/version/' + version.id + '/rules/section/' + section.id,
      section
    );
  },
  insertRuleSection(version, section) {
    return axios.post('/version/' + version.id + '/rules/sections', section);
  },
  updateTemplate(version, template) {
    return axios.put(
      '/version/' + version.id + '/template/' + template.id,
      template
    );
  },
  insertTemplate(version, template) {
    return axios.post('/version/' + version.id + '/templates', template);
  },

  deleteQuestionsSection(version, section) {
    return axios.delete(
      '/version/' + version.id + '/questions/section/' + section.id
    );
  },
  deleteRulesSection(version, section) {
    return axios.delete(
      '/version/' + version.id + '/rules/section/' + section.id
    );
  },

  undoQuestion(version, question) {
    return axios.post(
      '/version/' + version.id + '/question/' + question.id + '/undo'
    );
  },
  undoQuestionSection(version, section) {
    return axios.post(
      '/version/' + version.id + '/questions/section/' + section.id + '/undo'
    );
  },
  undoRule(version, rule) {
    return axios.post('/version/' + version.id + '/rule/' + rule.id + '/undo');
  },
  undoRuleSection(version, section) {
    return axios.post(
      '/version/' + version.id + '/rules/section/' + section.id + '/undo'
    );
  },
  undoTemplate(version, template) {
    return axios.post(
      '/version/' + version.id + '/template/' + template.id + '/undo'
    );
  },
  undoTemplateSection(version, section) {
    return axios.post(
      '/version/' + version.id + '/templates/section/' + section.id + '/undo'
    );
  },

  saveQuestionsOrder(version, section, questions) {
    return axios.put(
      '/version/' + version.id + '/questions/section/' + section.id + '/order',
      questions
    );
  },
  saveRulesOrder(version, section, rules) {
    return axios.put(
      '/version/' + version.id + '/rules/section/' + section.id + '/order',
      rules
    );
  },
  saveTemplatesOrder(version, section, templates) {
    return axios.put(
      '/version/' + version.id + '/templates/section/' + section.id + '/order',
      templates
    );
  },

  saveQuestionSectionsOrder(version, sections) {
    return axios.put(
      '/version/' + version.id + '/questions/sections/order',
      sections
    );
  },
  saveRuleSectionsOrder(version, sections) {
    return axios.put(
      '/version/' + version.id + '/rules/sections/order',
      sections
    );
  },
  saveTemplateSectionsOrder(version, sections) {
    return axios.put(
      '/version/' + version.id + '/templates/sections/order',
      sections
    );
  },

  getFileSections() {
    return axios.get('/files/sections');
  },
  getFileSection(id) {
    return axios.get('/files/section/' + id);
  },
  saveFileSectionsOrder(items) {
    return axios.put('/files/sections/order', items);
  },
  updateFileSection(item) {
    return axios.put('/files/section/' + item.id, item);
  },
  insertFileSection(section) {
    return axios.post('/files/sections', section);
  },
  deleteFileSection(item) {
    return axios.delete('/files/section/' + item.id);
  },

  getTagsAvailable() {
    return axios.get('/resources/tags-available');
  },

  getFile(id) {
    return axios.get('/file/' + id);
  },
  getFiles() {
    return axios.get('/files');
  },
  updateFile(file) {
    return axios.put('/file/' + file.id, file);
  },
  insertFile(file) {
    return axios.post('/files', file);
  },
  deleteFile(id) {
    return axios.delete('/file/' + id);
  },
  saveFileOrder(item, items) {
    return axios.put('/files/order', items);
  },
};
