<template>
  <div class="container">
    <div class="subheader">
      <div class="title" v-if="section">{{ sectionTitle }}</div>
      <div class="buttons v-center">
        <SelectField
          v-if="questions"
          :options="questionTagsAvailable"
          :multiple="true"
          placeholder="Tags Filter"
          :label="(option) => option.label"
          @input="setQuestionTagFilter"
          :value="tagsSelected"
        />
        <button
          type="button"
          class="btn btn-invisible-grey"
          @click="revertOrder"
          v-if="changed"
          title="Volgorde herstellen"
        >
          herstellen
        </button>
        <button
          type="button"
          class="btn btn-dark"
          @click="saveOrder"
          v-if="changed"
        >
          Volgorde opslaan
        </button>
      </div>
    </div>
    <div class="scroller">
      <div
        class="questions cards"
        :class="{ dragging: dragging, enabled: enabled }"
        v-if="initialized"
      >
        <draggable
          v-model="questions"
          @start="startDrag"
          @end="endDrag"
          :forceFallback="true"
          animation="300"
          ghostClass="ghost"
          delay="50"
          touchStartThreshold="30"
          :disabled="!enabled"
        >
          <transition-group
            type="transition"
            :name="dragging ? '' : 'draglist'"
          >
            <div
              class="question card"
              v-for="question in questions"
              :key="question.id"
              :class="{ active: isActive(question) }"
              @click="open(question)"
            >
              <div class="row">
                <div
                  class="text"
                  :title="getLanguageValue(question, 'text')"
                  :class="{ fallback: !hasLanguageValue(question, 'text') }"
                >
                  <span class="identifier">#V{{ question.id }}</span> -
                  {{ getLanguageValue(question, 'text') }}
                </div>
                <div
                  class="changed"
                  :title="
                    'Laatst gewijzigd: ' + formatDateTime(question.updated_at)
                  "
                >
                  {{ fromNow(question.updated_at) }}
                </div>
              </div>
              <div class="row question-details">
                <div class="options" :title="joinedOptions(question, '\n')">
                  {{ joinedOptions(question) }}
                </div>
                <div class="summary">
                  <template v-if="question.condition_option">
                    <font-awesome-icon
                      @click.stop.prevent="
                        open(question.condition_option.question)
                      "
                      class="icon rotate-180"
                      :title="
                        'Dit is een vervolgvraag op #V' +
                        question.condition_option.question.id
                      "
                      icon="reply"
                      size="1x"
                    />
                    <span
                      @click.stop.prevent="
                        open(question.condition_option.question)
                      "
                      class="identifier foreign"
                      :title="
                        'Dit is een vervolgvraag op #V' +
                        question.condition_option.question.id
                      "
                      >#V{{ question.condition_option.question.id }}</span
                    >
                  </template>
                  <template
                    v-if="
                      question.condition_option_2 &&
                      (!question.condition_option ||
                        question.condition_option.question.id !==
                          question.condition_option_2.question.id)
                    "
                  >
                    <font-awesome-icon
                      class="icon rotate-180"
                      icon="reply"
                      size="1x"
                      :title="
                        'Dit is een vervolgvraag op #V' +
                        question.condition_option_2.question.id
                      "
                    />
                    <span
                      @click.stop.prevent="
                        open(question.condition_option_2.question)
                      "
                      class="identifier foreign"
                      :title="
                        'Dit is een vervolgvraag op #V' +
                        question.condition_option_2.question.id
                      "
                      >#V{{ question.condition_option_2.question.id }}</span
                    >
                  </template>

                  <font-awesome-icon
                    v-if="question.notes"
                    class="icon"
                    icon="pen"
                    :title="'Notitie: ' + question.notes"
                    size="1x"
                  />
                </div>
                <div class="tags" v-if="questionTagsAvailable">
                  <div
                    :key="'positive-tag-' + tag"
                    v-for="tag in questionTagsAsValues(question.positive_tags)"
                    class="tag positive"
                  >
                    <span>{{ tag }}</span>
                  </div>
                  <div
                    :key="'negative-tag-' + tag"
                    v-for="tag in questionTagsAsValues(question.negative_tags)"
                    class="tag negative"
                  >
                    <span>{{ tag }}</span>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
        </draggable>
        <router-link
          :to="{
            name: 'questions',
            params: {
              versionId: version.id,
              sectionId: sectionId,
              questionId: 0,
            },
          }"
          class="question card new"
          :class="{ active: questionId <= 0 }"
          title="Nieuwe vraag toevoegen"
          v-if="enabled"
        >
          <font-awesome-icon class="icon" icon="plus-circle" size="2x" />
        </router-link>
      </div>
      <div class="center" v-if="!initialized && sectionId">
        <font-awesome-icon icon="spinner" size="2x" />
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import api, { getQuestionsSection } from '@/api';
import moment from 'moment';
import types from '@/config/questionTypes';
import _, { isEmpty } from 'lodash';
import languages from '@/languages';
import SelectField from '@/components/elements/SelectField';
import promiseStore from '../promiseStore';

export default {
  components: { SelectField },
  props: ['sectionId', 'questionId', 'version', 'language'],
  data() {
    return {
      section: null,
      questions: null,
      originalQuestions: null,
      types: types,
      dragging: false,
      questionTagsAvailable: [],
      tagsSelected: [],
    };
  },
  created() {
    this.load();
  },
  computed: {
    initialized() {
      return this.questions !== null;
    },
    changed() {
      return !_.isEqual(this.questions, this.originalQuestions);
    },
    enabled() {
      return this.version.open;
    },
    sectionTitle() {
      return this.getLanguageValue(this.section, 'title');
    },
  },
  beforeRouteLeave(to, from, next) {
    this.guard(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    this.guard(to, from, next);
  },
  methods: {
    setQuestionTagFilter(tags) {
      if (!isEmpty(tags)) {
        const tagValues = tags.map((tag) => tag.value);
        this.$router.push({ query: { question_tags: tagValues.join(',') } });
      } else {
        const query = Object.assign({}, this.$route.query);
        delete query.question_tags;
        this.$router.replace({ query });
      }

      this.load();
    },
    hasLanguageValue(item, key) {
      return languages.hasValue(this.language, item, key);
    },
    getLanguageValue(item, key) {
      return languages.getValue(this.language, item, key);
    },
    guard(to, from, next) {
      if (
        parseInt(from.params.sectionId) !== parseInt(to.params.sectionId) &&
        this.changed
      ) {
        this.$emit('popup', {
          title: 'Weet je zeker dat je wilt weggaan?',
          content:
            'De wijzigingen in de volgorde zullen niet opgeslagen worden',
          image: 'question',
          confirm: () => {
            this.$emit('popup', null);
            next();
          },
          cancel: () => {
            this.$emit('popup', null);
            next(false);
          },
        });
      } else {
        next();
      }
    },
    shortType(question) {
      return this.types.find((t) => t.label === question.type).short;
    },
    /**
     * Filter questions on the current / active selected tags.
     *
     * @param questions
     * @returns {*}
     */
    filterQuestionsOnTags(questions) {
      return questions.filter((question) => {
        if (!this.tagsSelected.length) {
          return true;
        }

        return this.tagsSelected.filter((tag) => {
          return (
            question.positive_tags.includes(tag.value) ||
            question.negative_tags.includes(tag.value)
          );
        }).length;
      });
    },
    initTagsAvailable() {
      const questionTagsPromise = promiseStore.getOrSet('tags-available', () =>
        api.getTagsAvailable().then((response) => response.data)
      );

      questionTagsPromise.then((tags) => {
        const query = this.$route.query;
        const tagsAsArray = query.question_tags
          ? query.question_tags.split(',')
          : [];

        this.questionTagsAvailable = tags;

        // Make sure the value is of the selected tags are always to same as the query params reflect.
        this.tagsSelected = tagsAsArray.map((value) => {
          return tags.find((t) => value === t.value);
        });
      });
    },
    load() {
      this.initTagsAvailable();

      if (this.sectionId) {
        getQuestionsSection(this.version, this.sectionId).then((response) => {
          this.section = response.data;
          this.questions = this.filterQuestionsOnTags(this.section.questions);
          this.originalQuestions = _.cloneDeep(this.questions);
        });
      }
    },
    open(question) {
      this.$router.push({
        name: 'questions',
        params: {
          versionId: this.version.id,
          sectionId: question.section.id,
          questionId: question.id,
        },
      });
    },
    isActive(question) {
      return question.id === parseInt(this.questionId);
    },
    truncate(value, length) {
      return value.length > length
        ? value.substr(0, length - 3) + '...'
        : value;
    },
    joinedOptions(question, glue = ', ') {
      return question.options
        .map((o) => this.getLanguageValue(o, 'text'))
        .join(glue);
    },
    questionTagsAsValues(tags) {
      if (!tags || !tags.length) {
        return [];
      }

      return tags.map((tag) => {
        const f = this.questionTagsAvailable.find(
          (tagObj) => tagObj.value === tag
        );

        return f ? f.label : 'Missing:' + tag;
      });
    },
    formatDateTime(value) {
      return moment(value).format('DD-MM-YYYY hh:mm:ss');
    },
    fromNow(datetime) {
      return moment(datetime).fromNow();
    },
    startDrag() {
      this.dragging = true;
    },
    endDrag() {
      this.dragging = false;
    },
    revertOrder() {
      this.questions = _.cloneDeep(this.originalQuestions);
    },
    saveOrder() {
      api
        .saveQuestionsOrder(this.version, this.section, this.questions)
        .then(() => (this.originalQuestions = _.cloneDeep(this.questions)));
    },
  },
};
</script>

<style scoped lang="scss">
.questions:not(.dragging) {
  .question:hover:not(.active) {
    background-color: $bg-color-light-grey;
  }
}

.container {
  font-family: $font-family-gitlab;
  display: flex;
  flex-direction: column;

  border-right: solid 1px $bg-color-border;

  .scroller {
    overflow-y: auto;

    .draglist-move {
      transition: transform 0.7s;
    }

    .questions.enabled {
      .question {
        /*cursor: grab;*/
        cursor: pointer;
      }
    }

    .questions:not(.enabled) {
      .question {
        cursor: pointer;
      }
    }

    .questions {
      display: flex;
      flex-direction: column;
      background-color: white;

      .question.active {
        outline-style: none;
        background-color: $bg-color-light-blue;
      }

      .question.ghost {
        background-color: $bg-color-light-grey;
        /*opacity: 0.7;*/
      }

      .question.new {
        color: rgba(0, 0, 0, 0.125);
        border: 1px dashed rgba(0, 0, 0, 0.1);
        justify-content: center;
        align-items: center;
      }

      .question {
        background-color: white;
        padding: 5px 15px;
        font-weight: 400;
        font-size: 14px;
        height: 52px;

        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 0.25rem;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: black;

        .row {
          width: 100%;
          margin: 5px 0 5px 0;
          display: flex;
          flex-direction: row;

          &.question-details {
            justify-content: space-between;
          }

          .identifier.foreign {
            padding: 0 5px;
            color: grey;
            text-decoration: none;
          }

          .identifier:hover {
            text-decoration: underline;
          }

          .text {
            flex: 2;
          }

          .text.fallback {
            color: grey;
          }

          .options {
            flex: 2;
            font-size: 12px;
            color: grey;
          }

          .changed {
            font-size: 12px;
            color: grey;
            width: 150px;
            text-align: right;
          }

          .text,
          .notes,
          .options,
          .changed {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .summary {
            width: 150px;
            text-align: right;
            color: #9f9f9f;
            font-size: 12px;

            .icon {
              margin: 0 -2px 0 3px;
            }

            .rotate-180 {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
</style>
