<template>
  <transition name="modal">
    <div class="modal-mask" @click="emitCancel">
      <div class="modal-wrapper">
        <div class="modal-container" @click.stop.prevent="">
          <div v-if="image === 'question'" class="">
            <font-awesome-icon
              icon="question-circle"
              class="question-icon"
              size="4x"
            />
          </div>
          <div v-if="image === 'success'" class="">
            <font-awesome-icon
              icon="check-circle"
              class="success-icon"
              size="4x"
            />
          </div>
          <div v-if="image === 'error'" class="">
            <font-awesome-icon
              icon="times-circle"
              class="error-icon"
              size="4x"
            />
          </div>
          <div class="title">
            <slot name="title">{{ title }}</slot>
          </div>
          <div class="content">
            <slot name="content">{{ content }}</slot>
          </div>
          <div class="input" v-if="input">
            <input type="text" size="40" v-model="inputValue" />
          </div>
          <div class="modal-buttons">
            <slot name="buttons">
              <div class="col-12">
                <button class="action btn btn-dark" @click="emitConfirm">
                  OK
                </button>
                <button
                  v-if="cancel"
                  class="action btn btn-light"
                  @click="emitCancel"
                >
                  Annuleren
                </button>
              </div>
            </slot>
          </div>
          <div class="error row" v-if="error">{{ error }}</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
export default {
  props: [
    'image',
    'error',
    'title',
    'content',
    'cancel',
    'input',
    'report_type',
  ],
  data() {
    return {
      inputValue: '',
      new_report: false,
    };
  },
  methods: {
    emitConfirm() {
      this.$emit('confirm', {
        input: this.inputValue,
        new_report: this.new_report,
      });
    },
    emitCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss">
.modal-buttons {
  text-align: center;
  padding: 15px 0 10px 0;

  .btn {
    width: 140px !important;
    margin: 0 5px !important;
  }
}
</style>

<style scoped lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: $font-family-gitlab;
      width: 500px;
      margin: 0 auto;
      padding: 20px 30px 10px 30px;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      border-top: solid 3px $color-main;

      .questionmark {
        margin: 0 0 15px 0;
      }

      .question-icon {
        margin: 0 0 15px 0;
        transform: scale(0.77);
        color: $color-main;
      }

      .success-icon {
        margin: 0 0 15px 0;
        transform: scale(0.77);
        color: $color-main;
      }

      .error-icon {
        margin: 0 0 15px 0;
        transform: scale(0.77);
        color: #f52b2b;
      }

      .title {
        font-size: 24px;
        padding-bottom: 5px;
        color: #222222;
      }

      .content {
        text-align: center;
        font-size: 16px;
        color: #4e4e4e;
        padding: 15px 0;
      }

      .error {
        margin: 10px -30px -10px -30px;
        padding: 15px;
        font-size: 14px;
        color: #721c24;
        background-color: #f8d7da;
        border: 1px solid #f5c6cb;
      }

      .btn-ok {
        color: white;
        border-color: #1474a4;
        background-color: #1474a4;
      }

      .btn-ok:hover {
        background-color: #13557a;
      }

      .btn-cancel {
        color: black;
        border-color: #1474a4;
        background-color: white;
      }

      .btn-cancel:hover {
        background-color: rgba(20, 121, 174, 0.05);
      }

      input,
      textarea,
      select {
        border-radius: 3px;
        border: 1px solid lightgrey;
        font-size: 14px;
        padding: 4px 6px;
        min-height: 24px;
        margin: 0 10px 15px 10px;
        outline-style: none;
      }
    }
  }
}

/*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  /*-webkit-transform: scale(1.1);*/
  /*transform: scale(1.1);*/
}
</style>
