<template>
  <input
    :disabled="disabled"
    :placeholder="languageFallback"
    :type="type"
    :value="safeValue[language] ?? ''"
    @input="setValue($event.target.value)"
  />
</template>

<script setup>
import { supportedLanguages, getValue } from '@/languages';
import { computed } from 'vue';

const props = defineProps({
  language: String,
  withLanguages: Array,
  type: String,
  disabled: Boolean,
  value: Object,
});

const safeValue = computed(() => props.value ?? {});

const emit = defineEmits(['input']);

const languages = computed(() => props.withLanguages ?? supportedLanguages);
const languageFallback = computed(() =>
  getValue(languages.value, props.language, safeValue.value)
);

function setValue(v) {
  const value = {
    ...safeValue.value,
    [props.language]: v || null,
  };
  emit('input', value);
}
</script>

<style scoped></style>
