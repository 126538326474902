<template>
  <section>
    <generic-modal title="Taalbestand importeren" :error="error">
      <template #content>
        <div class="content">
          <div class="field">
            <label>Bestand</label>
            <label for="file" class="upload">Selecteer bestand</label>
            <input
              type="file"
              id="file"
              ref="file"
              @input="changeFile"
              accept=".json"
            />
            <span class="infofield">{{ filename }}</span>
            <font-awesome-icon
              v-if="filename"
              icon="times"
              class="remove"
              @click="clearFile"
            />
          </div>
        </div>
      </template>
      <template #action-buttons>
        <button
          class="action btn btn-dark"
          @click="confirm"
          :disabled="isLoading || !filename"
        >
          <font-awesome-icon v-if="isLoading" icon="spinner" />
          <span v-else>Importeren</span>
        </button>
        <button
          :disabled="isLoading"
          class="action btn btn-light"
          @click="cancel"
        >
          Annuleren
        </button>
      </template>
    </generic-modal>
  </section>
</template>

<script>
import GenericModal from '@/components/elements/GenericModal.vue';
import { importQuestionnaireLanguage, importTemplateLanguage } from '@/api';

export default {
  props: ['resource', 'config', 'language', 'version'],
  components: { GenericModal },
  data() {
    return {
      isLoading: false,
      filename: null,
      filecontent: null,
      error: null,
    };
  },
  methods: {
    changeFile() {
      const file = this.$refs.file.files[0];

      if (!file) {
        this.clearFile();
        return;
      }

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.filename = file.name;
        this.filecontent = reader.result;
      });

      reader.readAsText(file);
    },
    clearFile() {
      this.error = null;
      this.filename = null;
      this.filecontent = null;
      this.$refs.file.value = '';
    },
    async confirm() {
      this.error = null;
      this.isLoading = true;
      try {
        await this.doImport();
      } catch (error) {
        this.error = error.response?.data?.message || 'Er is iets misgegaan';
      } finally {
        this.isLoading = false;
      }
    },
    async doImport() {
      if (this.resource === 'templates') {
        await importTemplateLanguage(
          this.config,
          this.version,
          this.filecontent,
          this.language
        );
      } else if (this.resource === 'questionnaire') {
        await importQuestionnaireLanguage(
          this.config,
          this.version,
          this.filecontent,
          this.language
        );
      }
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped lang="scss">
.field {
  display: flex;
  flex-direction: row;
  margin: 5px 0 10px 0;
  font-size: 14px;
}

label:not(.upload) {
  text-align: right;
  margin: 6px 15px 0 0;
  width: 120px;
  color: grey;
  height: 24px;
}

label.upload {
  padding: 7px 14px;
  font-size: 14px;
  border-radius: 3px;
  white-space: nowrap;
  user-select: none;
  border: 1px solid #6a103a;
  background-color: white;
  color: #6a103a;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

#file {
  display: none;
}

.infofield {
  margin: 6px 0 6px 0;
}

.remove {
  margin: 8px;
  cursor: pointer;
}
</style>
