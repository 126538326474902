<template>
  <transition name="modal">
    <div class="mask">
      <div class="modal">
        <div class="close">
          <font-awesome-icon
            class="icon close-button"
            icon="times"
            size="1x"
            @click="$emit('save')"
          />
        </div>
        <div class="content" v-if="loaded">
          <div class="field">
            <label>Conditie</label>
            <v-select
              v-model="localLine.rule"
              :options="conditionRules"
              :clearable="true"
              :searchable="true"
              class="input-rule"
              placeholder="ALTIJD"
            >
              <template #selected-option="rule"
                >#R{{ rule.id }} - {{ rule.label }}
              </template>
              <template #option="rule"
                >#R{{ rule.id }} - {{ rule.label }}
              </template>
            </v-select>
          </div>
          <div class="field">
            <label>Variabele</label>
            <v-select
              v-model="localLine.var_rule"
              :options="variableRules"
              @input="inputVariable"
              :clearable="true"
              :searchable="true"
              class="input-rule"
            >
              <template #selected-option="rule"
                >#R{{ rule.id }} - {{ rule.label }}
              </template>
              <template #option="rule"
                >#R{{ rule.id }} - {{ rule.label }}
              </template>
            </v-select>
          </div>
          <div class="field positive-tags">
            <label>Tags inclusief</label>
            <SelectField
              :options="positiveTagsAvailable"
              placeholder="Tags inclusief"
              :label="(option) => option.label"
              :value="positiveTagsSelected"
              :isLoading="isLoading"
              :multiple="true"
              :searchable="true"
              @input="setPositiveTags"
            />
          </div>
          <div class="field negative-tags">
            <label>Tags exclusief</label>
            <SelectField
              :options="negativeTagsAvailable"
              placeholder="Tags exclusief"
              :label="(option) => option.label"
              :value="negativeTagsSelected"
              :isLoading="isLoading"
              :multiple="true"
              :searchable="true"
              @input="setNegativeTags"
            />
          </div>

          <multi-language-input-field
            :language="language"
            input-type="input"
            class="field multi-language"
            label="Label"
            :model="line"
            value-key="label"
            :enabled="true"
          />

          <multi-language-input-field
            :language="language"
            input-type="textarea"
            class="field multi-language"
            label="Tekst"
            :model="line"
            value-key="value"
            :enabled="true"
          />

          <div class="field">
            <label>Markering</label>
            <select v-model="localLine.output_level.code">
              <option value="neutral"></option>
              <option value="problem">Waarschuwing</option>
              <option value="critical">Ernstig</option>
            </select>
          </div>

          <div class="field">
            <label>Bijlage</label>
            <v-select
              v-model="localLine.file"
              :options="files"
              :get-option-label="fileOptionValue"
              :clearable="true"
              :searchable="true"
              class="input-rule"
            ></v-select>
          </div>

          <div class="field" v-if="config.new_report">
            <label>In samenvatting</label>
            <select v-model="localLine.in_summary">
              <option :value="true">Ja</option>
              <option :value="false">Nee</option>
            </select>
          </div>
          <div class="field">
            <label>Preview</label>
            <template v-if="line.file">
              <img
                :src="filePreview(line.file)"
                height="200"
                alt="Image preview..."
              />
            </template>
          </div>

          <div class="field">
            <label
              >Bijlage tekst <br />
              (read only)</label
            >
            <p class="attachment-text">
              {{ line.file ? fileDescription(line.file) : '' }}
            </p>
          </div>
        </div>
        <div class="buttons">
          <button class="action btn btn-dark" @click="$emit('save')">OK</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SelectField from './elements/SelectField';
import { isEmpty, some } from 'lodash';
import { supportedReportLanguages } from '@/languages';
import MultiLanguageInputField from '@/components/MultiLanguageInputField.vue';

function firstTruthy(list, lambda) {
  for (const it of list) {
    const value = lambda(it);
    if (value) {
      return value;
    }
  }

  return null;
}

function hasVar(content) {
  return content.includes('{{var}}');
}

export default {
  components: { MultiLanguageInputField, SelectField },
  props: [
    'tagsAvailable',
    'version',
    'line',
    'rules',
    'type',
    'files',
    'config',
    'language',
  ],
  data() {
    return {
      preview: null,
      positiveTagsSelected: null,
      negativeTagsSelected: null,
      isLoading: false,
    };
  },
  created() {
    this.init();
  },
  computed: {
    positiveTagsAvailable() {
      return this.tagsAvailable.filter((tag) => {
        return !(this.line.negative_tags || []).find(
          (tagValue) => tagValue === tag.value
        );
      });
    },
    negativeTagsAvailable() {
      return this.tagsAvailable.filter((tag) => {
        return !(this.line.positive_tags || []).find(
          (tagValue) => tagValue === tag.value
        );
      });
    },
    loaded() {
      return this.rules !== null;
    },
    conditionRules() {
      return this.rules.filter((r) => r.operator.outputType === 'boolean');
    },
    variableRules() {
      return this.rules.filter((r) => r.operator.outputType === 'numeric');
    },

    // See vue/no-mutating-props rule in .eslintrc
    localLine() {
      return this.line;
    },
  },
  watch: {
    loaded() {
      this.init();
    },
  },
  methods: {
    setNegativeTags(tags) {
      if (!isEmpty(tags)) {
        this.localLine.negative_tags = tags.map((tag) => tag.value);
      } else {
        this.localLine.negative_tags = null;
      }
    },
    setPositiveTags(tags) {
      if (!isEmpty(tags)) {
        this.localLine.positive_tags = tags.map((tag) => tag.value);
      } else {
        this.localLine.positive_tags = null;
      }
    },
    init() {
      this.positiveTagsSelected = (this.line.positive_tags || []).map(
        (value) => {
          return this.tagsAvailable.find((t) => value === t.value);
        }
      );
      this.negativeTagsSelected = (this.line.negative_tags || []).map(
        (value) => {
          return this.tagsAvailable.find((t) => value === t.value);
        }
      );
    },

    // Called when the rule input variable changes, adds an automatic {{var}}
    // placeholder to the rule value.
    inputVariable() {
      const addVar = !!this.line.var_rule;

      // Iterate all report languages. If a language has a label / value set,
      // but no {{var}} placeholder, add a placeholder to that language. If
      // a language doesn't have content defined yet, leave it alone.
      const label = this.line.label;
      const value = this.line.value;
      for (const lang of supportedReportLanguages) {
        const lbl = label[lang] || '';
        const val = value[lang] || '';

        if (addVar && (lbl || val) && !(hasVar(lbl) || hasVar(val))) {
          // Add an automatic {{var}}
          value[lang] = val + '{{var}}';
        } else if (!addVar) {
          // Remove any var placeholders that may be present.
          label[lang] = lbl.replace('{{var}}', '') || null;
          value[lang] = val.replace('{{var}}', '') || null;
        }
      }

      // If all languages are currently empty, add a {{var}} to the first one.
      if (
        addVar &&
        !some(supportedReportLanguages, (lang) => {
          return hasVar(label[lang] || '') || hasVar(value[lang] || '');
        })
      ) {
        const first = supportedReportLanguages[0];
        value[first] = '{{var}}';
      }
    },

    fileLanguageFallback(lambda) {
      return (
        lambda(this.language) ??
        firstTruthy(supportedReportLanguages, lambda) ??
        ''
      );
    },

    // Returns the name of the file in either the selected language or the first available fallback
    fileName(file) {
      return this.fileLanguageFallback((lang) => file.translations[lang]?.name);
    },

    // Returns the URL of the file in either the selected language or the first available fallback
    filePreview(file) {
      return this.fileLanguageFallback((lang) => file.translations[lang]?.url);
    },

    fileOptionValue(file) {
      return file.file_section_name + ' - ' + this.fileName(file);
    },

    // Returns the description of the file in either the selected language or the first available fallback
    fileDescription(file) {
      return this.fileLanguageFallback(
        (lang) => file.translations[lang]?.description
      );
    },
  },
};
</script>

<style scoped lang="scss">
.mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

// Note: Template already specifies field styles deeply,
// and they're the same, so these basically do nothing.
// But they _could_ be different. These styles are a mess.
input:not(.vs__selected-options input),
textarea,
select {
  border-radius: 3px;
  border: 1px solid lightgrey;
  font-size: 14px;
  padding: 4px 6px;
  min-height: 24px;
  outline-style: none;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $font-family-gitlab;
  width: 1000px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-top: solid 3px $color-main;

  .close {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .close-button {
      margin: 8px;
      color: #afafaf;
      cursor: pointer;
    }

    .close-button:hover {
      color: #8b8b8b;
    }
  }

  .buttons {
    text-align: center;
    padding: 15px 0 10px 0;

    .btn {
      width: 140px !important;
      margin: 0 5px !important;
    }
  }
}

.content {
  width: 100%;
  min-height: 131px;
  margin: 10px 0 0 0;
}

:deep(.field) {
  display: flex;
  flex-direction: row;
  margin: 5px 0 10px 0;
  font-size: 14px;

  label:not(.upload) {
    text-align: right;
    margin: 6px 15px 0 0;
    width: 120px;
    color: grey;
    height: 24px;
  }

  label.upload {
    padding: 7px 14px;
    font-size: 14px;
    border-radius: 3px;
    white-space: nowrap;
    user-select: none;
    border: 1px solid #6a103a;
    background-color: white;
    color: #6a103a;
    cursor: pointer;
    margin-right: 10px;
  }

  label.upload:hover {
    background-color: #f6f6f6;
  }

  #file {
    display: none;
  }

  input {
    width: 600px;
  }

  input:disabled {
    background-color: #f6f6f6;
  }

  .infofield {
    margin: 6px 0 6px 0;
  }

  .remove {
    margin: 8px;
    cursor: pointer;
  }

  .input-rule {
    width: 800px;
  }

  .input-text {
    margin: 0;
    width: 786px;
    height: 80px;
  }

  .attachment-text {
    width: 786px;
    height: 80px;
    line-height: 1;
    text-align: left;
    vertical-align: baseline;
  }

  .old-style-text {
    width: 786px;
    height: 40px;
    line-height: 1;
    text-align: left;
    vertical-align: baseline;
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
</style>
