<template>
  <div class="container">
    <template-line
      v-if="line && tagsAvailable"
      :version="version"
      :config="config"
      :line="line"
      @save="saveLine"
      @close="line = null"
      :rules="rules"
      :tagsAvailable="tagsAvailable"
      :files="files"
      :type="template.section.template_section_type"
      :language="reportLanguage"
    />

    <div class="subheader">
      <template v-if="initialized">
        <div class="title" v-if="templateId">
          <router-link
            :to="{
              name: 'templates',
              params: {
                versionId: version.id,
                sectionId: sectionId,
                templateId: template.id,
              },
            }"
            class="link"
            >#D{{ template.id }}
          </router-link>
          - {{ languageValue(originalTemplate.label) }}
        </div>
        <div class="title" v-if="!templateId">Nieuw template</div>
        <div class="buttons" v-if="enabled">
          <button
            type="button"
            class="btn btn-invisible-grey"
            @click="deleteTemplate"
            v-if="templateId"
          >
            Verwijderen
          </button>
          <button
            type="button"
            class="btn btn-dark"
            @click="saveTemplate"
            :disabled="!changed"
          >
            Opslaan
          </button>
        </div>
      </template>
    </div>

    <div class="scroller">
      <div class="template" v-if="initialized">
        <div class="field">
          <label>Sectie</label>
          <select v-model="template.section" :disabled="!enabled">
            <option
              v-for="section in sections"
              :value="section"
              :key="section.id"
            >
              {{ languageValue(section.label) }}
            </option>
          </select>
        </div>
        <multi-language-input-field
          :language="reportLanguage"
          input-type="input"
          class="field"
          label="Titel"
          :model="template"
          valueKey="label"
          :enabled="enabled"
        />

        <div class="field">
          <label>Opmaak</label>
          <select v-model="template.separator" :disabled="!enabled">
            <option
              v-for="separator in separators"
              :key="separator.value || 'no-separator'"
              :value="separator.value"
            >
              {{ separator.name }}
            </option>
          </select>
        </div>

        <div class="field" v-if="config.new_report">
          <label>In samenvatting</label>
          <select v-model="template.summary_section">
            <option :value="null"></option>
            <option value="patient_data">Patiëntgegevens</option>
            <option value="general">Algemeen</option>
            <option value="allergies">Allergieën</option>
            <option value="intoxications">Intoxicaties</option>
            <option value="history">Relevante voorgeschiedenis</option>
            <option value="medication">Relevante medicatie</option>
            <option value="labresults">Labuitslagen</option>
            <option value="vitals">Vitale parameters</option>
            <option value="mdl">MDL</option>
            <option value="card">Cardiaal</option>
            <option value="pulm">Pulmonaal</option>
            <option value="neuro">Neurologisch</option>
            <option value="endo">Endocrinologisch</option>
            <option value="gastro">Gastro-intestinaal</option>
            <option value="nefro">Nefrologisch</option>
            <option value="vasc">Vaatstelsel</option>
            <option value="blood">Stolling</option>
            <option value="anesthesia">Anesthesiologisch</option>
            <option value="other">Overig</option>
            <option value="scores">Scores</option>
            <option value="patient_remarks">Opmerkingen patiënt</option>
            <option value="doctor_remarks">Opmerkingen arts</option>
            <option value="additional_diagnostics">
              Aanvullende diagnostiek / adviezen
            </option>
          </select>
        </div>

        <div class="field">
          <label>Waarden</label>
          <div class="lines">
            <draggable
              class="lines-draggable"
              handle=".draghandle"
              :forceFallback="true"
              element="div"
              v-model="template.lines"
              animation="300"
              ghostClass="ghost"
              :disabled="!enabled"
            >
              <div
                class="line-wrapper"
                v-for="(line, index) in template.lines"
                :key="line.id"
              >
                <font-awesome-icon
                  :icon="['fas', 'arrows-alt']"
                  class="draghandle"
                  v-if="enabled"
                />

                <div
                  class="line"
                  :class="{
                    disabled: !enabled,
                    enabled: enabled,
                    full: isFull,
                    simple: isSimple,
                  }"
                  @click="openLine(line, index)"
                >
                  <div class="rule">
                    <span
                      class="rulelink"
                      v-if="line.rule"
                      @click.stop.prevent="gotoRule(line.rule)"
                      >#R{{ line.rule.id }} - {{ line.rule.label }}</span
                    >
                    <div v-if="!line.rule">ALTIJD</div>
                  </div>
                  <div class="label">
                    {{ languageValue(line.label)
                    }}{{
                      languageValue(line.label) && languageValue(line.value)
                        ? ':'
                        : ''
                    }}
                  </div>
                  <div class="value">
                    <template v-if="languageValue(line.value)">
                      {{ valueLeft(line) }}
                      <span
                        class="varrule"
                        v-if="hasVar(line)"
                        @click.stop.prevent="gotoRule(line.var_rule)"
                        >[[#R{{ line.var_rule.id }} -
                        {{ line.var_rule.label }}]]</span
                      >
                      {{ valueRight(line) }}
                    </template>
                  </div>
                  <div class="all-tags">
                    <div class="tags" v-if="line.positive_tags">
                      <div
                        :key="'tag-' + index"
                        v-for="(tag, index) in tagsAsValues(line.positive_tags)"
                        class="tag positive"
                      >
                        <span>{{ tag }}</span>
                      </div>
                    </div>
                    <div class="tags" v-if="line.negative_tags">
                      <div
                        :key="'tag-' + index"
                        v-for="(tag, index) in tagsAsValues(line.negative_tags)"
                        class="tag negative"
                      >
                        <span>{{ tag }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="line-properties">
                    <div
                      class="output-level"
                      :class="line.output_level.code"
                      :title="line.output_level?.label"
                    ></div>
                    <div
                      class="in-summary"
                      v-if="line.in_summary"
                      title="In samenvatting"
                    >
                      <font-awesome-icon :icon="['fal', 'clipboard-list']" />
                    </div>
                    <div
                      class="with-attachment"
                      v-if="line.file"
                      title="Heeft bijlage"
                    >
                      <font-awesome-icon :icon="['fal', 'paperclip']" />
                    </div>
                  </div>
                </div>

                <font-awesome-icon
                  :icon="['fas', 'trash-alt']"
                  class="remove-icon"
                  @click="removeLine(index)"
                  v-if="enabled"
                />
              </div>
            </draggable>
            <div class="line-wrapper" v-if="enabled">
              <div
                class="line new"
                @click="openLine(null, template.lines.length)"
              >
                <font-awesome-icon class="icon" icon="plus-circle" size="2x" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import api, { getTemplateSeparators } from '@/api';
import TemplateLine from '@/components/TemplateLine.vue';
import _, { cloneDeep, isNil } from 'lodash';
import Vue from 'vue';
import promiseStore from '../promiseStore';
import {
  emptyLanguageObject,
  supportedReportLanguages,
  useGetValue,
} from '@/languages';
import MultiLanguageInputField from '@/components/MultiLanguageInputField.vue';

const languageValue = useGetValue(supportedReportLanguages);

// Checks whether a value contains a variable
function hasVar(value) {
  return value.includes('{{var}}');
}

// Splits value by variable
function splitVar(value) {
  return (value ?? '').split('{{var}}');
}

export default {
  components: { MultiLanguageInputField, TemplateLine },
  props: ['sectionId', 'templateId', 'version', 'config', 'reportLanguage'],
  data() {
    return {
      line: null,
      lineIndex: null,
      rules: null,
      sections: null,
      template: null,
      flagInitialized: false,
      originalTemplate: null,
      popup: null,
      files: [],
      tagsAvailable: null,
      separators: null,
    };
  },
  beforeRouteLeave(to, from, next) {
    this.guard(next);
  },
  beforeRouteUpdate(to, from, next) {
    this.guard(next);
  },
  created() {
    this.load();
  },
  computed: {
    loaded() {
      return this.template !== null && this.sections !== null;
    },
    initialized() {
      return (
        this.loaded &&
        this.flagInitialized &&
        this.tagsAvailable &&
        this.separators
      );
    },
    changed() {
      return !_.isEqual(this.template, this.originalTemplate);
    },
    enabled() {
      return !!this.version.open;
    },
    exists() {
      return this.templateId > 0;
    },
    isFull() {
      return this.template.section.template_section_type === 'full';
    },
    isSimple() {
      return this.template.section.template_section_type === 'simple';
    },
  },
  watch: {
    loaded() {
      if (this.loaded) {
        this.initialize();
        this.flagInitialized = true;
      } else {
        this.flagInitialized = false;
      }
      api
        .getRules(this.version)
        .then((response) => (this.rules = response.data));
    },
  },
  methods: {
    languageValue(item) {
      return languageValue(this.reportLanguage, item, {});
    },
    tagsAsValues(tags) {
      if (!tags || !tags.length) {
        return [];
      }

      return tags.map((tag) => {
        const f = this.tagsAvailable.find((tagObj) => tagObj.value === tag);

        return f.label;
      });
    },
    guard(next) {
      if (this.changed) {
        this.$emit('popup', {
          title: 'Weet je zeker dat je wilt weggaan?',
          content:
            'De wijzigingen in de template zullen niet opgeslagen worden',
          image: 'question',
          confirm: () => {
            this.$emit('popup', null);
            next();
          },
          cancel: () => {
            this.$emit('popup', null);
            next(false);
          },
        });
      } else {
        next();
      }
    },
    hasVar(line) {
      return hasVar(this.languageValue(line.value));
    },
    valueLeft(line) {
      const value = this.languageValue(line.value);
      return hasVar(value) ? splitVar(value)[0] : value;
    },
    valueRight(line) {
      const value = this.languageValue(line.value);
      return hasVar(value) ? splitVar(value)[1] : '';
    },
    async initTagsAvailable() {
      this.tagsAvailable = await promiseStore.getOrSet('tags-available', () =>
        api.getTagsAvailable().then((response) => response.data)
      );
    },
    async initSeparators() {
      this.separators = await promiseStore.getOrSet(
        'template-separators',
        async () => (await getTemplateSeparators()).data
      );
    },
    async load() {
      await Promise.all([this.initTagsAvailable(), this.initSeparators()]);
      this.files = (await api.getFiles()).data;
      this.sections = (await api.getTemplateSections(this.version)).data;
      if (this.templateId > 0) {
        this.template = (
          await api.getTemplate(this.version, this.templateId)
        ).data;
      } else if (!isNil(this.templateId)) {
        // Only explicitly create new template if the parameter is defined,
        // route might not be open.
        this.template = {
          section: this.sections.find(
            (section) => section.id === parseInt(this.sectionId)
          ),
          label: emptyLanguageObject(supportedReportLanguages),
          summary_section: null,
          lines: [],
        };
      }
    },
    initialize() {
      this.template.section = this.template.section
        ? this.sections.find((s) => s.id === this.template.section.id)
        : null;
      this.template.lines.map((line) => {
        return line;
      });
      this.originalTemplate = _.cloneDeep(this.template);
    },
    deleteTemplate() {
      this.$emit('popup', {
        title: 'Template verwijderen?',
        content: '',
        image: 'question',
        confirm: () => {
          this.$emit('popup', null);
          api.deleteTemplate(this.version, this.template).then(() => {
            this.$router.push({
              name: 'templates',
              params: {
                versionId: this.version.id,
                sectionId: this.template.section.id,
              },
            });
            this.$emit('refreshMiddle');
          });
        },
        cancel: () => this.$emit('popup', null),
      });
    },
    pushTemplate(template) {
      this.$router.push({
        name: 'templates',
        params: {
          versionId: this.version.id,
          sectionId: template.section.id,
          templateId: template.id,
        },
      });
    },
    saveTemplate() {
      if (this.exists) {
        api.updateTemplate(this.version, this.template).then((response) => {
          this.template = response.data;
          this.initialize();
          if (this.template.section.id !== parseInt(this.sectionId)) {
            this.pushTemplate(this.template);
          } else {
            this.$emit('refreshMiddle');
          }
        });
      } else {
        api.insertTemplate(this.version, this.template).then((response) => {
          this.template = response.data;
          this.originalTemplate = cloneDeep(this.template);
          this.$emit('refreshMiddle');
          this.pushTemplate(this.template);
        });
      }
    },
    openLine(line, index) {
      if (!this.enabled) {
        return;
      }

      if (line) {
        this.line = cloneDeep(line);
      } else {
        this.line = {
          id: null,
          label: emptyLanguageObject(supportedReportLanguages),
          value: emptyLanguageObject(supportedReportLanguages),
          in_summary: false,
          rule: null,
          file: null,
          var_rule: null,
          position: null,
          positive_tags: null,
          negative_tags: null,
          output_level: { code: 'neutral' },
        };
      }
      this.lineIndex = index;
    },
    saveLine() {
      Vue.set(this.template.lines, this.lineIndex, this.line);
      this.line = null;
      this.lineIndex = null;
    },
    removeLine(index) {
      this.template.lines.splice(index, 1);
    },
    gotoRule(rule) {
      this.$router.push({
        name: 'rules',
        params: {
          versionId: this.version.id,
          sectionId: rule.section.id,
          ruleId: rule.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  .all-tags {
    display: flex;
  }

  height: 100%;
  display: flex;
  flex-direction: column;
}

.scroller {
  overflow-y: auto;
}

.template {
  padding-top: 20px;
  padding-bottom: 60px;
  font-family: $font-family-gitlab;
  display: flex;
  flex-direction: column;
}

:deep(.field) {
  display: flex;
  flex-direction: row;
  padding: 6px 0;

  label {
    width: 120px;
    text-align: right;
    color: grey;
    padding: 6px 14px 0 0;
    font-size: 14px;
  }

  textarea:disabled,
  input:disabled,
  select:disabled {
    background-color: $bg-color-disabled;
  }

  // Exclude the vue-select hidden input
  input:not(.vs__selected-options input),
  textarea,
  select {
    border-radius: 3px;
    border: 1px solid lightgrey;
    font-size: 14px;
    padding: 4px 6px;
    min-height: 24px;
    margin: 0 10px 0 0;
    outline-style: none;
  }

  // Exclude the vue-select hidden input
  input:not(.vs__selected-options input) {
    width: 300px;
  }

  textarea {
    width: calc(100% - 240px);
    height: 8em;
    line-height: 1.4;
  }

  button {
    margin: 15px 5px;
  }
}

.lines {
  width: calc(100% - 200px);

  .ghost {
    opacity: 0.4;
  }
}

.line-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .draghandle {
    padding: 0 10px 0 10px;
    cursor: grab;
  }

  .remove-icon {
    cursor: pointer;
    color: $color-main;
    padding: 0 10px 0 10px;
  }
}

.line.enabled:hover,
.line.new:hover {
  background-color: $bg-color-light-grey;
}

.line.new {
  margin-left: 36px;
  margin-right: 34px;
  height: 17px;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.2);
  border-style: dashed;

  .icon {
    transform: scale(0.77);
  }
}

.line.disabled {
  color: rgb(84, 84, 84);
  background-color: $bg-color-disabled;

  .rule {
    a {
      color: rgb(84, 84, 84);
    }
  }
}

.line {
  cursor: pointer;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 14px 30px 14px 20px;
  margin: 3px 0;
  width: 100%;

  display: flex;
  flex-direction: row;

  .rule {
    width: 35%;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .rulelink:hover {
      text-decoration: underline;
    }

    .badge {
      padding: 5px 7px 5px 7px;
      font-size: 11px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      vertical-align: middle;
      border-radius: 0.25rem;

      margin: 0 2px;
      color: white;
      background-color: green;
      cursor: pointer;
    }
  }

  .label {
    margin: 0 5px 0 0;
    font-weight: bold;
  }

  .text {
    text-align: right;
    margin: 0 15px;
  }
}

.line.full {
  .text {
    width: 30%;
  }
}

.line.simple {
  .text {
    width: 0;
  }
}

.line-properties {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: 1.5rem;
  gap: 0.5rem;
}

.output-level {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ccc;

  &.problem {
    background-color: #ffb800;
  }

  &.critical {
    background-color: #c20000;
  }
}
</style>
