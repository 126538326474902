<template>
  <div class="container">
    <div class="subheader">
      <div class="title">Zorgplatform Login Test</div>
      <div class="buttons">
        <button class="btn btn-light" @click="closeTestSaml()">
          Terug naar tenant
        </button>
        <button class="btn btn-dark" type="submit" form="generate-form">
          Get SAML Response
        </button>
        <button
          v-if="samlResponse"
          class="btn btn-dark"
          type="submit"
          form="login-form"
        >
          Login with SAML Response
        </button>
      </div>
    </div>
    <form
      class="form"
      @submit.prevent="generateSamlResponse()"
      id="generate-form"
    >
      <div class="field-container">
        <div class="field">
          <label for="bsn">Bsn</label>
          <input
            type="text"
            v-model="bsn"
            class="form-control"
            :class="{ error: errors && errors.bsn }"
            id="bsn"
            placeholder="Patient BSN"
          />
        </div>

        <div class="info warning">
          <font-awesome-icon icon="exclamation-circle" />

          <div class="info-content">
            <span>Use BSNs that match with test patients.</span>
          </div>
        </div>

        <div class="error-message" v-if="errors && errors.bsn">
          {{ errors.bsn[0] }}
        </div>
      </div>

      <div class="field-container">
        <div class="field">
          <label
            @click="() => (workflowId = generateWorkflowId())"
            class="clickable"
            for="saml"
            >Workflow Id</label
          >
          <input
            type="text"
            v-model="workflowId"
            class="form-control"
            :class="{ error: errors && errors.workflow_id }"
            id="workflow"
            placeholder="Workflow ID"
          />
        </div>
        <div class="error-message" v-if="errors && errors.workflow_id">
          {{ errors.workflow_id[0] }}
        </div>

        <div class="info warning">
          <font-awesome-icon icon="exclamation-circle" />

          <div class="info-content">
            <span>Prefilled with a random string / number.</span>
          </div>
        </div>
      </div>

      <div class="field-container">
        <div class="field">
          <label for="name_id">Name Id</label>
          <input
            type="text"
            v-model="nameId"
            class="form-control"
            :class="{ error: errors && errors.name_id }"
            id="name_id"
            placeholder="Name ID"
          />
        </div>
        <div class="error-message" v-if="errors && errors.name_id">
          {{ errors.name_id[0] }}
        </div>
        <div class="info warning">
          <font-awesome-icon icon="exclamation-circle" />

          <div class="info-content">
            <span
              >Assuming you want to login as you and you have that name_id set
              into the name_id field in the database.</span
            >
          </div>
        </div>
      </div>

      <div class="field-container">
        <div class="field">
          <label for="saml">Organization Id</label>
          <input
            type="text"
            v-model="organizationId"
            :class="{ error: errors && errors.organization_id }"
            class="form-control"
            id="organization_id"
            placeholder="OrganizationID"
          />
        </div>

        <div class="error-message" v-if="errors && errors.organization_id">
          {{ errors.organization_id[0] }}
        </div>
        <div class="info warning">
          <font-awesome-icon icon="exclamation-circle" />

          <div class="info-content">
            <span
              >We take the oid from the tenant you just selected and you want to
              log into that tenant.</span
            >
          </div>
        </div>
      </div>
      <button class="btn btn-dark" type="submit" hidden>
        Get SAML Response
      </button>
    </form>
    <form
      v-if="samlResponse"
      target="_blank"
      :action="loginUrl"
      class="form"
      method="post"
      id="login-form"
    >
      <div class="field">
        <label for="token">Token</label>
        <input
          type="text"
          v-model="token"
          class="form-control"
          id="token"
          name="token"
          placeholder="Token"
        />
      </div>
      <div class="field">
        <label for="saml">SAML Response (base64-encoded)</label>
        <textarea
          cols="100"
          rows="20"
          v-model="samlResponse"
          class="form-control"
          id="saml"
          name="SAMLResponse"
          placeholder="SAML Response"
        />
      </div>
      <button class="btn btn-dark" type="submit" hidden>
        Login with SAML Response
      </button>
    </form>
    <pre v-if="assertion" class="assertion">{{ assertion }}</pre>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router/composables';
import { getCurrentInstance, ref, watch } from 'vue';
import { generateTenantSamlResponse, getTenant } from '@/api';

defineProps({
  resource: String,
});

const router = useRouter();
const route = useRoute();

function closeTestSaml() {
  router.push({
    name: 'tenants',
    params: route.params,
    query: route.query,
  });
}

function generateWorkflowId() {
  return (Math.random() + 1).toString(36).substring(2);
}

const assertion = ref(null);
const bsn = ref(null);
const errors = ref(null);
const tenant = ref(null);
const organizationId = ref(null);
const loginUrl = process.env.VUE_APP_ADMIN_API + '/zorgplatform/login';
const root = getCurrentInstance().proxy.$root;
const nameId = ref(root.user?.name?.toLowerCase() ?? '');
const samlResponse = ref(null);
const samlPlainText = ref(null);
const token = ref(null);
const workflowId = ref(generateWorkflowId());

async function generateSamlResponse() {
  try {
    errors.value = null;

    const { data } = await generateTenantSamlResponse({
      bsn: bsn.value,
      workflow_id: workflowId.value,
      organization_id: organizationId.value,
      name_id: nameId.value,
    });

    samlResponse.value = data.saml_response;
    assertion.value = data.assertion;
    samlPlainText.value = data.saml_plain_text;
    token.value = data.token;
  } catch (e) {
    const { data } = e.response;
    this.errors = data.errors;
  }
}

watch(
  () => route.params,
  async () => {
    const tenantId = route.params.tenantId;
    const response = await getTenant(tenantId);
    tenant.value = response.data;
    organizationId.value = tenant.value.oid;
  },
  { immediate: true, flush: 'post' }
);
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';

form {
  padding: 1rem;
}

.field-container {
  &:not(:first-child) {
    margin-top: 1rem;
  }
}

.field {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 1rem;
  }
}

input,
textarea {
  @include input-box;
  flex-grow: 1;
}

label {
  @include input-label(150px);
}

.info {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 160px;
}

.error-message {
  font-size: 14px;
  font-weight: bold;
  color: $color-dark-red;
  padding: 0.75rem 0.75rem 0.75rem 160px;
}

.info-content {
  margin-left: 0.5rem;
  color: #666666;
  font-size: 0.875rem;
}

.clickable {
  cursor: pointer;
}

.assertion {
  @include input-box;
  overflow: auto;
  font-size: 12px;
  max-height: 400px;
  margin: 1rem;
}
</style>
