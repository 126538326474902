<template>
  <generic-modal
    :error="error"
    :errorData="errorData"
    @close="$emit('close')"
    title="Export dupliceren"
    :confirm="confirm"
  >
    <template #image>
      <font-awesome-icon icon="clone" class="icon" size="4x" />
    </template>
    <template #content>
      <div class="field">
        <label>Nieuwe naam</label>
        <input type="text" v-model="name" size="40" />
      </div>
    </template>
    <template #action-buttons>
      <button class="action btn btn-dark" @click="confirm" :disabled="!name">
        <font-awesome-icon v-if="isLoading" icon="spinner" />
        <span v-else>Dupliceren</span>
      </button>
      <button
        :disabled="isLoading"
        class="action btn btn-light"
        @click="cancel"
      >
        Annuleren
      </button>
    </template>
  </generic-modal>
</template>

<script>
import GenericModal from '@/components/elements/GenericModal';

export default {
  props: ['exportTemplateId', 'action'],
  components: { GenericModal },
  data() {
    return {
      error: null,
      name: null,
      errorData: null,
      isLoading: false,
    };
  },
  methods: {
    confirm() {
      this.isLoading = true;
      this.error = null;
      this.errorData = null;

      this.action(this.exportTemplateId, this.name)
        .then(() => {
          this.$emit('close');
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            this.error = error.response.data.message;
            this.errorData = error.response.data?.metadata;
          } else {
            this.error = 'Er is iets misgegaan';
          }
        })
        .finally(() => (this.isLoading = false));
    },
    cancel() {
      this.$emit('close');
    },
  },
};
</script>
