<template>
  <RootLayout resource="validator">
    <div
      class="container settings"
      :class="expandSettings ? 'expanded' : 'collapsed'"
    >
      <div class="inner">
        <div class="subheader">
          <div class="title">
            <font-awesome-icon
              :icon="['fas', 'cog']"
              @click="toggleExpandSettings()"
              class="icon-button"
            />
            Instellingen
          </div>
          <div class="buttons">
            <button
              class="btn btn-invisible-grey"
              @click="applyAnalyze()"
              :disabled="pendingConfigs.length !== 1"
            >
              analyseren
            </button>
            <button
              class="btn btn-invisible-grey"
              @click="applySettings()"
              :disabled="!hasPendingConfig"
            >
              vergelijken
            </button>
          </div>
        </div>
        <div class="settings-form">
          <h2>Tenant</h2>
          <div class="field select">
            <select class="tenant-selector" v-model="pendingTenant">
              <option v-for="t in tenants" :key="t.id" :value="t">
                {{ t.name }}
              </option>
            </select>
          </div>

          <h2>Configuraties</h2>
          <!-- Select which configurations to compare -->
          <ul class="selected-versions">
            <li v-for="(pair, i) in pendingConfigs" :key="pair.version.id">
              {{ pair.config.name }} - versie {{ pair.version.version }}
              <font-awesome-icon
                :icon="['fas', 'trash']"
                @click="removePendingConfig(i)"
                class="icon-button"
              />
            </li>
          </ul>
          <div class="field select">
            <select
              class="config-selector"
              v-model="pendingConfig"
              @change="pendingConfigChanged"
            >
              <option v-for="c in configs" :key="c.id" :value="c">
                {{ c.name }}
              </option>
            </select>
            <select
              class="version-selector"
              v-if="pendingVersion"
              v-model="pendingVersion"
            >
              <option
                v-for="v in pendingConfig.versions"
                :key="v.id"
                :value="v"
              >
                versie {{ v.version }}
              </option>
            </select>
            <font-awesome-icon
              :icon="['fas', 'plus']"
              @click="addPendingConfig()"
              class="icon-button"
            />
          </div>
          <div class="field checkbox">
            <label for="hideExternalInputs"> Externe inputs verbergen </label>
            <input
              type="checkbox"
              id="hideExternalInputs"
              v-model="hideExternalInputs"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="evaluate"
      v-if="settings"
      :class="[
        hideExternalInputs ? 'hide-external-inputs' : '',
        expandSettings ? 'settings-expanded' : 'settings-collapsed',
      ]"
    >
      <div class="center" v-if="isLoading">
        <font-awesome-icon icon="spinner" size="2x" />
      </div>
      <table v-else>
        <thead>
          <tr>
            <th>Patient</th>
            <th
              v-for="header in headers.topHeaders"
              :key="`patient-header-${header.screening_id}`"
              :colspan="header.colspan"
            >
              {{ header.patient.name }}
            </th>
          </tr>
          <tr>
            <th>Regel / Versie</th>
            <template v-for="header in headers.subHeaders">
              <th
                :key="`version-header-${header.screening_id}-${header.version.id}`"
                :title="`${header.config.name} - ${header.version.version}`"
              >
                v{{ header.version.id }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-for="section in sections">
            <tr :key="`section-${section.id}`" :class="sectionClass(section)">
              <!-- The section label spans the rule column + one column for each screening + version-->
              <th :colspan="1 + headers.subHeaders.length">
                {{ section.label }}
              </th>
            </tr>
            <!-- Include the section ID in the rule key, because rules may have moved to different
sections and therefore appear in the list twice. We show the rule in both sections
in this case. -->
            <tr
              v-for="rule in section.rules"
              :key="`rule-${section.id}-${rule.id}`"
              :class="[
                isExternalInput(rule) ? 'external-input' : '',
                ruleClass(rule),
              ]"
            >
              <th
                :title="`${rule.id} - ${rule.label}`"
                class="rule-label"
                @click="toggleFocus(rule)"
              >
                {{ shorten(rule.label, 50) }}
              </th>
              <td
                v-for="result in ruleCells[rule.id]"
                :key="result.key"
                :title="result.display"
                :class="[
                  result.missing ? 'missing' : '',
                  `cat-${result.cat}`,
                  result.first ? 'first' : '',
                  result.last ? 'last' : '',
                ]"
              >
                {{ result.short }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <config-analyzer
      v-if="!!analyzeConfig"
      :settings="analyzeConfig"
    ></config-analyzer>
  </RootLayout>
</template>
<script setup>
import RootLayout from '@/components/RootLayout.vue';
import { computed, onMounted, ref } from 'vue';
import {
  evaluateVersion,
  getConfigs,
  getRuleSections,
  getTenants,
} from '@/api';
import {
  cloneDeep,
  countBy,
  isArray,
  isObject,
  isUndefined,
  sortBy,
  truncate,
  uniq,
} from 'lodash';
import ConfigAnalyzer from '@/components/config-validator/ConfigAnalyzer.vue';

// Toggle settings width for more space for the table
const expandSettings = ref(true);

function toggleExpandSettings() {
  expandSettings.value = !expandSettings.value;
}

// Show / hide rules that just pass an external input value
const hideExternalInputs = ref(true);

// Available tenants
const tenants = ref([]);

// Available configurations
const configs = ref([]);

// ID of the tenant in the tenant selector
const pendingTenant = ref(null);

// Versions that will be evaluated with the next submit
const pendingConfigs = ref([]);

// Config to pass along to the analyzer
const analyzeConfig = ref(null);

// Enables / disables the apply button
const hasPendingConfig = computed(
  () => pendingConfigs.value.length && pendingTenant.value
);

// Currently selected config / version
const pendingConfig = ref(null);
const pendingVersion = ref(null);

// Callback when the pending config value is changed.
function pendingConfigChanged() {
  if (!pendingConfig.value) {
    return;
  }

  pendingVersion.value = pendingConfig.value.versions[0];
}

// Adds the current pending config to the list of pending configs
function addPendingConfig() {
  if (!pendingConfig.value || !pendingVersion.value) {
    return;
  }

  if (
    pendingConfigs.value.find((c) => c.version.id === pendingVersion.value.id)
  ) {
    // Don't add duplicates
    return;
  }

  pendingConfigs.value.push({
    config: pendingConfig.value,
    version: pendingVersion.value,
  });
}

// Removes the pending config at the given index
function removePendingConfig(i) {
  pendingConfigs.value.splice(i, 1);
}

// Current (pending) config settings
const settings = ref(null);

// Current section list
const sections = ref(null);

// Current dependency list
const ruleDependencies = ref(null);

//#region Rule focus
// If set, we limit the displayed table to only this rule
// and all rules it depends on.
const focusRule = ref(null);

// Class that needs to be applied to a section row. This is either
// an empty string, 'dependency' or 'hidden' depending on the
// currently focused rule.
function sectionClass(section) {
  const focused = focusRule.value;
  if (!focused) {
    return '';
  }

  const deps = ruleDependencies.value[focused.id];
  return deps.sections.has(section.id) ? 'dependency' : 'hidden';
}

// Class applied to a rule row. If a rule is focused, this is one of:
// - 'focused' (current rule is focused)
// - 'dependency' (rule is a dependency of the focused rule)
// - 'hidden' (rule is not a dependency of the focused rule)
// If no rule is focused, this is an empty string.
function ruleClass(rule) {
  const focused = focusRule.value;
  if (!focused) {
    return '';
  }

  // So we can highlight the focused rule
  if (focused.id === rule.id) {
    return 'focused';
  }

  const deps = ruleDependencies.value[focused.id];
  return deps.rules.has(rule.id) ? 'dependency' : 'hidden';
}

// Toggles focus of the given rule. If the passed rule is currently
// focused, all rule focus is removed. If the passed rule is not
// currently focused, focus shifts to the given rule.
function toggleFocus(rule) {
  if (rule.id === focusRule.value?.id) {
    focusRule.value = null;
    return;
  }

  focusRule.value = rule;
}

//#endregion

// Current table header list
const headers = ref({
  // Top headers, basically the patient list
  topHeaders: [],

  // Lists versions per top header
  subHeaders: [],
});

// Contains sets of cells that need to be displayed for each rule
const ruleCells = ref(null);

// Clears settings, sections, rule dependencies,
// headers and rule cells.
function resetData() {
  analyzeConfig.value = null;
  settings.value = null;
  sections.value = null;
  ruleDependencies.value = null;
  headers.value = null;
  ruleCells.value = null;
}

// The last thing we do when filling a configuration is
// set the sections and rule cells.
const isLoading = computed(() => !ruleCells.value || !sections.value);

// String representation of the given value.
function valueRepr(answer) {
  if (answer === true) {
    return 'ja';
  }

  if (answer === false) {
    return 'nee';
  }

  if (isUndefined(answer)) {
    return '⚠';
  }

  if (answer === null) {
    return '-';
  }

  if (isArray(answer) || isObject(answer)) {
    return JSON.stringify(answer);
  }

  return answer.toString();
}

function shorten(value, length = 10) {
  value = value?.toString();
  return truncate(value, { length });
}

function isExternalInput(rule) {
  return !!rule.externalinput;
}

//#region Section / rule merging
function mergeInto(current, other, nestedMerge = null) {
  // The index we will insert items at if no matching item
  // can be found in the current list.
  let insertAt = 0;
  for (const item of other) {
    // Attempt to find the current item in the list
    const idx = current.findIndex((it) => it.id === item.id);

    if (idx < 0) {
      // No matching item found, insert at the last known index
      current.splice(insertAt, 0, item);
      insertAt++;
    } else {
      // Matching item found, insert consecutive items at the next index,
      // and call nested merge if provided.
      insertAt = idx + 1;

      if (nestedMerge) {
        nestedMerge(current[idx], item);
      }
    }
  }
}

// Merges the given list of lists into a single list, removing duplicates and
// trying to retain the order of the two lists as much as possible. Item matching is
// done by ID. If two items match, nestedMerge is called on those two items.
function mergeLists(listOfLists, nestedMerge = null) {
  const result = [];
  for (const list of listOfLists) {
    mergeInto(result, list, nestedMerge);
  }

  return result;
}

//#endregion

// Creates item categories based on occurrence count.
function categorize(values) {
  // Count occurrences
  const categories = countBy(values);

  // If we have only individual values for a sufficiently large
  // list, assume we're dealing with very individual patient values
  // and disable categories altogether.
  // if (values.length > 10 && Object.keys(categories).length === values.length) {
  //   return Array(values.length).fill(0);
  // }

  // Sort values from most to least common occurrence.
  const sorted = uniq(sortBy(values, (v) => -categories[v]));

  // Now we can create a map from value => category
  const catMap = Object.fromEntries(sorted.map((value, i) => [value, i]));
  return values.map((v) => catMap[v]);
}

// Builds a dependency map for the given list of sections. Output is
// an object that maps each rule ID to an object with two keys:
// - rules: a Set() of other rule IDs that this rule depends on
// - sections: a Set() of other section IDs that this rule depends on
function buildDependencies(sections) {
  const dependencies = {};
  const ruleMap = {};

  // Build a map of all rules and the base of the dependency graph.
  // Note that a rule can appear more
  // than once in case it has been moved to a different section
  // between configurations.
  for (let section of sections) {
    for (let rule of section.rules) {
      if (rule.id in ruleMap) {
        ruleMap[rule.id].push({ rule, section });
      } else {
        ruleMap[rule.id] = [{ rule, section }];
      }
    }
  }

  // Recursively fetches rule dependencies.
  function buildRuleDependencies(rule, section) {
    if (rule.id in dependencies) {
      // Already traversed this rule, though we might not have
      // traversed it for the current section pair.
      const current = dependencies[rule.id];

      if (current === null) {
        // This means we're currently traversing it, which is a problem
        throw Error(`Encountered circular reference for rule ID ${rule.id}`);
      }

      current.sections.add(section.id);
      return current;
    }

    // To detect circular references
    dependencies[rule.id] = null;

    // Lists of section / rule IDs that this rule depends on recursively
    // This includes the rule / section IDs themselves for convenience.
    const sectionIds = new Set([section.id]);
    const ruleIds = new Set([rule.id]);

    for (let line of rule.lines) {
      // Go through lines that use other rules as input
      if (!line.input_rule) {
        continue;
      }

      const pairs = ruleMap[line.input_rule.id];
      for (const linePair of pairs) {
        ruleIds.add(linePair.rule.id);
        sectionIds.add(linePair.section.id);

        // Recurse
        const nested = buildRuleDependencies(linePair.rule, linePair.section);
        nested.rules.forEach((v) => ruleIds.add(v));
        nested.sections.forEach((v) => sectionIds.add(v));
      }
    }

    return (dependencies[rule.id] = {
      sections: sectionIds,
      rules: ruleIds,
    });
  }

  for (let section of sections) {
    for (let rule of section.rules) {
      buildRuleDependencies(rule, section);
    }
  }

  return dependencies;
}

async function applyAnalyze() {
  const counter = analyzeConfig.value?.counter ?? 0;
  resetData();

  // Set a config with a counter, so a new button press would refresh the page.
  analyzeConfig.value = {
    data: pendingConfigs.value[0],
    counter: counter + 1,
  };
}

// Initializes config comparison
async function applySettings() {
  resetData();

  if (!hasPendingConfig.value) {
    return;
  }

  // Collapse settings when they are applied
  expandSettings.value = false;
  const toEvaluate = pendingConfigs.value;
  const tenantId = pendingTenant.value.id;

  // Update current settings
  settings.value = {
    configs: cloneDeep(toEvaluate),
    tenantId: tenantId,
  };

  // Fetch all evaluations
  const evaluationRequests = toEvaluate.map((config) =>
    evaluateVersion(tenantId, config.version.id)
  );

  // Fetch all sections + rules
  const sectionRequests = toEvaluate.map(
    (config) => getRuleSections(config.version, true),
    true
  );

  // Merge all sections / rules
  const allSections = (await Promise.all(sectionRequests)).map(
    (response) => response.data
  );

  // Merge all sections and rules into a single ordered list
  const mergedSections = mergeLists(allSections, (s1, s2) => {
    // Called if two configurations shared the same section. The first section
    // will be kept in this case, and we merge the second section's rules
    // into it.
    mergeInto(s1.rules, s2.rules);
  });

  ruleDependencies.value = buildDependencies(mergedSections);

  const evaluations = (await Promise.all(evaluationRequests)).map(
    (response) => response.data
  );

  const evalCount = evaluations.length;
  const screeningCount = evaluations[0].length;

  // Maps rule IDs to value objects that should appear for that rule
  const results = {};

  function buildCell(configPair, rule, result, cat, first, last) {
    const v = result.rules[rule.id];
    const repr = valueRepr(v);
    const missing = v === undefined;

    return {
      key: `output-${configPair.version.id}-${rule.id}-${result.screening_id}`,

      // The original rule output value
      value: v,

      // String representation of the rule output value. Used for the
      // title,`short` is used for the actual cell value.
      display: missing ? 'Waarde ontbreekt in deze configuratie' : repr,

      short: shorten(repr),

      // Whether the rule output value was missing, i.e. not
      // defined in the configuration.
      missing,

      // Category of the rule output value. We only have 10 (well, 11 if we
      // include plain white) category colors, wrap around if there are too many.
      cat: cat > 10 ? 1 + (cat % 10) : cat,

      // Helper to indicate if this is the first evaluation of its kind
      first,
      last,
    };
  }

  if (evalCount > 1) {
    // Categorize within patient, so we can see how rule outputs differ
    // within a single patient across different config versions.
    for (const section of mergedSections) {
      for (const rule of section.rules) {
        const ruleResult = [];

        // Each evaluation contains the same screenings in the same order
        // Iterate all screenings.
        for (let i = 0; i < screeningCount; i++) {
          // For each screening, make a list of its output values in each evaluation.
          // The screening result set is at position i of each evaluation.
          const values = evaluations.map((evaluation) =>
            valueRepr(evaluation[i].rules[rule.id])
          );

          // Categorize the values by how often they occur
          const categories = categorize(values);

          // Push the screening rule output for each output value
          for (let j = 0; j < values.length; j++) {
            ruleResult.push(
              buildCell(
                toEvaluate[j],
                rule,
                evaluations[j][i],
                categories[j],

                // So we could add an extra thick line between different screenings.
                j === 0,
                j === values.length - 1
              )
            );
          }
        }

        results[rule.id] = ruleResult;
      }
    }
  } else {
    // Categorize across patients, so we can see how rule outputs differ
    // between different patients within the same configuration.
    const evaluation = evaluations[0];
    for (const section of mergedSections) {
      for (const rule of section.rules) {
        const ruleResult = [];

        // Gather the values for this rule for all screenings
        const values = evaluation.map((result) =>
          valueRepr(result.rules[rule.id])
        );

        // Calculate the categories they will belong to
        const categories = categorize(values);

        for (let i = 0; i < evaluation.length; i++) {
          ruleResult.push(
            buildCell(
              toEvaluate[0],
              rule,
              evaluation[i],
              categories[i],
              false,
              false
            )
          );
        }

        results[rule.id] = ruleResult;
      }
    }
  }

  // Build a header list
  const topHeaders = [];
  const subHeaders = [];

  for (let i = 0; i < screeningCount; i++) {
    // Screenings will be the same, it doesn't matter which one we take here
    const result = evaluations[0][i];

    topHeaders.push({
      colspan: evalCount,
      patient: result.patient,
      screening_id: result.screening_id,
    });

    for (const pair of toEvaluate) {
      subHeaders.push({
        patient: result.patient,
        screening_id: result.screening_id,
        config: pair.config,
        version: pair.version,
      });
    }
  }

  headers.value = {
    topHeaders,
    subHeaders,
  };
  sections.value = mergedSections;
  ruleCells.value = results;
}

// Loads all validation tenants
async function loadTenants() {
  const allTenants = (await getTenants()).data.data;
  tenants.value = allTenants.filter((t) => t.status === 'validation');
}

// Loads current configurations
async function loadConfigs() {
  configs.value = (await getConfigs()).data;

  // Preselect E+POS - vragenlijst
  pendingConfig.value =
    configs.value.find((c) => c.name.includes('- vragenlijst')) ??
    configs.value[0];
  pendingVersion.value = pendingConfig.value.versions[0];
}

onMounted(async () => {
  await Promise.all([loadConfigs(), loadTenants()]);

  pendingTenant.value = tenants.value.length ? tenants.value[0] : null;
  pendingConfigs.value = [
    {
      config: pendingConfig.value,
      version: pendingVersion.value,
    },
  ];
});
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';

$settings-collapsed: 50px;
$settings-expanded: 380px;

.settings {
  width: $settings-expanded;
  flex-basis: $settings-expanded;
  flex-grow: 0;
  flex-shrink: 0;
  border-right: solid 1px $bg-color-border;

  &.collapsed {
    width: $settings-collapsed;
    flex-basis: $settings-collapsed;
    overflow: hidden;
  }

  .inner {
    width: $settings-expanded;
    overflow: hidden;
  }
}

.settings-form {
  padding: 0.5rem;
}

.checkbox {
  line-height: 24px;
}

.version-selector {
  margin-left: 0.5rem;
}

.icon-button {
  margin: 0 0.5rem;
  cursor: pointer;
}

.config-selector {
  max-width: 200px;
}

// deep so the ConfigAnalyzer can use the same side panel styles.
:deep(.evaluate) {
  min-width: calc(100% - 90px - $settings-expanded);
  padding: 1rem;
  box-sizing: border-box;
  overflow-x: auto;

  &.settings-collapsed {
    min-width: calc(100% - 90px - $settings-collapsed);
  }
}

h2 {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.selected-versions {
  margin: 0 0 1rem 0;
  padding: 0;
  list-style: none;

  li {
    margin-left: 0;
    font-size: 0.9rem;
    line-height: 24px;
  }
}

table,
th,
td {
  border: 1px solid $bg-color-border;
  border-collapse: collapse;
  text-align: center;
}

tbody th {
  text-align: left;
}

td.last {
  border-right: 1px solid #666666;
}

thead {
  th {
    height: 1.2rem;
    line-height: 1.2rem;
    position: sticky;

    // Container padding is 1rem, that's where the
    // first line stops. The second line stops just
    // below that.
    top: calc(0.2rem + 1px);
  }

  tr:first-of-type th {
    top: -1rem;
  }
}

table {
  font-size: 0.8rem;
}

th,
td {
  padding: 4px;
}

th {
  background: #f0f0f0;
}

.rule-label {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.focused .rule-label {
  color: darkblue;
  text-decoration: underline;
}

// Rules that aren't part of our focus scope
.hidden {
  display: none;
}

.hide-external-inputs .external-input {
  display: none;
}

.field + .field {
  margin-top: 1rem;
}

select {
  @include input-box;
}

// Cell backgrounds
.cat-0 {
  background-color: #fff;
}

.cat-1 {
  background-color: #add8e6;
}

.cat-2 {
  background-color: #98fb98;
}

.cat-3 {
  background-color: #ffffcc;
}

.cat-4 {
  background-color: #e6e6fa;
}

.cat-5 {
  background-color: #ffdab9;
}

.cat-6 {
  background-color: #f5fffa;
}

.cat-7 {
  background-color: #ffb6c1;
}

.cat-8 {
  background-color: #87ceeb;
}

.cat-9 {
  background-color: #fffdd0;
}

.cat-10 {
  background-color: #c8a2c8;
}

.missing {
  font-style: italic;
  color: #8e8e8e;
  text-align: center;
}
</style>
