var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"analyze evaluate"},[(_setup.isLoading)?_c('div',{staticClass:"center"},[_c('font-awesome-icon',{attrs:{"icon":"spinner","size":"2x"}})],1):_c('div',[_c('section',[_c('h1',[_vm._v("Ongebruikte regels")]),_c('ol',{staticClass:"relateds"},_vm._l((_setup.unusedRules),function(rule){return _c('li',{key:`unused-rule-${rule.id}`,staticClass:"related"},[_c('router-link',{attrs:{"target":"_blank","to":{
              name: 'rules',
              params: {
                versionId: _setup.version.id,
                sectionId: rule.section.id,
                ruleId: rule.id,
              },
            }},nativeOn:{"click":function($event){return _setup.passSessionToken()}}},[_vm._v(" #R"+_vm._s(rule.id)+" - "+_vm._s(rule.label)+" ")]),(rule.reasons?.length)?_c('ul',{staticClass:"reasons"},[_vm._l((rule.reasons),function(reason,i){return _c('li',{key:`rule-reason-${i}`},[_vm._v(" "+_vm._s(reason)+" ")])}),_vm._l((rule.invalid_lines),function(line){return _vm._l((line.reasons),function(reason,i){return _c('li',{key:`invalid-line-${line.id}-${i}`},[_vm._v(" L#"+_vm._s(line.id)+": "+_vm._s(reason)+" ")])})})],2):_vm._e()],1)}),0)]),_c('section',[_c('h1',[_vm._v("Ongebruikte vragen")]),_c('ol',{staticClass:"relateds"},_vm._l((_setup.unusedQuestions),function(question){return _c('li',{key:`unused-question-${question.id}`,staticClass:"related"},[_c('router-link',{attrs:{"target":"_blank","to":{
              name: 'questions',
              params: {
                versionId: _setup.version.id,
                sectionId: question.section.id,
                questionId: question.id,
              },
            }},nativeOn:{"click":function($event){return _setup.passSessionToken()}}},[_vm._v(" #V"+_vm._s(question.id)+" - "+_vm._s(question.text)+" ")])],1)}),0)]),_c('section',[_c('h1',[_vm._v("Ongeldige regels")]),_c('ol',{staticClass:"relateds"},_vm._l((_setup.invalidRules),function(rule){return _c('li',{key:`invalid-rule-${rule.id}`,staticClass:"related"},[_c('router-link',{attrs:{"target":"_blank","to":{
              name: 'rules',
              params: {
                versionId: _setup.version.id,
                sectionId: rule.section.id,
                ruleId: rule.id,
              },
            }},nativeOn:{"click":function($event){return _setup.passSessionToken()}}},[_vm._v(" #R"+_vm._s(rule.id)+" - "+_vm._s(rule.label)+" ")]),(rule.reasons?.length)?_c('ul',{staticClass:"reasons"},[_vm._l((rule.reasons),function(reason,i){return _c('li',{key:`rule-reason-${i}`},[_vm._v(" "+_vm._s(reason)+" ")])}),_vm._l((rule.invalid_lines),function(line){return _vm._l((line.reasons),function(reason,i){return _c('li',{key:`invalid-line-${line.id}-${i}`},[_vm._v(" L#"+_vm._s(line.id)+": "+_vm._s(reason)+" ")])})})],2):_vm._e()],1)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }