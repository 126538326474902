<template>
  <div>
    <button
      v-for="supportedLanguage in supportedLanguages"
      :key="supportedLanguage.value"
      :class="{
        'btn-dark': language !== supportedLanguage.value,
        'btn-light': language === supportedLanguage.value,
      }"
      @click="$emit('setLanguage', supportedLanguage.value)"
      class="btn language"
    >
      {{ supportedLanguage.display }}
    </button>

    <template v-if="translationImport">
      <button
        v-if="translationImport"
        @click="$emit('downloadLanguage', language)"
        class="btn btn-dark"
        title="Exporteer vertalingen"
      >
        <font-awesome-icon icon="download" />
      </button>
      <button
        v-if="translationImport"
        @click="$emit('uploadLanguage', language)"
        class="btn btn-dark"
        title="Importeer vertalingen"
      >
        <font-awesome-icon icon="upload" />
      </button>
    </template>
  </div>
</template>

<script>
export default {
  props: ['language', 'languages', 'translationImport'],
  computed: {
    supportedLanguages() {
      return this.languages.map((lang) => {
        return { value: lang, display: lang.toUpperCase() };
      });
    },
  },
};
</script>
