<template>
  <div class="container">
    <form
      class="login"
      @submit.prevent="login"
      v-if="$root.user.authenticator_enabled"
    >
      <div class="title">E+POS Verificatiecode</div>
      <div class="field">
        <div class="label">
          Open de authenticator app op je mobiele telefoon <br />en vul
          hieronder de code in.
        </div>
        <input
          type="text"
          required
          v-model="code"
          class="form-control"
          id="passcode"
          aria-describedby="passcode"
          placeholder="Verificatiecode"
          autofocus
        />
      </div>
      <div class="message" v-if="message">{{ message }}</div>

      <div class="buttons">
        <button class="btn btn-dark" type="submit">Verstuur</button>
      </div>
    </form>

    <form class="login" @submit.prevent="login" v-else>
      <div class="title">E+POS Configuratie</div>
      <div class="field">
        <label for="2fa_code">SMS Code</label>
        <input
          type="text"
          required
          v-model="code"
          class="form-control"
          id="2fa_code"
          aria-describedby="smscode"
          placeholder="Enter sms code"
          autofocus
        />
      </div>
      <div class="message" v-if="message">{{ message }}</div>
      <div class="confirm" v-if="confirm">{{ confirm }}</div>

      <div class="buttons">
        <button class="btn btn-dark" type="submit">Login</button>
        <button
          type="button"
          @click="resend"
          class="btn btn-novacair-light resend"
        >
          Geen code ontvangen
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { twoFactor, resendSms } from '@/api';
import { getCurrentUser } from '@/helpers';

export default {
  name: 'TwoFactor',
  data: function () {
    return {
      code: null,
      confirm: null,
      message: null,
    };
  },
  methods: {
    async login() {
      try {
        const response = await twoFactor(this.code);
        sessionStorage.setItem('token', response.data);
        this.$root.user = getCurrentUser();
        this.$router.push('/');
      } catch (error) {
        if (error.response.data.message === 'invalid-otp-code') {
          this.message = 'Onjuiste code';
        } else {
          this.message = 'Inloggen mislukt';
        }
      }
    },
    async resend() {
      try {
        await resendSms();
        this.confirm = 'Er is een nieuwe code verzonden';
      } catch (error) {
        this.message = 'Er is iets mis gegaan bij het inloggen.';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  font-family: $font-family-gitlab;
  font-size: 14px;
  height: 100vh;
  width: 100vw;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .login {
    border: solid 1px lightgrey;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    border-radius: 3px;
    padding: 30px;
    display: flex;
    flex-direction: column;

    .title {
      width: 100%;
      text-align: center;
      font-size: 18px;
      padding: 10px 0 20px 0;
    }

    .field {
      padding: 3px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .label {
        padding-bottom: 15px;
        color: grey;
        text-align: center;
      }

      input {
        width: 250px;
        border: solid 1px lightgrey;
        border-radius: 2px;
        padding: 11px 7px;
      }
    }

    .message {
      margin: 15px 0 0 0;
      padding: 8px 8px;
      color: #721c24;
      background-color: #f8d7da;
      border: 1px solid #f5c6cb;
      border-radius: 3px;
    }

    .confirm {
      margin: 15px 0 0 0;
      padding: 8px 8px;
      color: #155724;
      background-color: #d4edda;
      border: 1px solid #c3e6cb;
      border-radius: 3px;
    }

    .buttons {
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .resend {
      margin-left: 10px;
    }
  }
}
</style>
