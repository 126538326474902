<template>
  <div class="analyze evaluate">
    <div class="center" v-if="isLoading">
      <font-awesome-icon icon="spinner" size="2x" />
    </div>
    <div v-else>
      <section>
        <h1>Ongebruikte regels</h1>
        <ol class="relateds">
          <li
            v-for="rule in unusedRules"
            :key="`unused-rule-${rule.id}`"
            class="related"
          >
            <router-link
              target="_blank"
              @click.native="passSessionToken()"
              :to="{
                name: 'rules',
                params: {
                  versionId: version.id,
                  sectionId: rule.section.id,
                  ruleId: rule.id,
                },
              }"
            >
              #R{{ rule.id }} - {{ rule.label }}
            </router-link>

            <ul class="reasons" v-if="rule.reasons?.length">
              <li v-for="(reason, i) in rule.reasons" :key="`rule-reason-${i}`">
                {{ reason }}
              </li>
              <template v-for="line in rule.invalid_lines">
                <li
                  v-for="(reason, i) in line.reasons"
                  :key="`invalid-line-${line.id}-${i}`"
                >
                  L#{{ line.id }}: {{ reason }}
                </li>
              </template>
            </ul>
          </li>
        </ol>
      </section>
      <section>
        <h1>Ongebruikte vragen</h1>
        <ol class="relateds">
          <li
            v-for="question in unusedQuestions"
            :key="`unused-question-${question.id}`"
            class="related"
          >
            <router-link
              target="_blank"
              @click.native="passSessionToken()"
              :to="{
                name: 'questions',
                params: {
                  versionId: version.id,
                  sectionId: question.section.id,
                  questionId: question.id,
                },
              }"
            >
              #V{{ question.id }} - {{ question.text }}
            </router-link>
          </li>
        </ol>
      </section>
      <section>
        <h1>Ongeldige regels</h1>
        <ol class="relateds">
          <li
            v-for="rule in invalidRules"
            :key="`invalid-rule-${rule.id}`"
            class="related"
          >
            <router-link
              target="_blank"
              @click.native="passSessionToken()"
              :to="{
                name: 'rules',
                params: {
                  versionId: version.id,
                  sectionId: rule.section.id,
                  ruleId: rule.id,
                },
              }"
            >
              #R{{ rule.id }} - {{ rule.label }}
            </router-link>

            <ul class="reasons" v-if="rule.reasons?.length">
              <li v-for="(reason, i) in rule.reasons" :key="`rule-reason-${i}`">
                {{ reason }}
              </li>
              <template v-for="line in rule.invalid_lines">
                <li
                  v-for="(reason, i) in line.reasons"
                  :key="`invalid-line-${line.id}-${i}`"
                >
                  L#{{ line.id }}: {{ reason }}
                </li>
              </template>
            </ul>
          </li>
        </ol>
      </section>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { analyzeVersion } from '@/api';
import { passSessionToken } from '@/util/session-sync';

const props = defineProps({
  settings: Object,
});

const analysis = ref(null);
const isLoading = computed(() => !analysis.value);
const unusedRules = computed(() => analysis.value?.unused_rules ?? []);
const unusedQuestions = computed(() => analysis.value?.unused_questions ?? []);
const invalidRules = computed(() => analysis.value?.invalid_rules ?? []);
const version = computed(() => props.settings?.data.version);

async function updateAnalysis() {
  analysis.value = null;

  if (!version.value) {
    return;
  }

  const versionId = version.value.id;
  analysis.value = (await analyzeVersion(versionId)).data;
}

watch(
  () => props.settings.counter,
  () => updateAnalysis(),
  { flush: 'post', immediate: true }
);
</script>

<style scoped lang="scss">
.relateds {
  list-style: none;
  padding: 0;
  margin: 0;

  .related {
    padding: 0.25rem;
    margin: 0;
  }

  .related a {
    font-size: 1rem;
  }
}

.reasons {
  margin-top: 0.75rem;
}

.reasons li {
  color: #999;
  padding: 0.25rem 0.25rem;
}
</style>
