<template>
  <div class="container">
    <form class="login" @submit.prevent="login">
      <div class="title">E+POS Configuratie</div>
      <div class="field">
        <label for="email">Email address</label>
        <input
          type="email"
          v-model="email"
          class="form-control"
          id="email"
          aria-describedby="emailHelp"
          placeholder="Enter email"
          autofocus
        />
      </div>
      <div class="field">
        <label for="password">Password</label>
        <input
          v-model="password"
          type="password"
          class="form-control"
          id="password"
          placeholder="Password"
        />
      </div>
      <div class="message" v-if="message" v-html="message"></div>
      <button class="btn btn-dark" type="submit">Login</button>
    </form>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import { login } from '@/api';
import { getCurrentUser } from '@/helpers';

export default {
  name: 'Login',
  data: function () {
    return {
      email: null,
      password: null,
      message: null,
    };
  },
  methods: {
    async login() {
      try {
        const response = await login(this.email, this.password);
        sessionStorage.setItem('token', response.data);
        this.$root.user = getCurrentUser();

        if (this.$root.user.permissions.includes('config_app')) {
          this.$router.push('/');
        } else {
          this.$router.push('/twofactor');
        }
      } catch (error) {
        if (error.response.data.message === 'ip-block') {
          this.message =
            'Geen toegang vanaf dit IP-adres(' +
            error.response.data.metadata.ip +
            ')';
        } else if (error.response.data.message === 'missing-mobile') {
          this.message =
            'Er is geen telefoonnummer bekend bij dit account. <br/> Neem contact op met de beheerder.';
        } else {
          this.message = 'Gebruikersnaam of wachtwoord onbekend';
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  font-family: $font-family-gitlab;
  font-size: 14px;
  height: 100vh;
  width: 100vw;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .login {
    border: solid 1px lightgrey;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    border-radius: 3px;
    padding: 30px;
    display: flex;
    flex-direction: column;

    .title {
      width: 100%;
      text-align: center;
      font-size: 18px;
      padding: 10px 0 20px 0;
    }

    .field {
      padding: 3px 0;
      display: flex;
      flex-direction: row;

      label {
        padding-top: 8px;
        width: 100px;
      }

      input {
        width: 250px;
        border: solid 1px lightgrey;
        border-radius: 2px;
        padding: 8px 4px;
      }
    }

    .message {
      margin: 15px 0 0 0;
      padding: 8px 8px;
      color: #721c24;
      background-color: #f8d7da;
      border: 1px solid #f5c6cb;
      border-radius: 3px;
    }

    button {
      margin: 15px 120px 5px 120px;
    }
  }
}
</style>
