<template>
  <div class="container">
    <div class="subheader">
      <template v-if="initialized">
        <div class="title" v-if="questionId">
          <router-link
            :to="{
              name: 'questions',
              params: { sectionId: sectionId, questionId: question.id },
            }"
            class="link"
          >
            #V{{ question.id }}
          </router-link>
          - {{ questionLanguageText() }}
        </div>
        <div class="title" v-if="!questionId">Nieuwe vraag</div>
        <div class="buttons" v-if="enabled">
          <button
            type="button"
            class="btn btn-invisible-grey"
            @click="deleteQuestion"
            v-if="questionId > 0"
          >
            Verwijderen
          </button>
          <button
            type="button"
            class="btn btn-dark"
            @click="saveQuestion"
            :disabled="!changed"
          >
            Opslaan
          </button>
        </div>
      </template>
    </div>

    <div class="scroller">
      <div class="center" v-if="!initialized && questionId">
        <font-awesome-icon icon="spinner" size="2x" />
      </div>
      <div class="question" v-if="initialized">
        <div class="field">
          <label>Sectie</label>
          <select v-model="question.section" :disabled="!enabled">
            <option
              v-for="section in sections"
              :key="section.id"
              :value="section"
            >
              {{ languageFallback(section, 'title') }}
            </option>
          </select>
        </div>

        <MultiLanguageInputField
          :language="language"
          :inputType="'textarea'"
          class="field"
          :label="'Vraag'"
          :model="question"
          :valueKey="'text'"
          :enabled="enabled"
        />
        <MultiLanguageInputField
          :language="language"
          :inputType="'textarea'"
          :label="'Uitleg patient'"
          class="field"
          :model="question"
          :valueKey="'explanation'"
          :enabled="enabled"
        />

        <div class="field">
          <label>Notities</label>
          <textarea v-model="question.notes" :disabled="!enabled"></textarea>
        </div>

        <div class="field">
          <label>Conditie</label>
          <select
            @change="question.condition_option_id = null"
            v-model="conditionQuestion1"
            :disabled="!enabled"
          >
            <option :value="null">-</option>
            <option
              v-for="q in conditionQuestions"
              :key="q.id"
              :value="q"
              :title="languageFallback(q, 'text')"
            >
              #V{{ q.id }} - {{ truncate(languageFallback(q, 'text')) }}
            </option>
          </select>

          <select
            v-model="question.condition_option_id"
            v-if="conditionQuestion1"
            :disabled="!enabled"
          >
            <option
              v-for="option in conditionQuestion1.options"
              :key="option.id"
              :value="option.id"
              :title="languageFallback(option, 'text')"
            >
              {{ truncate(languageFallback(option, 'text')) }}
            </option>
          </select>
        </div>
        <div
          v-if="
            question.condition_option_id !== null ||
            question.condition_option_2_id !== null
          "
        >
          <div class="field">
            <label>En / Of</label>

            <SelectField
              v-if="conditionOperatorSelected"
              :options="operatorOptions"
              placeholder=""
              :label="(option) => option.label"
              :multiple="false"
              :searchable="false"
              :disabled="!enabled"
              :value="conditionOperatorSelected"
              @input="setConditionOperator"
            />
          </div>

          <div
            class="field"
            v-if="
              question.condition_option_id !== null ||
              question.condition_option_2_id !== null
            "
          >
            <label>Deze conditie</label>
            <select
              @change="question.condition_option_2_id = null"
              v-model="conditionQuestion2"
              :disabled="!enabled"
            >
              <option :value="null">-</option>
              <option
                v-for="q in conditionQuestions"
                :key="q.id"
                :value="q"
                :title="languageFallback(q, 'text')"
              >
                #V{{ q.id }} - {{ truncate(languageFallback(q, 'text')) }}
              </option>
            </select>
            <select
              v-model="question.condition_option_2_id"
              v-if="conditionQuestion2"
              :disabled="!enabled"
            >
              <option
                v-for="option in conditionQuestion2.options"
                :key="option.id"
                :value="option.id"
                :title="languageFallback(option, 'text')"
              >
                {{ truncate(languageFallback(option, 'text')) }}
              </option>
            </select>
          </div>
        </div>

        <div class="field select">
          <label>Speciale conditie</label>
          <SelectField
            :options="specialConditions"
            placeholder="Special Conditie"
            :label="(option) => option.label"
            :value="specialConditionSelected"
            :multiple="false"
            :searchable="true"
            :disabled="!enabled"
            @input="setSpecialCondition"
          />
        </div>

        <div class="field positive-tags">
          <label>Tags inclusief</label>
          <SelectField
            :options="positiveTagsAvailable"
            placeholder="Tags Inclusief"
            :label="(option) => option.label"
            :value="positiveTagsSelected"
            :multiple="true"
            :searchable="true"
            @input="setPositiveTags"
          />
        </div>

        <div class="field negative-tags">
          <label>Tags exclusief</label>
          <SelectField
            :options="negativeTagsAvailable"
            placeholder="Tags exclusief"
            :label="(option) => option.label"
            :value="negativeTagsSelected"
            :multiple="true"
            :searchable="true"
            @input="setNegativeTags"
          />
        </div>

        <div class="field">
          <label>Speciaal type</label>
          <SelectField
            :options="specialTypes"
            placeholder="Speciaal Type"
            :label="(option) => option.label"
            :value="specialTypeSelected"
            :multiple="false"
            :searchable="true"
            :disabled="!enabled"
            @input="setSpecialType"
          />
        </div>

        <div class="field" v-if="!question.special_type">
          <label>Type</label>
          <select v-model="question.type" :disabled="!enabled">
            <option v-for="type in types" :key="type.id" :value="type.label">
              {{ type.description }}
            </option>
          </select>
        </div>

        <div class="field" v-if="showOptionInputs">
          <label>Keuzes</label>

          <draggable
            class="options"
            handle=".draghandle"
            :forceFallback="true"
            element="div"
            v-model="question.options"
            animation="200"
            :disabled="!enabled"
          >
            <div
              class="option"
              v-for="option in question.options"
              :key="option.id"
            >
              <font-awesome-icon
                :icon="['fas', 'arrows-alt']"
                class="draghandle"
                v-if="enabled"
              />
              <MultiLanguageInputField
                :enabled="enabled"
                :language="language"
                inputType="input"
                :model="option"
                @afterInputChanged="changeOption"
                class="option-field"
                valueKey="text"
              />

              <select
                v-if="question.special_type === 'bloodthinner-options'"
                v-model="option.type"
              >
                <option
                  v-for="type in bloodthinnerOptions"
                  :key="type.value"
                  :value="type.value"
                >
                  {{ type.label }}
                </option>
              </select>

              <button
                @click.prevent="deleteOption(option)"
                v-if="enabled && (option.text || option.id)"
                class="btn btn-invisible"
                title="Verwijderen"
              >
                <font-awesome-icon :icon="['fas', 'trash-alt']" />
              </button>
            </div>
          </draggable>
        </div>

        <div class="divider" v-if="question.related_rules.length > 0"></div>

        <div class="field" v-if="question.related_rules.length > 0">
          <label>Gebruikt in regels</label>
          <div class="relateds">
            <div
              v-for="relatedRule in question.related_rules"
              :key="relatedRule.id"
              class="related"
            >
              <router-link
                :to="{
                  name: 'rules',
                  params: {
                    versionId: version.id,
                    sectionId: relatedRule.section.id,
                    ruleId: relatedRule.id,
                  },
                }"
              >
                #R{{ relatedRule.id }} - {{ relatedRule.label }}
              </router-link>
              <span class="sectionref"> - {{ relatedRule.section.label }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import api, {
  getQuestion,
  getQuestionSections,
  getQuestionsSection,
  getSpecialConditions,
  getSpecialQuestionTypes,
} from '@/api';
import _, { difference, isEmpty } from 'lodash';
import types from '@/config/questionTypes';
import languages from '@/languages';
import MultiLanguageInputField from './MultiLanguageInputField';
import SelectField from './elements/SelectField';
import promiseStore from '../promiseStore';
import bloodthinnerOptions from '../config/bloodthinnerOptions';

export default {
  props: ['sectionId', 'questionId', 'version', 'language'],
  components: { SelectField, MultiLanguageInputField },
  data() {
    return {
      types,
      specialTypes: [],
      specialConditions: [],
      questions: null,
      question: null,
      sections: null,
      section: null,
      originalQuestion: null,
      conditionQuestion1: null,
      conditionQuestion2: null,
      specialConditionSelected: null,
      tagsAvailable: [],
      specialTypeSelected: null,
      positiveTagsSelected: null,
      negativeTagsSelected: null,
      bloodthinnerOptions: bloodthinnerOptions,
      conditionOperatorSelected: null,
      operatorOptions: [
        {
          value: 'or',
          label: 'OF',
        },
        {
          value: 'and',
          label: 'EN',
        },
      ],
    };
  },
  beforeRouteLeave(to, from, next) {
    this.guard(next);
  },
  beforeRouteUpdate(to, from, next) {
    this.guard(next);
  },
  created() {
    this.load();
  },
  computed: {
    positiveTagsAvailable() {
      const tags = this.tagsAvailable.filter((tag) => {
        return !(this.question.negative_tags || []).find(
          (tagValue) => tagValue === tag.value
        );
      });

      const missingTags = difference(
        this.question.positive_tags,
        tags.map((v) => v.value)
      ).map((tag) => {
        return { name: 'Missing:' + tag, value: tag };
      });

      return [...tags, ...missingTags];
    },
    negativeTagsAvailable() {
      const tags = this.tagsAvailable.filter((tag) => {
        return !(this.question.positive_tags || []).find(
          (tagValue) => tagValue === tag.value
        );
      });

      const missingTags = difference(
        this.question.negative_tags,
        tags.map((v) => v.value)
      ).map((tag) => {
        return { name: 'Missing:' + tag, value: tag };
      });

      return [...tags, ...missingTags];
    },
    enabled() {
      return !!this.version.open;
    },
    initialized() {
      return (
        this.questions !== null &&
        this.question !== null &&
        this.sections !== null &&
        this.section !== null
      );
    },
    conditionQuestions() {
      return this.questions
        .filter(this.canHaveOptions)
        .filter((q) => q.id !== this.question.id);
    },
    changed() {
      return !_.isEqual(this.originalQuestion, this.question);
    },
    hasQuestionId() {
      return (
        this.questionId || this.questionId === 0 || this.questionId === '0'
      );
    },
    exists() {
      return this.questionId > 0;
    },
    showOptionInputs() {
      if (this.question.special_type === 'bloodthinner-options') {
        return true;
      }

      return (
        !this.question.special_type &&
        (this.question.type === 'select' ||
          this.question.type === 'multiselect' ||
          this.question.type === 'score')
      );
    },
  },
  methods: {
    setConditionOperator(option) {
      this.question.condition_operator = option.value;
    },
    setSpecialCondition(type) {
      this.question.special_condition = type ? type.value : null;
    },
    setSpecialType(type) {
      this.question.special_type = type ? type.value : null;
    },
    setNegativeTags(tags) {
      if (!isEmpty(tags)) {
        this.question.negative_tags = tags.map((tag) => tag.value);
      } else {
        this.question.negative_tags = null;
      }
    },
    setPositiveTags(tags) {
      if (!isEmpty(tags)) {
        this.question.positive_tags = tags.map((tag) => tag.value);
      } else {
        this.question.positive_tags = null;
      }
    },
    languageFallback(item, key) {
      return languages.getValue(this.language, item, key);
    },
    questionLanguageText() {
      return languages.getValue(this.language, this.originalQuestion, 'text');
    },
    guard(next) {
      if (this.changed) {
        this.$emit('popup', {
          title: 'Weet je zeker dat je wilt weggaan?',
          content: 'De wijzigingen in de vraag zullen niet opgeslagen worden',
          image: 'question',
          confirm: () => {
            this.$emit('popup', null);
            next();
          },
          cancel: () => {
            this.$emit('popup', null);
            next(false);
          },
        });
      } else {
        next();
      }
    },
    canHaveOptions(question) {
      return _.includes(['select', 'multiselect', 'score'], question.type);
    },
    initTagsAvailable() {
      const questionTagsPromise = promiseStore.getOrSet('tags-available', () =>
        api.getTagsAvailable().then((response) => response.data)
      );

      questionTagsPromise.then((tags) => {
        this.tagsAvailable = tags;
      });
    },
    async initSpecialConditions() {
      const response = await promiseStore.getOrSet('special-conditions', () =>
        getSpecialConditions()
      );

      this.specialConditions = response.data;
    },
    async initSpecialQuestionTypes() {
      const response = await promiseStore.getOrSet(
        'special-question-types',
        () => getSpecialQuestionTypes()
      );
      this.specialTypes = response.data;
    },
    async load() {
      this.initTagsAvailable();
      await Promise.all([
        this.initSpecialConditions(),
        this.initSpecialQuestionTypes(),
      ]);

      if (!this.hasQuestionId) {
        return;
      }

      this.sections = (await getQuestionSections(this.version)).data;
      this.section = (
        await getQuestionsSection(this.version, this.sectionId)
      ).data;

      this.questions = this.section.questions;
      const question =
        this.questionId > 0
          ? (await getQuestion(this.version, this.questionId)).data
          : this.getNewQuestion();

      this.setQuestion(question);
    },
    setQuestion(question) {
      this.question = question;
      this.question.section = this.sections.find(
        (s) => s.id === this.question.section.id
      );
      this.appendOptionRowIfNeeded();

      this.originalQuestion = _.cloneDeep(this.question);
      this.conditionQuestion1 = this.findQuestionByOptionId(
        this.question.condition_option_id
      );
      this.conditionQuestion2 = this.findQuestionByOptionId(
        this.question.condition_option_2_id
      );
      this.conditionOperatorSelected = this.operatorOptions.find(
        (v) => v.value === question.condition_operator
      );

      if (question.positive_tags) {
        this.positiveTagsSelected = question.positive_tags.map((value) => {
          return this.tagsAvailable.find((t) => value === t.value);
        });
      } else {
        this.positiveTagsSelected = null;
      }

      if (question.negative_tags) {
        this.negativeTagsSelected = question.negative_tags.map((value) => {
          return this.tagsAvailable.find((t) => value === t.value);
        });
      } else {
        this.negativeTagsSelected = null;
      }

      const specialTypeSelected = this.specialTypes.find(
        (v) => v.value === this.question.special_type
      );

      if (specialTypeSelected) {
        this.specialTypeSelected = [specialTypeSelected];
      }

      const specialCondition = this.specialConditions.find(
        (v) => v.value === this.question.special_condition
      );

      if (specialCondition) {
        this.specialConditionSelected = [specialCondition];
      }
    },
    getNewQuestion() {
      return {
        section: this.section,
        type: 'select',
        text: languages.newTranslationObject('?'),
        position: 1000000,
        is_questionnaire_replacement: 0,
        options: [
          this.getNewOption('text', languages.newTranslationObject('yes')),
          this.getNewOption('text', languages.newTranslationObject('no')),
        ],
        notes: '',
        explanation: languages.newTranslationObject(null),
        condition_option_id: null,
        condition_option_2_id: null,
        condition_operator: this.operatorOptions[0].value,
        related_rules: [],
        special_type: null,
      };
    },
    /**
     * Create a new 'option' for multiple choice questions.
     *
     * @param key
     * @param languageValues
     * @returns {{id: null}}
     */
    getNewOption(key, languageValues = null) {
      const newOption = {
        id: null,
      };

      newOption[key] = languageValues
        ? languageValues
        : languages.newTranslationObject(null);

      return newOption;
    },
    truncate(value, length = 45) {
      return value && value.length > length
        ? value.substring(0, length) + '...'
        : value;
    },
    findQuestionByOptionId: function (optionId) {
      return this.questions.find((q) => this.questionHasOption(q, optionId));
    },
    questionHasOption(question, optionId) {
      return (
        question.options && question.options.find((o) => o.id === optionId)
      );
    },
    saveQuestion() {
      const payload = _.cloneDeep(this.question);

      payload.options = payload.options.filter((o) => o.text);

      if (payload.section.id !== parseInt(this.sectionId)) {
        payload.condition_option_id = null;
        payload.condition_option_2_id = null;
        payload.condition_operator = this.operatorOptions[0].value;
        payload.position = 0;
      } else {
        payload.condition_option_id = payload.condition_option_id || null;
        payload.condition_option_2_id = payload.condition_option_2_id || null;
        payload.condition_operator = payload.condition_operator || null;
      }

      if (this.exists) {
        api
          .updateQuestion(this.version, payload)
          .then((response) => {
            this.setQuestion(response.data);
            if (this.question.section.id !== parseInt(this.sectionId)) {
              this.pushQuestion(this.question);
            } else {
              this.$emit('refreshMiddle');
            }
          })
          .catch((error) => {
            this.setQuestion(this.originalQuestion);

            this.$emit('popup', {
              title: 'Kan wijzingen niet opslaan.',
              image: 'error',
              error: error.response.data.message,
              confirm: () => {
                this.$emit('popup', null);
              },
            });
          });
      } else {
        api.insertQuestion(this.version, payload).then((response) => {
          this.setQuestion(response.data);
          this.$emit('refreshMiddle');
          this.pushQuestion(this.question);
        });
      }
    },
    pushQuestion(question) {
      this.$router.push({
        name: 'questions',
        params: {
          versionId: this.version.id,
          sectionId: question.section.id,
          questionId: question.id,
        },
      });
    },
    deleteQuestion() {
      this.$emit('popup', {
        title: 'Vraag verwijderen?',
        content: 'Dit kan niet ongedaan gemaakt worden.',
        image: 'question',
        confirm: () => {
          this.$emit('popup', null);
          api
            .deleteQuestion(this.version, this.question)
            .then(() => {
              this.$router.push({
                name: 'questions',
                params: {
                  versionId: this.version.id,
                  sectionId: this.question.section.id,
                },
              });
              this.$emit('refreshMiddle');
            })
            .catch((error) => {
              this.$emit('popup', {
                title: 'Kan vraag niet verwijderen',
                image: 'error',
                error: error.response.data.message,
                confirm: () => {
                  this.$emit('popup', null);
                },
              });
            });
        },
        cancel: () => this.$emit('popup', null),
      });
    },
    deleteOption(option) {
      this.question.options.splice(this.question.options.indexOf(option), 1);
      this.appendOptionRowIfNeeded();
    },
    changeOption() {
      this.appendOptionRowIfNeeded();
    },
    appendOptionRowIfNeeded() {
      const optionCount = this.question.options.length;

      // Add a new row if no options are there OR if the last option has text.
      if (optionCount === 0 || this.question.options[optionCount - 1].text.nl) {
        this.question.options.push(this.getNewOption('text'));
      }
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.field) {
  display: flex;
  flex-direction: row;
  padding: 6px 0;

  label {
    width: 120px;
    text-align: right;
    color: grey;
    padding: 6px 14px 0 0;
    font-size: 14px;
  }

  // Exclude the vue-select hidden input
  input:not(.vs__selected-options input),
  textarea,
  select {
    border-radius: 3px;
    border: 1px solid lightgrey;
    font-size: 14px;
    padding: 4px 6px;
    min-height: 24px;
    margin: 0 10px 0 0;
    outline-style: none;
  }

  .select-field {
    margin-left: 20px;

    input {
      border: none;
    }
  }

  textarea {
    width: calc(100% - 240px);
    height: 8em;
    line-height: 1.4;
  }

  textarea:disabled,
  input:disabled,
  select:disabled {
    background-color: $bg-color-disabled;
  }

  .options {
    .option {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 3em;

      .draghandle {
        color: black;
        margin: 8px 2px 0 8px;
        cursor: grab;
      }

      .option-field {
        input {
          width: 400px;
          margin: 0 4px 0 8px;
        }

        button {
          margin: 0;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;

  .select {
    border: none;
  }

  .scroller {
    overflow-y: auto;

    .question {
      padding-top: 20px;
      padding-bottom: 60px;
      font-family: $font-family-gitlab;
      display: flex;
      flex-direction: column;

      .divider {
        margin: 30px 0 20px 0;
        width: 100%;
        height: 1px;
        background-color: #e4e4e4;
      }
    }
  }
}
</style>
