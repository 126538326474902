<template>
  <div class="container">
    <div class="subheader" v-if="initialized">
      <div class="title">
        {{ sectionTitle }}
      </div>
      <div class="buttons">
        <button
          type="button"
          class="btn btn-invisible-grey"
          @click="deleteSection"
          v-if="exists"
        >
          Verwijderen
        </button>
        <button
          type="button"
          class="btn btn-dark"
          @click="saveSection"
          :disabled="!changed"
        >
          Opslaan
        </button>
      </div>
    </div>

    <div class="scroller" v-if="initialized">
      <div class="section" v-if="initialized">
        <template v-if="isTranslatedResource">
          <MultiLanguageInputField
            class="field"
            :enabled="enabled"
            inputType="input"
            label="Naam"
            :language="translationLanguage"
            :with-languages="translationLanguages"
            :model="section"
            type="text"
            :valueKey="nameField"
          />
        </template>
        <template v-else>
          <div class="field">
            <label>Naam</label>
            <input
              :value="section[nameField]"
              @input="setName($event.target.value)"
              :disabled="!enabled"
            />
          </div>
        </template>
        <template v-if="resource === 'questions'">
          <MultiLanguageInputField
            class="field"
            :enabled="enabled"
            inputType="input"
            label="URL"
            :language="language"
            :model="section"
            type="url"
            valueKey="url"
          />
          <div class="field">
            <label>In menu</label>
            <select v-model="section.in_menu" :disabled="!enabled">
              <option :value="true">Ja</option>
              <option :value="false">Nee</option>
            </select>
          </div>
          <div class="field">
            <label>Menu</label>
            <select v-model="section.menu_status" :disabled="!enabled">
              <option :value="'active'">Actief</option>
              <option :value="'disabled'">Uitgeschakeld</option>
              <option :value="'hidden'">Verborgen</option>
            </select>
          </div>
          <div class="field">
            <label>Voortgangsindicatie</label>
            <select v-model="section.show_progress" :disabled="!enabled">
              <option :value="true">Ja</option>
              <option :value="false">Nee</option>
            </select>
          </div>
          <div class="field">
            <label>Voortgang in %</label>
            <input
              type="number"
              min="0"
              max="100"
              v-model="section.progress_percentage"
              :disabled="!enabled"
            />
          </div>
          <div class="field">
            <label>Screening afronden</label>
            <select v-model="section.is_final" :disabled="!enabled">
              <option :value="true">Ja</option>
              <option :value="false">Nee</option>
            </select>
          </div>
          <div class="field">
            <label>Plaats knop</label>
            <select v-model="section.button_position" :disabled="!enabled">
              <option value="footer">Footer</option>
              <option value="page">In pagina</option>
            </select>
          </div>
          <MultiLanguageInputField
            class="field"
            :enabled="enabled"
            inputType="input"
            label="Text op knop"
            :language="language"
            :model="section"
            type="text"
            valueKey="button_text"
          />
          <div class="field">
            <label>Terugknop</label>
            <select v-model="section.allow_previous" :disabled="!enabled">
              <option :value="true">Ja</option>
              <option :value="false">Nee</option>
            </select>
          </div>
          <div class="field">
            <label>Achtergrond</label>
            <select v-model="section.background_color" :disabled="!enabled">
              <option value="light">Licht</option>
              <option value="dark">Donker</option>
            </select>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import api, { getQuestionsSection } from '@/api';
import _ from 'lodash';
import languages, {
  getValue,
  newLanguageObject,
  supportedLanguages,
} from '@/languages';
import MultiLanguageInputField from './MultiLanguageInputField';

export default {
  components: { MultiLanguageInputField },
  props: [
    'sectionId',
    'resource',
    'version',
    'language',
    'reportLanguage',
    'config',
  ],
  data() {
    return {
      section: null,
      originalSection: null,
    };
  },
  created() {
    this.load();
  },
  beforeRouteLeave(to, from, next) {
    this.guard(next);
  },
  beforeRouteUpdate(to, from, next) {
    this.guard(next);
  },
  computed: {
    isTranslatedResource() {
      return this.resource === 'questions';
    },
    translationLanguage() {
      return this.resource === 'questions' ? this.language : null;
    },
    translationLanguages() {
      return this.resource === 'questions' ? supportedLanguages : [];
    },
    isNew() {
      return !this.exists;
    },
    exists() {
      return this.sectionId > 0;
    },
    initialized() {
      return this.section;
    },
    changed() {
      return !_.isEqual(this.section, this.originalSection);
    },
    enabled() {
      return true;
    },
    nameField() {
      let nameFields = {
        questions: 'title',
        rules: 'label',
        exports: 'name',
        files: 'name',
      };
      return nameFields[this.resource];
    },
    newLabel() {
      return this.resource === 'exports' ? 'Nieuwe export' : 'Nieuwe sectie';
    },
    sectionsRouteName() {
      let routeNames = {
        rules: 'rulessections',
        questions: 'questionssections',
        exports: 'exports',
        files: 'filesections',
      };
      return routeNames[this.resource];
    },
    deleteTitle() {
      return this.resource === 'exports'
        ? 'Export verwijderen?'
        : 'Sectie verwijderen?';
    },
    sectionTitle() {
      if (!this.exists) {
        return this.newLabel;
      }

      if (this.isTranslatedResource) {
        return getValue(
          this.translationLanguages,
          this.translationLanguage,
          this.originalSection ? this.originalSection[this.nameField] ?? {} : {}
        );
      }

      return this.section[this.nameField];
    },
  },
  methods: {
    setName(name) {
      this.$set(this.section, this.nameField, name);
    },
    guard(next) {
      if (this.changed) {
        this.$emit('popup', {
          title: 'Weet je zeker dat je wilt weggaan?',
          content: 'De wijzigingen in de sectie zullen niet opgeslagen worden',
          image: 'question',
          confirm: () => {
            this.$emit('popup', null);
            next();
          },
          cancel: () => {
            this.$emit('popup', null);
            next(false);
          },
        });
      } else {
        next();
      }
    },
    load() {
      if (this.exists) {
        let calls = {
          questions: getQuestionsSection,
          rules: api.getRulesSection,
          exports: api.getExportTemplate,
          files: api.getFileSection,
        };

        let call;
        if (this.resource === 'exports' || this.resource === 'files') {
          call = calls[this.resource](this.sectionId);
        } else {
          call = calls[this.resource](this.version, this.sectionId);
        }

        call.then((response) => {
          this.section = response.data;
          this.originalSection = _.cloneDeep(this.section);
        });
      } else if (this.isNew) {
        const section = {};
        section[this.nameField] = this.isTranslatedResource
          ? newLanguageObject(this.translationLanguages, () => null)
          : '';

        if (this.resource === 'questions') {
          section.progress_percentage = 50;
          section.button_text = languages.newTranslationObject('next');
          section.title = languages.newTranslationObject(null);
          section.url = languages.newTranslationObject(null);
          section.in_menu = true;
          section.menu_status = 'active';
          section.show_progress = true;
          section.allow_previous = true;
          section.button_position = 'footer';
          section.background_color = 'light';
          section.is_final = false;
        }

        this.section = section;
        this.originalSection = _.cloneDeep(this.section);
      }
    },

    deleteSection() {
      this.$emit('popup', {
        title: this.deleteTitle,
        content: '',
        image: 'question',
        confirm: () => {
          this.$emit('popup', null);

          let calls = {
            questions: api.deleteQuestionsSection,
            rules: api.deleteRulesSection,
            exports: api.deleteExportTemplate,
            files: api.deleteFileSection,
          };

          let call;
          if (this.resource === 'exports' || this.resource === 'files') {
            call = calls[this.resource](this.section);
          } else {
            call = calls[this.resource](this.version, this.section);
          }

          call
            .then(() => {
              this.$router.push({
                name: this.sectionsRouteName,
                params: { versionId: this.version.id },
              });
              this.$emit('refreshLeft');
            })
            .catch((error) => {
              this.$emit('popup', {
                title: 'Kan sectie niet verwijderen',
                image: 'error',
                error: error.response.data.message,
                confirm: () => {
                  this.$emit('popup', null);
                },
              });
            });
        },
        cancel: () => this.$emit('popup', null),
      });
    },
    saveSection() {
      if (this.exists) {
        let calls = {
          questions: api.updateQuestionSection,
          rules: api.updateRuleSection,
          exports: api.updateExportTemplate,
          files: api.updateFileSection,
        };

        let call;
        if (this.resource === 'exports' || this.resource === 'files') {
          call = calls[this.resource](this.section);
        } else {
          call = calls[this.resource](this.version, this.section);
        }

        call.then((response) => {
          this.section = response.data;
          this.originalSection = _.cloneDeep(this.section);
          this.$emit('refreshLeft');
        });
      } else {
        let calls = {
          questions: api.insertQuestionSection,
          rules: api.insertRuleSection,
          exports: api.insertExportTemplate,
          files: api.insertFileSection,
        };

        let call;
        if (this.resource === 'exports' || this.resource === 'files') {
          call = calls[this.resource](this.section);
        } else {
          call = calls[this.resource](this.version, this.section);
        }

        call.then((response) => {
          this.section = response.data;
          this.originalSection = _.cloneDeep(this.section);
          this.$emit('refreshLeft');
          this.$router.push({
            name: this.sectionsRouteName,
            params: { versionId: this.version.id, sectionId: this.section.id },
          });
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
:deep(.field) {
  display: flex;
  flex-direction: row;
  padding: 6px 0;

  label {
    width: 160px;
    text-align: right;
    color: grey;
    padding: 6px 14px 0 0;
    font-size: 14px;
  }

  // Exclude the vue-select hidden input
  input:not(.vs__selected-options input),
  textarea,
  select {
    border-radius: 3px;
    border: 1px solid lightgrey;
    font-size: 14px;
    padding: 4px 6px;
    min-height: 24px;
    margin: 0 10px 0 0;
    outline-style: none;
  }

  select {
    height: 34px;
  }

  // Exclude the vue-select hidden input
  input:not(.vs__selected-options input) {
    width: calc(100% - 310px);
  }

  select {
    width: calc(100% - 295px);
  }

  textarea {
    width: calc(100% - 270px);
    height: 8em;
    line-height: 1.4;
  }

  button {
    margin: 15px 5px;
  }

  .old-style-text {
    padding: 0;
  }
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    height: 58px;
    display: flex;
    flex-direction: row;

    .title {
      font-family: $font-family-light;
      font-size: 18px;
      width: calc(100% - 250px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 18px 0 20px 24px;

      .link {
        color: black;
        text-decoration: none;
      }

      .link:hover {
        text-decoration: underline;
      }
    }

    .buttons {
      padding: 14px 24px;
      text-align: right;
      width: 250px;

      button {
        margin: 0 0 0 10px;
      }
    }
  }

  .scroller {
    height: calc(100% - 50px);
    overflow-y: auto;

    .section {
      padding-top: 20px;
      padding-bottom: 60px;
      font-family: $font-family-gitlab;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
