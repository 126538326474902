<template>
  <div class="container">
    <div class="subheader">
      <div class="title">Filters</div>
      <div class="buttons">
        <button
          type="button"
          class="btn btn-invisible-grey"
          @click="resetFilter()"
        >
          reset
        </button>
      </div>
    </div>
    <form @submit.prevent="updateFilter()">
      <div class="field">
        <label>ID</label>
        <input type="number" v-model="id" />
      </div>
      <div class="field">
        <label>Naam</label>
        <input type="text" v-model="name" />
      </div>

      <!-- Enter to submit only works with a submit button -->
      <input type="submit" hidden />
    </form>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';

const id = ref('');
const name = ref('');

const router = useRouter();
const route = useRoute();

watch(
  () => route.query,
  () => {
    id.value = route.query.id;
    name.value = route.query.name;
  },
  { immediate: true }
);

function updateFilter() {
  const currentId = route.params.tenantId;
  const query = {};
  if (id.value) {
    query.id = id.value;
  }

  if (name.value) {
    query.name = name.value;
  }

  router.push({
    name: 'tenants',
    params: currentId ? { tenantId: currentId } : {},
    query,
  });
}

function resetFilter() {
  id.value = '';
  name.value = '';
  updateFilter();
}
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';

.field {
  padding: 0.5rem;
}

label {
  color: grey;
  display: block;
  font-size: 14px;
  padding: 6px 14px 0 0;
  margin-bottom: 0.25rem;
}

input {
  width: calc(100% - 12px);
  @include input-box;
}
</style>
