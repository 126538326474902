import ar from '@/config/translations/ar';
import en from '@/config/translations/en';
import es from '@/config/translations/es';
import nl from '@/config/translations/nl';
import pl from '@/config/translations/pl';
import tr from '@/config/translations/tr';
import de from '@/config/translations/de';
import it from '@/config/translations/it';
import fr from '@/config/translations/fr';

export default {
  ar,
  en,
  es,
  nl,
  pl,
  tr,
  de,
  it,
  fr,
};
