<template>
  <div class="layout" v-if="initialized">
    <header-component
      class="header"
      :configs="configs"
      :version="version"
      :resource="props.resource"
      :language="language"
      :report-language="reportLanguage"
      @popup="setPopup"
      @reloadConfigs="loadConfigs"
      @newConfig="newConfig"
      @setLanguage="setLanguage"
      @setReportLanguage="setReportLanguage"
    />
    <div class="content">
      <side-bar :version="version" />
      <modal
        v-if="popup"
        :image="popup.image"
        :error="popup.error"
        :title="popup.title"
        :content="popup.content"
        :cancel="!!popup.cancel"
        :input="popup.input"
        :report_type="popup.report_type"
        @confirm="popup.confirm"
        @cancel="popup.cancel"
      />
      <slot
        :language="language"
        :reportLanguage="reportLanguage"
        :setPopup="setPopup"
        :resource="props.resource"
        :config="config"
        :version="version"
      ></slot>
    </div>
  </div>
</template>
<script setup>
// Root layout consisting of a header, sidebar and a slot for a popup.
import headerComponent from '@/components/Header.vue';
import sideBar from '@/components/SideBar.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { getConfigs, getVersion } from '@/api';
import { useRoute, useRouter } from 'vue-router/composables';
import modal from '@/components/Modal.vue';
import { loadSupportedLanguages } from '@/languages';

const props = defineProps({
  resource: String,
});

const router = useRouter();
const route = useRoute();
const versionId = computed(() => route.params.versionId);

// Language for patient facing resources (questions)
const language = ref('nl');

function setLanguage(l) {
  language.value = l;
}

// Language for health care provider facing resources (files, currently)
const reportLanguage = ref('nl');

function setReportLanguage(l) {
  reportLanguage.value = l;
}

const configs = ref(null);
const config = ref(null);
const version = ref(null);
const languagesLoaded = ref(false);
const initialized = computed(
  () => !!configs.value && !!version.value && languagesLoaded.value
);

const popup = ref(null);

function setPopup(p) {
  popup.value = p;
}

async function loadConfigs() {
  configs.value = (await getConfigs()).data;
}

async function loadVersion() {
  version.value = versionId.value
    ? (await getVersion(versionId.value)).data
    : configs.value[0].versions[0];

  config.value = configs.value.find(
    (config) => config.id === version.value.config_id
  );
}

async function newConfig(configId) {
  await loadConfigs();
  const version = configs.value.find((config) => config.id === configId)
    .versions[0];
  pushVersion(version.id, true);
}

function pushVersion(versionId, clearEntity = false) {
  const name = route.name;
  const params = {
    ...route.params,
    versionId,
  };
  params.versionId = versionId;

  if (clearEntity) {
    delete params.questionId;
    delete params.sectionId;
    delete params.ruleId;
    delete params.templateId;
  }

  router.push({ name, params });
}

onMounted(async function () {
  await loadSupportedLanguages();
  languagesLoaded.value = true;

  await loadConfigs();
  await loadVersion();
});

watch([versionId], () => loadVersion());
</script>

<style scoped lang="scss">
.layout {
  font-family: $font-family-gitlab;
  display: flex;
  flex-direction: column;
  min-width: $layout-min-width;
}

.content {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 54px);
}
</style>
