export default [
  {
    label: 'Bloedverdunner',
    value: null,
  },
  {
    label: 'Weet ik niet',
    value: 'unknown',
  },
  {
    label: 'Overig',
    value: 'other',
  },
];
