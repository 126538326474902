export default {
  promises: {},

  get(key) {
    return this.promises[key] ?? null;
  },
  unset(key) {
    delete this.promises[key];
  },
  /**
   * @param key: string
   * @param promiseFn: Closure function that returns a promise.
   * @returns {*}
   */
  set(key, promiseFn) {
    const promise = promiseFn();
    this.promises[key] = promise;

    return promise;
  },
  getOrSet(key, promiseFn) {
    if (!this.get(key)) {
      return this.set(key, promiseFn);
    } else {
      return this.get(key);
    }
  },
  waitFor(key, fn, timeout = 2000) {
    return new Promise((resolve, reject) => {
      let t = 0;

      const start = new Date().getTime();

      while (t < timeout) {
        const p = this.get(key);

        if (p) {
          resolve(p.then(fn));

          break;
        }

        let end = new Date().getTime();

        t = end - start;
      }

      return reject('timeout exceeded');
    });
  },
};
