// Functionality that helps us open new tabs retaining our session token. We call
// `consumeSessionToken()` in `main.js`, so this always runs before app startup.
// Calling `passSessionToken()` temporarily registers the session token in local
// storage, allowing the opened tab or window to pick it up. You can add this
// to @click.native for a router-link, for instance.
import { random } from 'lodash';

const storageKey = '_tokenPass';

function getPassed() {
  const data = localStorage.getItem(storageKey);
  const result = data ? JSON.parse(data) : null;

  if (data) {
    localStorage.removeItem(storageKey);
  }

  return result;
}

export function passSessionToken() {
  const id = random(0, 1e6, false).toString();
  const pass = {
    id,
    token: sessionStorage.getItem('token'),
  };
  localStorage.setItem(storageKey, JSON.stringify(pass));

  // The new tab has 500ms to consume the passed token before it is automatically cleared.
  setTimeout(getPassed, 500);
}

export function consumePassedToken() {
  const passed = getPassed();
  if (passed?.token) {
    sessionStorage.setItem('token', passed.token);
  }
}
