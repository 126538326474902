import { render, staticRenderFns } from "./UserProfile.vue?vue&type=template&id=7c19b546&scoped=true"
import script from "./UserProfile.vue?vue&type=script&setup=true&lang=js"
export * from "./UserProfile.vue?vue&type=script&setup=true&lang=js"
import style0 from "./UserProfile.vue?vue&type=style&index=0&id=7c19b546&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c19b546",
  null
  
)

export default component.exports