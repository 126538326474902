import translations from '@/config/translations/index';
import { truncate, zipObject } from 'lodash';
import promiseStore from '@/promiseStore';
import { getSupportedLanguages } from '@/api';

// These used to be locally defined (and used as globals), but are now returned by the API. Rewriting to
// be anything other than globals is quite a hassle, so we just fill them every time
// the RootLayout is loaded and keep using them as globals. No child components that
// use the globals will be loaded before the root layout finishes loading.

/**
 * List of languages supported for patient facing resources
 */
export const supportedLanguages = [];

/**
 * Set of supported languages for healthcare provider facing resources.
 * There may be non-overlapping elements compared to the patient languages.
 */
export const supportedReportLanguages = [];

/**
 * Display values for languages.
 */
export const languageLabels = {};

/**
 * Initializes the supportedLanguages variables (they start out empty).
 */
export async function loadSupportedLanguages() {
  const languages = (
    await promiseStore.getOrSet('supported-languages', () =>
      getSupportedLanguages()
    )
  ).data;
  supportedReportLanguages.splice(
    0,
    supportedReportLanguages.length,
    ...languages.report
  );
  supportedLanguages.splice(0, supportedLanguages.length, ...languages.patient);
  for (const key in languages.labels) {
    languageLabels[key] = languages.labels[key];
  }
}

// Return a language value for the given `language` for the `item`
// specifying a list of languages. if options.useFallback is true
// (the default), when missing, a fallback will be returned from
// the first language in `language` that supplies it. If `options.nowrap`
// is true (default false), fallbacks will *not* be wrapped in (LANG: {text})
// but returned verbatim.
export function getValue(languages, language, item, options = {}) {
  const nowrap = options?.nowrap ?? false;
  const useFallback = options?.useFallback ?? true;
  const truncateLength = options?.truncate ?? 0;

  const _truncate = (s) =>
    truncateLength ? truncate(s ?? '', { length: truncateLength }) : s;

  if (item[language]) {
    return _truncate(item[language]);
  }

  if (!useFallback) {
    return null;
  }

  for (const fallback of languages) {
    if (item[fallback]) {
      return nowrap ? fallback : wrapValue(fallback, _truncate(item[fallback]));
    }
  }

  return null;
}

// Helper to create a `getValue()` method with a pre-specified list of languages.
export function useGetValue(languages) {
  return (language, item, options = {}) =>
    getValue(languages, language, item, options);
}

// Creates a new language object for the given languages, where
// the initial value is the result of calling initializer() with
// each language.
// Example:
// newLanguageObject(['nl', 'en'], (lang) => lang); // {nl: 'nl', en: 'en'}
export function newLanguageObject(languages, initializer) {
  return zipObject(languages, languages.map(initializer));
}

// Creates a language object with all keys initialized to `null`
export function emptyLanguageObject(languages) {
  return zipObject(languages, Array(languages.length).fill(null));
}

export default {
  /**
   * Create a new translation object. The supplied translationKey is used to translate. If the key is not matched
   * it will just be used as a string for all translations.
   *
   * Example output:
   *
   * Non existing key.
   * newTranslationObject(null)
   * {
   *    en: null,
   *    nl: null,
   *    es: null,
   * }
   *
   * Existing key:
   * newTranslationObject('yes')
   * {
   *    en: 'Yes',
   *    nl: 'Ja',
   *    es: 'Si',
   * }
   *
   * @param translationKey
   * @returns {{}}
   */
  newTranslationObject(translationKey = null) {
    return newLanguageObject(supportedLanguages, (lang) =>
      translationKey
        ? translations[lang][translationKey] ?? translationKey
        : translationKey
    );
  },

  hasValue(language, item, key) {
    return !!this.getValue(language, item, key, false);
  },

  getValue(language, item, key, useFallback = true) {
    const values = this.getValues(item, key);
    const value = values[language];

    if (useFallback) {
      if (!value && language === 'nl') {
        return wrapValue('en', values.en);
      }

      if (!value && values.nl) {
        return wrapValue('nl', values.nl);
      }
    }

    return value || '';
  },

  getValues(item, key) {
    const result = {};

    supportedLanguages.forEach(function (language) {
      result[language] = item[key][language];
    });

    return result;
  },
};

export function wrapValue(language, value) {
  return `(${language.toUpperCase()}: ${value})`;
}
