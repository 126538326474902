<template>
  <div class="container">
    <div class="center" v-if="!isLoaded">
      <font-awesome-icon icon="spinner" size="2x" />
    </div>
    <div class="center" v-if="isErrored">
      <div>
        <div class="center">
          <font-awesome-icon
            class="flex"
            icon="exclamation-triangle"
            size="2x"
          />
        </div>
        <div>No data</div>
      </div>
    </div>

    <div class="subheader">
      <template v-if="initialized">
        <div class="title" v-if="fieldId">
          <router-link
            :to="{
              name: 'exportfields',
              params: { sectionId: sectionId, fieldId: exportField.id },
            }"
            class="link"
            >{{ originalExportField.name }}
          </router-link>
        </div>
        <div class="title" v-if="!fieldId">Nieuw veld</div>
        <div class="buttons" v-if="enabled">
          <button
            type="button"
            class="btn btn-invisible-grey"
            @click="deleteExportField"
            v-if="fieldId"
          >
            Verwijderen
          </button>
          <button
            type="button"
            class="btn btn-dark"
            @click="saveExportField"
            :disabled="!changed"
          >
            Opslaan
          </button>
        </div>
      </template>
    </div>

    <div class="scroller">
      <div class="files" v-if="initialized && exportField">
        <div class="field">
          <label>Kolomnaam</label>
          <input type="text" v-model="exportField.name" :disabled="!enabled" />
        </div>
        <div class="field">
          <label>Vraag</label>
          <multiselect
            placeholder=""
            track-by="id"
            label="text"
            :value="question"
            @input="setQuestion"
            :options="questions"
            :disabled="!!((rule && rule.id) || (miscField && miscField.value))"
            :close-on-select="true"
            :multiple="false"
            :hide-selected="false"
            :show-labels="false"
            :searchable="true"
            :allow-empty="true"
          />
        </div>
        <div class="field">
          <label>Regel</label>
          <multiselect
            placeholder=""
            track-by="id"
            label="label"
            :value="rule"
            @input="setRule"
            :options="rules"
            :disabled="
              !!((question && question.id) || (miscField && miscField.value))
            "
            :close-on-select="true"
            :multiple="false"
            :hide-selected="false"
            :show-labels="false"
            :searchable="true"
            :allow-empty="true"
          />
        </div>
        <div class="field">
          <label>Overig</label>
          <multiselect
            :allow-empty="true"
            :close-on-select="true"
            :disabled="!!((question && question.id) || (rule && rule.id))"
            :hide-selected="false"
            :multiple="false"
            :options="miscFields"
            :searchable="true"
            :show-labels="false"
            :value="miscField"
            @input="setMiscField"
            label="label"
            placeholder=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import _ from 'lodash';
import promiseStore from '@/promiseStore';

import multiselect from 'vue-multiselect';
import miscExportFields from '@/config/miscExportFields';

export default {
  components: { multiselect },
  props: ['sectionId', 'fieldId'],
  data() {
    return {
      rules: null,
      questions: null,
      exportField: null,
      originalExportField: null,
      miscFields: miscExportFields,
      flagInitialized: false,
      popup: null,
      isLoaded: false,
      isErrored: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    this.guard(next);
  },
  beforeRouteUpdate(to, from, next) {
    this.guard(next);
  },
  mounted() {
    this.load();
  },
  computed: {
    rule() {
      return this.rules.find((r) => r.id === this.exportField.rule_id);
    },
    question() {
      return this.questions.find((q) => q.id === this.exportField.question_id);
    },
    miscField() {
      return this.miscFields.find(
        (f) => f.value === this.exportField.misc_field
      );
    },
    initialized() {
      return this.isLoaded && this.flagInitialized && !this.isErrored;
    },
    changed() {
      return (
        !_.isEqual(this.exportField, this.originalExportField) &&
        (this.exportField.rule_id ||
          this.exportField.question_id ||
          this.exportField.misc_field)
      );
    },
    enabled() {
      return true;
    },
    exists() {
      return this.fieldId > 0;
    },
  },
  watch: {
    isLoaded() {
      if (this.isLoaded) {
        this.initialize();
        this.flagInitialized = true;
      } else {
        this.flagInitialized = false;
      }
    },
  },
  methods: {
    guard(next) {
      if (this.changed) {
        this.$emit('popup', {
          title: 'Weet je zeker dat je wilt weggaan?',
          content: 'De wijzigingen in het veld zullen niet opgeslagen worden',
          image: 'question',
          confirm: () => {
            this.$emit('popup', null);
            next();
          },
          cancel: () => {
            this.$emit('popup', null);
            next(false);
          },
        });
      } else {
        next();
      }
    },
    setQuestion(question) {
      this.exportField.question_id = question.id;
      this.exportField.name =
        question.text && question.text.length >= 40
          ? question.text.substr(0, 40) + '...'
          : question.text;
    },
    setRule(rule) {
      this.exportField.rule_id = rule.id;
      this.exportField.name = rule.label;
    },
    setMiscField(field) {
      this.exportField.misc_field = field.value;
      this.exportField.name = field.label;
    },
    load() {
      this.isLoaded = false;

      const allQuestionsPromise = promiseStore.waitFor(
        'all-questions',
        (questions) => {
          return [{ id: null, text: '' }].concat(
            questions.map((question) => {
              return {
                ...question,
                text:
                  '#Q' +
                  question.id +
                  ' ' +
                  (question.text ? question.text.substr(0, 120) : ''),
              };
            })
          );
        }
      );

      const rulesPromise = promiseStore.waitFor('all-rules', (rules) => {
        return [{ id: null, label: '' }].concat(
          rules.map((rule) => {
            return {
              ...rule,
              label:
                '#R' +
                rule.id +
                ' ' +
                (rule.label ? rule.label.substr(0, 120) : ''),
            };
          })
        );
      });

      const allPromises = [allQuestionsPromise, rulesPromise];

      Promise.all([allQuestionsPromise, rulesPromise])
        .then(([questions, rules]) => {
          this.questions = questions;
          this.rules = rules;
        })
        .catch(() => {
          this.isErrored = true;
        });

      if (this.fieldId > 0) {
        allPromises.push(
          api.getExportField(this.fieldId).then((response) => {
            this.exportField = response.data;
          })
        );
      } else if (this.fieldId === 0 || this.fieldId === '0') {
        this.exportField = {
          name: '',
          export_template_id: this.sectionId,
          rule_id: null,
          question_id: null,
          misc_field: null,
        };
      }

      Promise.all(allPromises).finally(() => (this.isLoaded = true));
    },
    initialize() {
      this.originalExportField = _.cloneDeep(this.exportField);
    },
    deleteExportField() {
      this.$emit('popup', {
        title: 'Veld verwijderen uit deze export?',
        content: '',
        image: 'question',
        confirm: () => {
          this.$emit('popup', null);
          api.deleteExportField(this.exportField.id).then(() => {
            this.$router.push({
              name: 'exportfields',
              params: { sectionId: this.exportField.export_template_id },
            });
            this.$emit('refreshMiddle');
          });
        },
        cancel: () => this.$emit('popup', null),
      });
    },
    pushExportField(exportField) {
      this.$router.push({
        name: 'exportfields',
        params: {
          sectionId: exportField.export_template_id,
          fieldId: exportField.id,
        },
      });
    },
    saveExportField() {
      if (this.exists) {
        api.updateExportField(this.exportField).then((response) => {
          this.exportField = response.data;
          this.originalExportField = _.cloneDeep(this.exportField);
          this.$emit('refreshMiddle');
          this.initialize();
        });
      } else {
        api.insertExportField(this.exportField).then((response) => {
          this.exportField = response.data;
          this.originalExportField = _.cloneDeep(this.exportField);
          this.$emit('refreshMiddle');
          this.pushExportField(this.exportField);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .scroller {
    overflow-y: auto;

    .files {
      padding-top: 20px;
      padding-bottom: 60px;
      font-family: $font-family-gitlab;
      display: flex;
      flex-direction: column;

      .field {
        display: flex;
        flex-direction: row;
        padding: 6px 0;

        label {
          width: 120px;
          text-align: right;
          color: grey;
          padding: 6px 14px 0 0;
          font-size: 14px;
        }

        textarea:disabled,
        input:disabled,
        select:disabled {
          background-color: $bg-color-disabled;
        }

        input,
        textarea,
        select {
          border-radius: 3px;
          border: 1px solid lightgrey;
          font-size: 14px;
          padding: 4px 6px;
          min-height: 24px;
          margin: 0 10px 0 0;
          outline-style: none;
        }

        input {
          width: 300px;
        }

        textarea {
          width: calc(100% - 240px);
          height: 8em;
          line-height: 1.4;
        }

        .multiselect {
          width: 900px;
        }

        button {
          margin: 15px 5px;
        }

        .lines {
          width: calc(100% - 200px);

          .ghost {
            opacity: 0.4;
          }

          .line-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .draghandle {
              padding: 0 10px 0 10px;
              cursor: grab;
            }

            .remove-icon {
              cursor: pointer;
              color: $color-main;
              padding: 0 10px 0 10px;
            }

            .line.enabled:hover,
            .line.new:hover {
              background-color: $bg-color-light-grey;
            }

            .line.new {
              margin-left: 36px;
              margin-right: 34px;
              height: 17px;
              align-items: center;
              justify-content: center;
              color: rgba(0, 0, 0, 0.2);
              border-style: dashed;

              .icon {
                transform: scale(0.77);
              }
            }

            .line.disabled {
              color: rgb(84, 84, 84);
              background-color: $bg-color-disabled;

              .rule {
                a {
                  color: rgb(84, 84, 84);
                }
              }
            }

            .line {
              cursor: pointer;
              font-size: 14px;
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 0.25rem;
              padding: 14px 30px 14px 20px;
              margin: 3px 0;
              width: 100%;

              display: flex;
              flex-direction: row;

              .rule {
                width: 35%;
                padding: 0 10px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .rulelink:hover {
                  text-decoration: underline;
                }

                .badge {
                  padding: 5px 7px 5px 7px;
                  font-size: 11px;
                  font-weight: 700;
                  line-height: 1;
                  text-align: center;
                  vertical-align: middle;
                  border-radius: 0.25rem;

                  margin: 0 2px;
                  color: white;
                  background-color: green;
                  cursor: pointer;
                }
              }

              .focus {
                margin: 0 10px;

                .varrule {
                  font-style: italic;
                }

                .varrule:hover {
                  text-decoration: underline;
                }
              }

              .text {
                text-align: right;
                margin: 0 15px;
              }
            }

            .line.full {
              .focus {
                width: 35%;
              }

              .text {
                width: 30%;
              }
            }

            .line.simple {
              .focus {
                width: 65%;
              }

              .text {
                width: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
