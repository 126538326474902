// Parses an admin service JWT. Correctly decodes the JWT using base64Url.
// https://stackoverflow.com/a/38552302/358873
// This returns the 'user' claim of the token only.
export function parseToken(token) {
  if (!token) {
    return null;
  }

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload)?.user;
}

// Returns the current user in session storage, or null if none is present.
export function getCurrentUser() {
  const token = sessionStorage.getItem('token');
  return parseToken(token);
}
