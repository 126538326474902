<template>
  <div class="container">
    <div class="subheader">
      <div class="title">Gebruiker</div>
      <div class="buttons"></div>
    </div>
    <form class="form" v-if="initialized">
      <div class="error" v-if="errorMessage">
        {{ errorMessage }}
      </div>

      <div class="field">
        <label for="user-id">ID:</label>
        <input
          type="text"
          :value="user.id"
          id="user-id"
          placeholder="ID"
          disabled
        />
      </div>
      <div class="field">
        <label for="user-name">Naam:</label>
        <input
          type="text"
          :value="user.name"
          id="user-name"
          placeholder="Naam"
          disabled
        />
      </div>
      <div class="field">
        <label for="user-email">E-mail:</label>
        <input
          type="text"
          :value="user.email"
          id="user-email"
          placeholder="Email"
          disabled
        />
      </div>
      <div class="field">
        <label for="user-mobile">Telefoonnummer:</label>
        <input
          type="text"
          :value="user.mobile"
          id="user-mobile"
          placeholder="Mobile"
          disabled
        />
      </div>
      <div class="field">
        <label for="user-role">Rol:</label>
        <input
          type="text"
          :value="user.role"
          id="user-role"
          placeholder="Rol"
          disabled
        />
      </div>
      <div class="field">
        <label>Authenticator:</label>
        <div class="input-shape">
          <template v-if="authenticatorEnabled">
            <span>Ingeschakeld</span>
            <a @click="startEnable()">Uitschakelen</a></template
          >
          <template v-else>
            <span>Uitgeschakeld</span>
            <a @click="startEnable()">Inschakelen</a></template
          >
          <a @click="cancelChanges()" v-if="makingChanges">Annuleren</a>
        </div>
      </div>
      <div class="field" v-if="makingChanges">
        <label for="user-password">Huidig wachtwoord:</label>
        <input
          type="password"
          v-model="password"
          id="user-password"
          placeholder="Wachtwoord"
        />
        <div class="input-shape">
          <a @click="confirmDisable()" v-if="authenticatorEnabled"
            >Bevestigen</a
          >
          <a @click="requestSecret()" v-else>QR-code opvragen</a>
        </div>
      </div>
      <div class="field" v-if="qrUrl">
        <label>QR Code:</label>
        <div id="qr-container">
          <canvas id="qr-canvas" ref="qrCanvas" />
        </div>
      </div>
      <div class="field" v-if="makingChanges && qrUrl">
        <label for="auth-code">Authenticator code:</label>
        <input id="auth-code" v-model="authCode" />
        <div class="input-shape">
          <a @click="confirmEnable()">Bevestigen</a>
        </div>
      </div>
    </form>

    <div class="subheader">
      <div class="title">Wachtwoord</div>
      <div class="buttons">
        <button
          type="submit"
          form="password-form"
          class="btn btn-dark"
          :disabled="!canChangePassword"
        >
          Wijzigen
        </button>
      </div>
    </div>

    <!-- Password change -->
    <form
      action=""
      class="form"
      id="password-form"
      v-if="initialized"
      @submit.prevent="doUpdatePassword()"
    >
      <div class="error" v-if="passwordErrorMessage">
        {{ passwordErrorMessage }}
      </div>
      <div class="field">
        <label for="user-password-current">Huidig wachtwoord:</label>
        <input
          type="password"
          id="user-password-current"
          placeholder="Wachtwoord"
          v-model="currentPassword"
        />
      </div>
      <div class="field">
        <label for="user-password-new">Nieuw wachtwoord</label>
        <input
          type="password"
          id="user-password-new"
          placeholder="Nieuw wachtwoord"
          v-model="newPassword"
        />
      </div>
      <div class="field">
        <label for="user-password-confirm">Bevestigen</label>
        <input
          type="password"
          id="user-password-confirm"
          placeholder="Nieuw wachtwoord bevestigen"
          v-model="confirmPassword"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue';
import {
  disableAuthenticator,
  enableAuthenticator,
  getProfile,
  requestOTPSecret,
  updatePassword,
} from '@/api';
import QRCode from 'qrcode';
import { getCurrentUser } from '@/helpers';

const errorMessage = ref(null);
const root = getCurrentInstance().proxy.$root;
const user = ref(null);
const initialized = computed(() => !!user.value);
const authenticatorEnabled = computed(() => user.value?.authenticator_enabled);

const password = ref('');
const makingChanges = ref(false);
const qrCanvas = ref(null);
const qrUrl = ref(null);
const authCode = ref('');

watch(
  qrUrl,
  async (url) => {
    const canvas = qrCanvas.value;

    if (!canvas || !url) {
      return;
    }

    QRCode.toCanvas(canvas, url, { width: 250 }, function (error) {
      console.log(`Could not write code to canvas: ${error}.`);
    });
  },
  // Run after next DOM update so the canvas is actually visible.
  { flush: 'post' }
);

function cancelChanges() {
  makingChanges.value = false;
  password.value = '';
  errorMessage.value = null;
  qrUrl.value = null;
  authCode.value = null;
}

function startEnable() {
  makingChanges.value = true;
}

async function requestSecret() {
  try {
    const response = await requestOTPSecret(password.value);
    qrUrl.value = response.data;
  } catch (error) {
    errorMessage.value = error.response?.data?.message ?? 'Onbekende fout.';
  }
}

function updateAuth(token) {
  sessionStorage.setItem('token', token);
  root.user = getCurrentUser();
}

async function confirmEnable() {
  try {
    const response = await enableAuthenticator(authCode.value);
    cancelChanges();
    updateAuth(response.data);
    await reloadUser();
  } catch (error) {
    errorMessage.value = error.response?.data?.message ?? 'Onbekende fout.';
  }
}

async function confirmDisable() {
  try {
    const response = await disableAuthenticator(password.value);
    cancelChanges();
    updateAuth(response.data);
    await reloadUser();
  } catch (error) {
    errorMessage.value = error.response?.data?.message ?? 'Onbekende fout.';
  }
}

async function reloadUser() {
  const { data } = await getProfile();
  user.value = data;
}

//#region Password change
const passwordErrorMessage = ref('');
const currentPassword = ref('');
const newPassword = ref('');
const confirmPassword = ref('');
const canChangePassword = computed(
  () =>
    newPassword.value &&
    currentPassword.value &&
    newPassword.value === confirmPassword.value
);

async function doUpdatePassword() {
  if (!canChangePassword.value) {
    return;
  }

  passwordErrorMessage.value = '';
  try {
    await updatePassword(currentPassword.value, newPassword.value);
    newPassword.value = '';
    currentPassword.value = '';
    confirmPassword.value = '';
  } catch (error) {
    passwordErrorMessage.value =
      error.response?.data?.message ?? 'Onbekende fout.';
  }
}

//#endregion

onMounted(() => {
  reloadUser();
});
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';

.container {
  width: 800px;
}

.field {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

label {
  @include input-label(200px);
}

.form {
  padding: 1rem;
}

input,
textarea,
select,
.input-shape {
  @include input-box;
  line-height: 24px;
  flex-grow: 1;
}

.input-shape {
  border-color: transparent;
}

.field {
  a {
    color: $color-main;
    cursor: pointer;
    text-decoration: underline;

    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
}

#qr-container {
  width: 250px;
  height: 250px;

  canvas {
    width: 100%;
    height: 100%;
  }
}

.error {
  color: $color-red;
  margin-bottom: 1rem;
}
</style>
