export default [
  { label: 'info', description: 'Tekst / Uitleg', short: 'uitleg' },
  { label: 'text', description: 'Open vraag - tekst', short: 'tekst' },
  { label: 'number', description: 'Open vraag - getal', short: 'getal' },
  { label: 'date', description: 'Open vraag - datum', short: 'datum' },
  { label: 'select', description: 'Keuzevraag', short: 'keuze' },
  {
    label: 'multiselect',
    description: 'Keuzevraag - meerdere antwoorden',
    short: 'keuzes',
  },
  { label: 'score', description: 'Score - horizontaal', short: 'score' },
];
