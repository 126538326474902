<template>
  <div class="sidebar">
    <div class="top">
      <template v-if="$root.user.permissions.includes('config_app')">
        <div class="item router-link-active">
          <font-awesome-icon
            :icon="['fal', 'flask']"
            class="item-icon"
          ></font-awesome-icon>
          <span class="item-label">Configuratie</span>
        </div>

        <div class="line"></div>
        <router-link
          class="item"
          :to="{ name: 'questions', params: routeParams }"
        >
          <font-awesome-icon
            :icon="['fal', 'question-circle']"
            class="item-icon sub-item"
          ></font-awesome-icon>
          <span class="item-label sub-item">Vragen</span>
        </router-link>

        <router-link class="item" :to="{ name: 'rules', params: routeParams }">
          <font-awesome-icon
            :icon="['fal', 'pencil-ruler']"
            class="item-icon sub-item"
          ></font-awesome-icon>
          <span class="item-label sub-item">Regels</span>
        </router-link>

        <router-link
          class="item"
          :to="{ name: 'templates', params: routeParams }"
        >
          <font-awesome-icon
            :icon="['fal', 'file-medical']"
            class="item-icon sub-item"
          ></font-awesome-icon>
          <span class="item-label sub-item">Document</span>
        </router-link>

        <router-link
          class="item"
          :to="{ name: 'changes', params: routeParams }"
        >
          <font-awesome-icon
            :icon="['fal', 'history']"
            class="item-icon sub-item"
          ></font-awesome-icon>
          <span class="item-label sub-item">Wijzigingen</span>
        </router-link>

        <router-link class="item" :to="{ name: 'files', params: routeParams }">
          <font-awesome-icon
            :icon="['fal', 'file-upload']"
            class="item-icon sub-item"
          ></font-awesome-icon>
          <span class="item-label sub-item">Bestanden</span>
        </router-link>

        <router-link
          class="item"
          :to="{ name: 'exportfields', params: routeParams }"
        >
          <font-awesome-icon
            :icon="['fal', 'download']"
            class="item-icon sub-item"
          ></font-awesome-icon>
          <span class="item-label sub-item">Exports</span>
        </router-link>

        <div class="line"></div>
      </template>

      <router-link
        v-if="$root.user.permissions.includes('tenant_app')"
        class="item"
        :to="{ name: 'tenants', params: routeParams }"
      >
        <font-awesome-icon
          :icon="['fal', 'hospitals']"
          class="item-icon sub-item"
        ></font-awesome-icon>
        <span class="item-label">Tenants</span>
      </router-link>

      <router-link
        class="item"
        :to="{ name: 'validator', params: routeParams }"
      >
        <font-awesome-icon
          :icon="['fal', 'clipboard-check']"
          class="item-icon sub-item"
        ></font-awesome-icon>
        <span class="item-label">Validator</span>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  version: Object,
});

const routeParams = computed(() =>
  props.version ? { versionId: props.version.id } : {}
);
</script>

<style scoped lang="scss">
.sidebar {
  color: $color-main;
  background: #f0f0f0;
  border-right: 1px solid #b3b3b3;
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;

  .item {
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    color: gray;
    text-decoration: none;

    &.router-link-active {
      color: $color-main;
    }

    .item-icon {
      width: 100%;
      font-size: 35px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 5px;

      &.sub-item {
        font-size: 25px;
      }
    }

    .item-label {
      font-size: 12px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      &.sub-item {
        font-size: 10px;
      }
    }
  }

  .line {
    margin: 0px 10px;
    border-bottom: 1px solid gray;
  }
}
</style>
