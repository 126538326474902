<template>
  <div class="container">
    <div class="subheader" v-if="initialized">
      <div class="title">
        {{ sectionTitle }}
      </div>
      <div class="buttons">
        <button
          type="button"
          class="btn btn-invisible-grey"
          @click="deleteSection"
          :disabled="!enabled"
          v-if="!isNew"
        >
          Verwijderen
        </button>
        <button
          type="button"
          class="btn btn-dark"
          @click="saveSection"
          :disabled="!hasChanges"
        >
          Opslaan
        </button>
      </div>
    </div>

    <div class="scroller" v-if="initialized">
      <div class="section">
        <MultiLanguageInputField
          :enabled="enabled"
          class="field"
          inputType="input"
          label="Naam"
          :language="reportLanguage"
          :with-languages="supportedReportLanguages"
          :model="section"
          type="text"
          valueKey="label"
        />
      </div>
      <div class="field">
        <label>Kolom in document</label>
        <select v-model="section.report_column" :disabled="!enabled">
          <option
            v-for="type in columnTypes"
            :key="type.value || 'no-column-type'"
            :value="type.value"
          >
            {{ type.name }}
          </option>
        </select>
      </div>
      <div class="field">
        <label>Icoon</label>
        <v-select
          v-model="section.icon"
          :disabled="!enabled"
          :options="icons"
          :clearable="true"
          :searchable="false"
          placeholder="selecteer icoon"
        >
          <template #selected-option="icon">
            <font-awesome-icon :icon="icon.label" size="2x" />
          </template>
          <template #option="icon">
            <font-awesome-icon :icon="icon.label" size="2x" />
          </template>
        </v-select>
      </div>

      <div class="field">
        <label>Bewerkbaar</label>
        <select v-model="section.editable" :disabled="!enabled">
          <option :value="1">Ja</option>
          <option :value="0">nee</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { cloneDeep, isEqual, isNil } from 'lodash';
import {
  getValue,
  newLanguageObject,
  supportedReportLanguages,
} from '@/languages';
import {
  deleteTemplatesSection,
  getReportColumnTypes,
  getTemplatesSection,
  insertTemplateSection,
  updateTemplateSection,
} from '@/api';
import {
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
  useRouter,
} from 'vue-router/composables';
import MultiLanguageInputField from '@/components/MultiLanguageInputField.vue';

const props = defineProps({
  sectionId: {
    type: [Number, String],
    required: false,
  },
  version: {
    type: Object,
    required: true,
  },
  reportLanguage: {
    type: String,
    required: true,
  },
});

const sectionIdNumber = computed(() =>
  isNil(props.sectionId)
    ? null
    : typeof props.sectionId === 'number'
    ? props.sectionId
    : Number.parseInt(props.sectionId)
);

const enabled = computed(() => props.version && !!props.version.open);

const router = useRouter();

const emit = defineEmits(['popup', 'refreshLeft']);

const columnTypes = ref(null);

// Currently loaded section (for editing)
const section = ref(null);

// Section copy for change comparision
const originalSection = ref(null);

const initialized = computed(
  () =>
    !!section.value &&
    !!columnTypes.value &&
    (!!sectionIdNumber.value || sectionIdNumber.value === 0)
);

const isNew = computed(() => !sectionIdNumber.value);

const hasChanges = computed(
  () => initialized.value && !isEqual(section.value, originalSection.value)
);

// Title to display in the header bar
const sectionTitle = computed(() =>
  isNew.value
    ? 'Nieuwe sectie'
    : getValue(
        supportedReportLanguages,
        props.reportLanguage,
        originalSection.value.label
      )
);

// Template section icons
const icons = [
  'user',
  'scalpel-path',
  'list-ul',
  'pills',
  'allergies',
  'smoking',
  'weight',
  'heartbeat',
  'lungs',
  'brain',
  'chart-network',
  'stomach',
  'kidneys',
  'tint',
  'vial',
  'stethoscope',
];

function factory() {
  return {
    id: null,
    label: newLanguageObject(supportedReportLanguages, () => null),
    report_column: null,
    icon: null,
    editable: 0,
  };
}

async function saveSection() {
  const exists = !!section.value.id;

  const response = exists
    ? await updateTemplateSection(props.version, section.value)
    : await insertTemplateSection(props.version, section.value);

  section.value = response.data;
  originalSection.value = cloneDeep(section.value);
  emit('refreshLeft');

  if (!exists) {
    router.push({
      name: 'templatessections',
      params: { versionId: props.version.id, sectionId: section.value.id },
    });
  }
}

function deleteSection() {
  emit('popup', {
    title: 'Sectie verwijderen?',
    content: '',
    image: 'question',
    confirm: async () => {
      emit('popup', null);
      await deleteTemplatesSection(props.version, section.value);
      router.push({
        name: 'templatessections',
        parmas: { versionId: props.version.id },
      });
      emit('refreshLeft');
    },
    cancel: () => emit('popup', null),
  });
}

async function load() {
  section.value = null;
  originalSection.value = null;

  if (isNew.value) {
    section.value = factory();
    originalSection.value = factory();
    return;
  }

  section.value = (
    await getTemplatesSection(props.version, props.sectionId)
  ).data;
  originalSection.value = cloneDeep(section.value);
}

function guard(to, from, next) {
  if (!hasChanges.value) {
    next();
    return;
  }

  emit('popup', {
    title: 'Weet je zeker dat je wilt weggaan?',
    content: 'De wijzigingen in de sectie zullen niet worden opgeslagen.',
    image: 'question',
    confirm: () => {
      emit('popup', null);
      next();
    },
    cancel: () => {
      emit('popup', null);
      next(false);
    },
  });
}

onBeforeRouteLeave(guard);
onBeforeRouteUpdate(guard);

watch(
  () => [sectionIdNumber.value, props.version],
  () => load(),
  { flush: 'post', immediate: true }
);

onMounted(async () => {
  columnTypes.value = (await getReportColumnTypes()).data;
});
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';

.section {
  padding-top: 20px;
}

:deep(.field) {
  display: flex;
  flex-direction: row;
  padding: 6px 0;

  label {
    @include input-label(160px);
  }

  // Exclude the vue-select hidden input
  input:not(.vs__selected-options input),
  textarea,
  select {
    @include input-box();
  }

  // Exclude the vue-select hidden input
  input:not(.vs__selected-options input) {
    width: calc(100% - 310px);
  }

  select {
    width: calc(100% - 295px);
  }
}

.scroller {
  height: calc(100% - 50px);
  overflow-y: auto;
}
</style>
