<template>
  <div class="container">
    <div class="subheader">
      <div class="title" v-if="section">{{ section.name }}</div>
      <div class="buttons">
        <button
          type="button"
          class="btn btn-invisible-grey"
          @click="revertOrder"
          v-if="changed"
          title="Volgorde herstellen"
        >
          herstellen
        </button>
        <button
          type="button"
          class="btn btn-dark"
          @click="saveOrder"
          v-if="changed"
        >
          Volgorde opslaan
        </button>
      </div>
    </div>

    <div class="scroller">
      <div
        class="exportfields cards"
        :class="{ dragging: dragging }"
        v-if="initialized"
      >
        <draggable
          v-model="files"
          @start="startDrag"
          @end="endDrag"
          :forceFallback="true"
          animation="300"
          ghostClass="ghost"
          delay="50"
          touchStartThreshold="30"
        >
          <transition-group
            type="transition"
            :name="dragging ? '' : 'draglist'"
          >
            <div
              class="file card"
              v-for="file in files"
              :key="file.id"
              :class="{ active: isActive(file) }"
              @click="open(file)"
            >
              <div class="col left">
                <div
                  class="name"
                  :class="hasTranslation(file, 'name') ? '' : 'fallback'"
                >
                  {{ languageValue(file, 'name') }}
                </div>
                <div
                  class="fileref"
                  :class="hasTranslation(file, 'description') ? '' : 'fallback'"
                >
                  {{ languageValue(file, 'description') }}
                </div>
              </div>
              <div class="col right">
                <!--<div class="changed" :title="'Laatst gewijzigd: '+formatDateTime(exportField.updated_at)">{{fromNow(exportField.updated_at)}}</div>-->
                <!--<div class="operator">-->
                <!--<div class="badge badge-count" >{{exportField.lines.length}}</div>-->
                <!--</div>-->
              </div>
            </div>
          </transition-group>
        </draggable>
        <router-link
          :to="{ name: 'files', params: { sectionId: sectionId, fileId: 0 } }"
          class="file card new"
          :class="{ active: fileId <= 0 }"
          title="Nieuw bestand toevoegen"
        >
          <font-awesome-icon class="icon" icon="plus-circle" size="2x" />
        </router-link>
      </div>
      <div class="center" v-if="!initialized && sectionId">
        <font-awesome-icon icon="spinner" size="2x" />
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import api from '@/api';
import moment from 'moment';
import { isEqual, cloneDeep } from 'lodash';
import { supportedReportLanguages, wrapValue } from '@/languages';

export default {
  props: ['sectionId', 'fileId', 'reportLanguage'],
  data() {
    return {
      questions: null,
      rules: null,
      files: null,
      originalFiles: null,
      section: null,
      dragging: false,
    };
  },
  created() {
    this.load();
  },
  beforeRouteLeave(to, from, next) {
    this.guard(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    this.guard(to, from, next);
  },
  computed: {
    initialized() {
      return this.files !== null;
    },
    changed() {
      return !isEqual(this.files, this.originalFiles);
    },
  },
  methods: {
    guard(to, from, next) {
      if (
        parseInt(from.params.sectionId) !== parseInt(to.params.sectionId) &&
        this.changed
      ) {
        this.$emit('popup', {
          title: 'Weet je zeker dat je wilt weggaan?',
          content:
            'De wijzigingen in de volgorde zullen niet opgeslagen worden',
          image: 'question',
          confirm: () => {
            this.$emit('popup', null);
            next();
          },
          cancel: () => {
            this.$emit('popup', null);
            next(false);
          },
        });
      } else {
        next();
      }
    },
    async load() {
      if (!this.sectionId) {
        return;
      }

      const response = await api.getFileSection(this.sectionId);
      this.section = response.data;
      this.files = this.section.files;
      this.originalFiles = cloneDeep(this.files);
    },
    formatDateTime(value) {
      return moment(value).format('DD-MM-YYYY hh:mm:ss');
    },
    fromNow(datetime) {
      return moment(datetime).fromNow();
    },

    isActive(exportField) {
      return exportField.id === parseInt(this.fileId);
    },
    saveOrder() {
      api
        .saveFileOrder(this.section, this.files)
        .then(() => (this.originalFiles = cloneDeep(this.files)));
    },
    startDrag() {
      this.dragging = true;
    },
    endDrag() {
      this.dragging = false;
    },
    revertOrder() {
      this.files = cloneDeep(this.originalFiles);
    },
    open(file) {
      this.$router.push({
        name: 'files',
        params: { sectionId: file.file_section_id.toString(), fileId: file.id },
      });
    },
    hasTranslation(file, key) {
      const l = this.reportLanguage;
      return !!(file.translations[l] && file.translations[l][key]);
    },

    languageValue(file, key) {
      const cur = this.reportLanguage;

      // Check for active translation
      if (file.translations[cur] && file.translations[cur][key]) {
        return file.translations[cur][key];
      }

      // Check for fallback translation in order of appearance in language list
      for (const lang of supportedReportLanguages) {
        if (file.translations[lang] && file.translations[lang][key]) {
          return wrapValue(lang, file.translations[lang][key]);
        }
      }

      return '';
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  font-family: $font-family-gitlab;
  display: flex;
  flex-direction: column;
}

.scroller {
  overflow-y: auto;
}

.exportfields {
  display: flex;
  flex-direction: column;
}

.draglist-move {
  transition: transform 0.7s;
}

.file.active {
  outline-style: none;
}

.file.ghost {
  background-color: $bg-color-light-grey;
  /*opacity: 0.7;*/
}

.file.new {
  color: rgba(0, 0, 0, 0.125);
  border: 1px dashed rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
}

.file {
  background-color: white;
  font-weight: 400;
  font-size: 14px;
  height: 52px;
  padding: 5px 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.col.left {
  .name {
    font-weight: 500;
    padding-bottom: 5px;
  }

  width: calc(100% - 10px);

  .fileref {
    font-size: 11px;
  }
}

.col.right {
  width: 10px;
}

.col {
  justify-content: center;
  height: 100%;
  display: flex;
  flex-direction: column;

  .name {
    width: 100%;
    color: black;
    font-size: 14px;
  }

  .changed {
    font-size: 12px;
    color: grey;
    width: 100%;
    text-align: right;
  }

  .fallback {
    color: grey;
  }

  .operator {
    .badge {
      display: inline-block;
      padding: 3px 4px 3px 4px;
      font-size: 9px;
      font-weight: 700;
      line-height: 1;
      width: 14px;
      text-align: center;
      vertical-align: middle;
      white-space: nowrap;
      border-radius: 0.25rem;

      margin: 0 2px;
      color: white;
    }

    .badge-operator {
      background-color: green;
    }

    .badge-count {
      background-color: #919191;
    }

    padding-top: 15px;
    text-align: right;
    color: grey;
    font-size: 12px;
  }
}
</style>
