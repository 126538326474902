var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},_vm._l((['questions', 'rules', 'templates']),function(resource){return _c('div',{key:resource,staticClass:"pane",class:resource},[(_vm.initialized)?_c('div',{staticClass:"subheader"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(resource === 'questions' ? 'Vragen' : resource === 'rules' ? 'Regels' : 'Document')+" ")])]):_vm._e(),(_vm.initialized)?_c('div',{staticClass:"scroller"},[_c('div',{staticClass:"content"},[(resource === 'questions')?[(
              _vm.changes.questionsections.length > 0 ||
              _vm.changes.questions.length > 0
            )?[(_vm.changes.questionsections.length > 0)?_c('div',{staticClass:"list"},_vm._l((_vm.changes.questionsections),function(section,index){return _c('div',{key:section.id,staticClass:"change"},[_c('router-link',{attrs:{"to":{
                    name: 'questionssections',
                    params: { versionId: _vm.version.id, sectionId: section.id },
                  }}},[_vm._v("Sectie: "+_vm._s(_vm.languageValue(section.title))+" ")]),(_vm.version.open)?_c('button',{staticClass:"btn btn-invisible",attrs:{"title":"Wijzigingen ongedaan maken"},on:{"click":function($event){return _vm.undoQuestionSection(section, index)}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"undo"}})],1):_vm._e()],1)}),0):_vm._e(),(_vm.changes.questions.length > 0)?_c('div',{staticClass:"list"},_vm._l((_vm.changes.questions),function(question,index){return _c('div',{key:question.id,staticClass:"change"},[_c('router-link',{attrs:{"to":{
                    name: 'questions',
                    params: {
                      versionId: _vm.version.id,
                      sectionId: question.section
                        ? question.section.id
                        : null,
                      questionId: question.id,
                    },
                  }}},[_vm._v("#V"+_vm._s(question.id)+" - "+_vm._s(_vm.languageValue(question.text, { truncate: 100 }))+" ")]),(_vm.version.open)?_c('button',{staticClass:"btn btn-invisible",attrs:{"title":"Wijzigingen ongedaan maken"},on:{"click":function($event){return _vm.undoQuestion(question, index)}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"undo"}})],1):_vm._e()],1)}),0):_vm._e()]:_c('div',{staticClass:"center"},[_vm._v(" De vragen zijn niet gewijzigd in versie "+_vm._s(_vm.version.version)+" ")])]:_vm._e(),(resource === 'rules')?[(_vm.changes.rulesections.length > 0 || _vm.changes.rules.length > 0)?[(_vm.changes.rulesections.length > 0)?_c('div',{staticClass:"list"},_vm._l((_vm.changes.rulesections),function(section,index){return _c('div',{key:section.id,staticClass:"change"},[_c('router-link',{attrs:{"to":{
                    name: 'rulessections',
                    params: { versionId: _vm.version.id, sectionId: section.id },
                  }}},[_vm._v("Sectie: "+_vm._s(section.label)+" ")]),(_vm.version.open)?_c('button',{staticClass:"btn btn-invisible",attrs:{"title":"Wijzigingen ongedaan maken"},on:{"click":function($event){return _vm.undoRuleSection(section, index)}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"undo"}})],1):_vm._e()],1)}),0):_vm._e(),(_vm.changes.rules.length > 0)?_c('div',{staticClass:"list"},_vm._l((_vm.changes.rules),function(rule,index){return _c('div',{key:rule.id,staticClass:"change"},[_c('router-link',{attrs:{"to":{
                    name: 'rules',
                    params: {
                      versionId: _vm.version.id,
                      sectionId: rule.section ? rule.section.id : null,
                      ruleId: rule.id,
                    },
                  }}},[_vm._v("#R"+_vm._s(rule.id)+" - "+_vm._s(rule.label)+" ")]),(_vm.version.open)?_c('button',{staticClass:"btn btn-invisible",attrs:{"title":"Wijzigingen ongedaan maken"},on:{"click":function($event){return _vm.undoRule(rule, index)}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"undo"}})],1):_vm._e()],1)}),0):_vm._e()]:_c('div',{staticClass:"center"},[_vm._v(" De regels zijn niet gewijzigd in versie "+_vm._s(_vm.version.version)+" ")])]:_vm._e(),(resource === 'templates')?[(
              _vm.changes.templatesections.length > 0 ||
              _vm.changes.templates.length > 0
            )?[(_vm.changes.templatesections.length > 0)?_c('div',{staticClass:"list"},_vm._l((_vm.changes.templatesections),function(section,index){return _c('div',{key:section.id,staticClass:"change"},[_c('router-link',{attrs:{"to":{
                    name: 'templatessections',
                    params: { versionId: _vm.version.id, sectionId: section.id },
                  }}},[_vm._v("Sectie: "+_vm._s(_vm.languageValue(section.label))+" ")]),(_vm.version.open)?_c('button',{staticClass:"btn btn-invisible",attrs:{"title":"Wijzigingen ongedaan maken"},on:{"click":function($event){return _vm.undoTemplateSection(section, index)}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"undo"}})],1):_vm._e()],1)}),0):_vm._e(),(_vm.changes.templates.length > 0)?_c('div',{staticClass:"list"},_vm._l((_vm.changes.templates),function(template,index){return _c('div',{key:template.id,staticClass:"change"},[_c('router-link',{attrs:{"to":{
                    name: 'templates',
                    params: {
                      versionId: _vm.version.id,
                      sectionId: template.section
                        ? template.section.id
                        : null,
                      templateId: template.id,
                    },
                  }}},[_vm._v("#D"+_vm._s(template.id)+" - "+_vm._s(_vm.languageValue(template.label))+" ")]),(_vm.version.open)?_c('button',{staticClass:"btn btn-invisible",attrs:{"title":"Wijzigingen ongedaan maken"},on:{"click":function($event){return _vm.undoTemplate(template, index)}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"undo"}})],1):_vm._e()],1)}),0):_vm._e()]:_c('div',{staticClass:"center"},[_vm._v(" Het document is niet gewijzigd in versie "+_vm._s(_vm.version.version)+" ")])]:_vm._e()],2)]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }