<template>
  <div class="container">
    <div class="subheader">
      <div class="title" v-if="section">{{ section.label }}</div>
      <div class="buttons">
        <button
          type="button"
          class="btn btn-invisible-grey"
          @click="revertOrder"
          v-if="changed"
          title="Volgorde herstellen"
        >
          herstellen
        </button>
        <button
          type="button"
          class="btn btn-dark"
          @click="saveOrder"
          v-if="changed"
        >
          Volgorde opslaan
        </button>
      </div>
    </div>
    <div class="scroller">
      <div
        class="rules cards"
        :class="{ dragging: dragging }"
        v-if="initialized"
      >
        <draggable
          v-model="rules"
          @start="startDrag"
          @end="endDrag"
          :forceFallback="true"
          animation="300"
          ghostClass="ghost"
          delay="50"
          touchStartThreshold="30"
          :disabled="!version.open"
        >
          <transition-group
            type="transition"
            :name="dragging ? '' : 'draglist'"
          >
            <div
              class="rule card"
              v-for="rule in rules"
              :key="rule.id"
              :class="{ active: isActive(rule) }"
              @click="open(rule)"
            >
              <div class="col left">
                <div class="name">
                  <span class="identifier">#R{{ rule.id }}</span> -
                  {{ rule.label }}
                </div>
              </div>
              <div class="col right">
                <div
                  class="changed"
                  :title="
                    'Laatst gewijzigd: ' + formatDateTime(rule.updated_at)
                  "
                >
                  {{ fromNow(rule.updated_at) }}
                </div>
                <div class="operator">
                  <div class="badge badge-operator">
                    {{ rule.operator.label }}
                  </div>
                  <div class="badge badge-count">{{ rule.lines.length }}</div>
                </div>
              </div>
            </div>
          </transition-group>
        </draggable>
        <router-link
          :to="{
            name: 'rules',
            params: { versionId: version.id, sectionId: sectionId, ruleId: 0 },
          }"
          class="rule card new"
          :class="{ active: ruleId <= 0 }"
          title="Nieuwe regel toevoegen"
          v-if="version.open"
        >
          <font-awesome-icon class="icon" icon="plus-circle" size="2x" />
        </router-link>
      </div>
      <div class="center" v-if="!initialized && sectionId">
        <font-awesome-icon icon="spinner" size="2x" />
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import api from '@/api';
import moment from 'moment';
import _ from 'lodash';

export default {
  props: ['sectionId', 'ruleId', 'version'],
  data() {
    return {
      rules: null,
      originalRules: null,
      section: null,
      dragging: false,
    };
  },
  created() {
    this.load();
  },
  computed: {
    initialized() {
      return this.rules !== null;
    },
    changed() {
      return !_.isEqual(this.rules, this.originalRules);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.guard(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    this.guard(to, from, next);
  },
  methods: {
    guard(to, from, next) {
      if (
        parseInt(from.params.sectionId) !== parseInt(to.params.sectionId) &&
        this.changed
      ) {
        this.$emit('popup', {
          title: 'Weet je zeker dat je wilt weggaan?',
          content:
            'De wijzigingen in de volgorde zullen niet opgeslagen worden',
          image: 'question',
          confirm: () => {
            this.$emit('popup', null);
            next();
          },
          cancel: () => {
            this.$emit('popup', null);
            next(false);
          },
        });
      } else {
        next();
      }
    },
    load() {
      if (this.sectionId) {
        api.getRulesSection(this.version, this.sectionId).then((response) => {
          this.section = response.data;
          this.rules = this.section.rules;
          this.originalRules = _.cloneDeep(this.rules);
        });
      }
    },
    formatDateTime(value) {
      return moment(value).format('DD-MM-YYYY hh:mm:ss');
    },
    fromNow(datetime) {
      return moment(datetime).fromNow();
    },

    isActive(rule) {
      return rule.id === parseInt(this.ruleId);
    },
    saveOrder() {
      api
        .saveRulesOrder(this.version, this.section, this.rules)
        .then(() => (this.originalRules = _.cloneDeep(this.rules)));
    },
    startDrag() {
      this.dragging = true;
    },
    endDrag() {
      this.dragging = false;
    },
    revertOrder() {
      this.rules = _.cloneDeep(this.originalRules);
    },
    open(rule) {
      this.$router.push({
        name: 'rules',
        params: {
          versionId: this.version.id,
          sectionId: rule.section.id,
          ruleId: rule.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.rules:not(.dragging) {
  .rule:hover:not(.active) {
    background-color: $bg-color-light-grey;
  }
}

.container {
  font-family: $font-family-gitlab;
  display: flex;
  flex-direction: column;

  .scroller {
    overflow-y: auto;

    .rules {
      display: flex;
      flex-direction: column;

      .draglist-move {
        transition: transform 0.7s;
      }

      .rule.active {
        outline-style: none;
        background-color: $bg-color-light-blue;
      }

      .rule.ghost {
        background-color: $bg-color-light-grey;
        /*opacity: 0.7;*/
      }

      .rule.new {
        color: rgba(0, 0, 0, 0.125);
        border: 1px dashed rgba(0, 0, 0, 0.1);
        justify-content: center;
        align-items: center;
        margin-top: 0;
      }

      .rule {
        background-color: white;
        font-weight: 400;
        font-size: 14px;
        height: 52px;
        padding: 5px 15px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 0.25rem;
        display: flex;
        flex-direction: row;
        text-decoration: none;
        color: black;
        cursor: pointer;

        .col.left {
          width: calc(100% - 150px);
        }

        .col.right {
          width: 150px;
        }

        .col {
          justify-content: center;
          height: 100%;
          display: flex;
          flex-direction: column;

          .name {
            width: 100%;
            color: black;
            font-size: 14px;
          }

          .changed {
            font-size: 12px;
            color: grey;
            width: 100%;
            text-align: right;
          }

          .operator {
            .badge {
              display: inline-block;
              padding: 3px 4px 3px 4px;
              font-size: 9px;
              font-weight: 700;
              line-height: 1;
              width: 14px;
              text-align: center;
              vertical-align: middle;
              white-space: nowrap;
              border-radius: 0.25rem;

              margin: 0 2px;
              color: white;
            }

            .badge-operator {
              background-color: green;
            }

            .badge-count {
              background-color: #919191;
            }

            padding-top: 15px;
            text-align: right;
            color: grey;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
