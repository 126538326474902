<template>
  <div class="container">
    <div class="subheader">
      <div class="title" v-if="section">{{ section.name }}</div>
      <div class="buttons">
        <button
          type="button"
          class="btn btn-invisible-grey"
          @click="revertOrder"
          v-if="changed"
          title="Volgorde herstellen"
        >
          herstellen
        </button>
        <button
          type="button"
          class="btn btn-dark"
          @click="saveOrder"
          v-if="changed"
        >
          Volgorde opslaan
        </button>
      </div>
    </div>
    <div class="scroller">
      <div
        class="exportfields cards"
        :class="{ dragging: dragging }"
        v-if="isLoaded"
      >
        <draggable
          v-model="exportFields"
          @start="startDrag"
          @end="endDrag"
          :forceFallback="true"
          animation="300"
          ghostClass="ghost"
          delay="50"
          touchStartThreshold="30"
          :disabled="!version.open"
        >
          <transition-group
            type="transition"
            :name="dragging ? '' : 'draglist'"
          >
            <div
              class="files card"
              v-for="exportField in exportFields"
              :key="exportField.id"
              :class="{ active: isActive(exportField) }"
              @click="open(exportField)"
            >
              <div class="col left">
                <div class="name">{{ exportField.name }}</div>
                <div class="ref">{{ referenceLabel(exportField) }}</div>
              </div>
              <div class="col right">
                <!--<div class="changed" :title="'Laatst gewijzigd: '+formatDateTime(exportField.updated_at)">{{fromNow(exportField.updated_at)}}</div>-->
                <!--<div class="operator">-->
                <!--<div class="badge badge-count" >{{exportField.lines.length}}</div>-->
                <!--</div>-->
              </div>
            </div>
          </transition-group>
        </draggable>
        <router-link
          v-if="questions && rules"
          :to="{
            name: 'exportfields',
            params: {
              sectionId: sectionId,
              fieldId: 0,
            },
          }"
          class="files card new"
          :class="{ active: fieldId <= 0 }"
          title="Nieuw veld toevoegen"
        >
          <font-awesome-icon class="icon" icon="plus-circle" size="2x" />
        </router-link>
      </div>
      <div class="center" v-if="!isLoaded && sectionId">
        <font-awesome-icon icon="spinner" size="2x" />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import moment from 'moment';
import _ from 'lodash';
import miscExportFields from '@/config/miscExportFields';
import promiseStore from '@/promiseStore';

export default {
  props: ['sectionId', 'fieldId', 'version'],
  data() {
    return {
      questions: null,
      rules: null,
      exportFields: null,
      originalExportFields: null,
      section: null,
      dragging: false,
      miscFields: miscExportFields,
      isLoaded: false,
    };
  },
  created() {
    this.load();
  },
  beforeRouteLeave(to, from, next) {
    if (this.guard(to, from, next)) {
      // make sure we get fresh data when we come back to this route, so we delete the cached promises.
      promiseStore.unset('all-questions');
      promiseStore.unset('all-rules');
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.guard(to, from, next);
  },
  computed: {
    changed() {
      return !_.isEqual(this.exportFields, this.originalExportFields);
    },
  },
  methods: {
    referenceLabel(field) {
      if (field.question_id) {
        let question = this.questions.find((q) => q.id === field.question_id);
        if (question) {
          return (
            '#Q' +
            question.id +
            ' - ' +
            question.text.substr(0, 70) +
            (question.text.length > 50 ? '...' : '')
          );
        }
      } else if (field.rule_id) {
        let rule = this.rules.find((r) => r.id === field.rule_id);
        if (rule) {
          return (
            '#R' +
            rule.id +
            ' - ' +
            rule.label.substr(0, 70) +
            (rule.label.length > 50 ? '...' : '')
          );
        }
      } else if (field.misc_field) {
        const misc = this.miscFields.find((f) => f.value === field.misc_field);

        if (misc) {
          return `Overig: ${misc.value} => "${misc.label}"`;
        } else {
          return `(Unmatched) Overig ${field.misc_field}`;
        }
      }

      return '(geen waarde)';
    },
    guard(to, from, next) {
      if (
        parseInt(from.params.sectionId) !== parseInt(to.params.sectionId) &&
        this.changed
      ) {
        this.$emit('popup', {
          title: 'Weet je zeker dat je wilt weggaan?',
          content:
            'De wijzigingen in de volgorde zullen niet opgeslagen worden',
          image: 'question',
          confirm: () => {
            this.$emit('popup', null);
            next();

            return true;
          },
          cancel: () => {
            this.$emit('popup', null);
            next(false);

            return false;
          },
        });
      } else {
        next();

        return true;
      }
    },
    load() {
      const allQuestions = promiseStore.getOrSet('all-questions', () =>
        api.getAllQuestions().then((response) => response.data)
      );

      const allRules = promiseStore.getOrSet('all-rules', () =>
        api.getAllRules().then((response) => response.data)
      );

      Promise.all([allQuestions, allRules]).then(([questions, rules]) => {
        this.questions = questions;
        this.rules = rules;
      });

      const allPromises = [allQuestions, allRules];

      if (this.sectionId) {
        allPromises.push(
          api.getExportTemplate(this.sectionId).then((response) => {
            this.section = response.data;
            this.exportFields = this.section.fields;
            this.originalExportFields = _.cloneDeep(this.exportFields);
          })
        );
      }

      Promise.all(allPromises).then(() => (this.isLoaded = true));
    },
    formatDateTime(value) {
      return moment(value).format('DD-MM-YYYY hh:mm:ss');
    },
    fromNow(datetime) {
      return moment(datetime).fromNow();
    },

    isActive(exportField) {
      return exportField.id === parseInt(this.fieldId);
    },
    saveOrder() {
      api
        .saveExportFieldsOrder(this.section, this.exportFields)
        .then(
          () => (this.originalExportFields = _.cloneDeep(this.exportFields))
        );
    },
    startDrag() {
      this.dragging = true;
    },
    endDrag() {
      this.dragging = false;
    },
    revertOrder() {
      this.exportFields = _.cloneDeep(this.originalExportFields);
    },
    open(exportField) {
      this.$router.push({
        name: 'exportfields',
        params: {
          sectionId: exportField.export_template_id,
          fieldId: exportField.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  font-family: $font-family-gitlab;
  display: flex;
  flex-direction: column;

  .scroller {
    overflow-y: auto;

    .exportfields {
      display: flex;
      flex-direction: column;

      .draglist-move {
        transition: transform 0.7s;
      }

      .files.active {
        outline-style: none;
      }

      .files.ghost {
        background-color: $bg-color-light-grey;
        /*opacity: 0.7;*/
      }

      .files.new {
        color: rgba(0, 0, 0, 0.125);
        border: 1px dashed rgba(0, 0, 0, 0.1);
        justify-content: center;
        align-items: center;
      }

      .files {
        background-color: white;
        font-weight: 400;
        font-size: 14px;
        height: 52px;
        padding: 5px 15px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 0.25rem;
        display: flex;
        flex-direction: row;
        text-decoration: none;
        color: black;
        cursor: pointer;

        .col.left {
          .name {
            font-weight: 500;
            padding-bottom: 5px;
          }
          width: calc(100% - 10px);
        }
        .col.right {
          width: 10px;
        }

        .col {
          justify-content: center;
          height: 100%;
          display: flex;
          flex-direction: column;

          .name {
            width: 100%;
            color: black;
            font-size: 14px;
          }
          .changed {
            font-size: 12px;
            color: grey;
            width: 100%;
            text-align: right;
          }
          .operator {
            .badge {
              display: inline-block;
              padding: 3px 4px 3px 4px;
              font-size: 9px;
              font-weight: 700;
              line-height: 1;
              width: 14px;
              text-align: center;
              vertical-align: middle;
              white-space: nowrap;
              border-radius: 0.25rem;

              margin: 0 2px;
              color: white;
            }

            .badge-operator {
              background-color: green;
            }

            .badge-count {
              background-color: #919191;
            }

            padding-top: 15px;
            text-align: right;
            color: grey;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
