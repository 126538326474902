import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import 'normalize.css';
import 'moment/locale/nl-be.js';
import draggable from 'vuedraggable';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import VeeValidate from 'vee-validate';
import nl from 'vee-validate/dist/locale/nl';
import VueI18n from 'vue-i18n';
import { getCurrentUser } from '@/helpers';
import { consumePassedToken } from '@/util/session-sync';

// Check if another tab has explicitly passed a session token to continue
// with in this tab.
consumePassedToken();

Vue.use(VueI18n);
const i18n = new VueI18n({ locale: 'nl' });
Vue.use(VeeValidate, { i18n, dictionary: { nl } });

library.add(fas);
library.add(far);
library.add(fal);

Vue.component('font-awesome-icon', FontAwesomeIcon);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('draggable', draggable);
Vue.component('v-select', VueSelect);

axios.defaults.baseURL = process.env.VUE_APP_API;

function cleanToken(token) {
  return token && (token.startsWith('Bearer ') || token.startsWith('bearer '))
    ? token.substr(7)
    : token;
}

function setAxiosToken(token) {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + cleanToken(token);
}

setAxiosToken(sessionStorage.getItem('token'));

axios.interceptors.response.use(
  (response) => {
    if (response.headers.authorization) {
      sessionStorage.setItem(
        'token',
        cleanToken(response.headers.authorization)
      );
      setAxiosToken(response.headers.authorization);
    }
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      router.push('/login');
    }

    if (
      error.response &&
      error.response.status === 401 &&
      !error.request.responseURL.endsWith('two-factor')
    ) {
      sessionStorage.removeItem('token');
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const token = sessionStorage.getItem('token');

  if (authRequired && !token) {
    return next('/login');
  }

  setAxiosToken(token);

  next();
});

Vue.config.productionTip = false;

const vue = new Vue({
  router,
  render: (h) => h(App),
  data: {
    user: null,
  },
  created() {
    this.user = getCurrentUser();
  },
});

vue.$mount('#app');
