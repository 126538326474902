<template>
  <transition name="modal">
    <div class="mask">
      <div class="modal">
        <div>
          <font-awesome-icon icon="upload" class="success-icon" size="4x" />
        </div>
        <div class="title">Upload migratiebestand</div>
        <div class="content">
          <div class="field">
            <label>Bestand</label>
            <label for="file" class="upload">Selecteer bestand</label>
            <input
              type="file"
              id="file"
              ref="file"
              @input="changeFile"
              accept=".json"
            />
            <span class="infofield">{{ filename }}</span>
            <font-awesome-icon
              v-if="filename"
              icon="times"
              class="remove"
              @click="clearFile"
            />
          </div>
          <div class="field">
            <label>Nieuwe naam</label>
            <input type="text" v-model="title" size="40" />
          </div>
          <div class="field">
            <label>Update metadata van bijlages</label>
            <input type="checkbox" v-model="updateFileMetadata" />
          </div>
        </div>
        <div class="modal-buttons">
          <div class="col-12">
            <button
              class="action btn btn-dark"
              @click="confirm"
              :disabled="!filename || !title || isLoading"
            >
              <font-awesome-icon v-if="isLoading" icon="spinner" />
              <span v-else>Upload</span>
            </button>
            <button
              :disabled="isLoading"
              class="action btn btn-light"
              @click="cancel"
            >
              Annuleren
            </button>
          </div>
        </div>
        <div class="error row" v-if="error">
          {{ error }}
          <span v-if="errorData" class="error-data">
            {{ errorData }}
          </span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import api from '@/api';

export default {
  props: ['version'],
  data() {
    return {
      error: null,
      filename: null,
      filecontent: null,
      title: null,
      isLoading: false,
      errorData: null,
      updateFileMetadata: null,
    };
  },
  methods: {
    confirm() {
      this.isLoading = true;
      this.error = null;
      this.errorData = null;

      const settings = {
        update_file_metadata: this.updateFileMetadata,
      };

      api
        .uploadVersionMigration(this.title, this.filecontent, settings)
        .then(() => {
          this.$emit('close');
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            this.error = error.response.data.message;
            this.errorData = error.response.data?.metadata;
          } else {
            this.error = 'Er is iets misgegaan';
          }
        })
        .finally(() => (this.isLoading = false));
    },
    cancel() {
      this.$emit('close');
    },
    clearFile() {
      this.filename = null;
      this.filecontent = null;
      document.getElementById('file').value = '';
    },
    changeFile() {
      let file = this.$refs.file.files[0];

      if (!file) {
        this.filename = null;
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        this.filename = file.name;
        this.filecontent = reader.result;
      };
      reader.readAsText(file);
    },
  },
};
</script>

<style lang="scss">
.modal-buttons {
  text-align: center;
  padding: 15px 0 10px 0;

  .btn {
    width: 140px !important;
    margin: 0 5px !important;
  }
}
</style>

<style scoped lang="scss">
.mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  transition: opacity 0.3s ease;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $font-family-gitlab;
    width: 500px;
    margin: 0 auto;
    padding: 20px 30px 10px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    border-top: solid 3px $color-main;

    .questionmark {
      margin: 0 0 15px 0;
    }

    .question-icon {
      margin: 0 0 15px 0;
      transform: scale(0.77);
      color: $color-main;
    }

    .success-icon {
      margin: 0 0 15px 0;
      transform: scale(0.77);
      color: $color-main;
    }

    .error-icon {
      margin: 0 0 15px 0;
      transform: scale(0.77);
      color: #f52b2b;
    }

    .title {
      font-size: 24px;
      padding-bottom: 5px;
      color: #222222;
    }

    .content {
      text-align: center;
      font-size: 16px;
      color: #4e4e4e;
      padding: 30px 0 20px 0;
      width: 560px;
    }

    .error {
      width: calc(100% + 28px);
      margin: 10px 0 -10px 0;
      padding: 15px;
      font-size: 14px;
      color: #721c24;
      background-color: #f8d7da;
      border: 1px solid #f5c6cb;
    }

    .btn-ok {
      color: white;
      border-color: #1474a4;
      background-color: #1474a4;
    }

    .btn-ok:hover {
      background-color: #13557a;
    }

    .btn-cancel {
      color: black;
      border-color: #1474a4;
      background-color: white;
    }

    .btn-cancel:hover {
      background-color: rgba(20, 121, 174, 0.05);
    }

    input,
    textarea,
    select {
      border-radius: 3px;
      border: 1px solid lightgrey;
      font-size: 14px;
      padding: 4px 6px;
      min-height: 24px;
      margin: 0 10px 15px 10px;
      outline-style: none;
    }

    .field {
      display: flex;
      flex-direction: row;
      margin: 5px 0 10px 0;
      font-size: 14px;

      label:not(.upload) {
        text-align: right;
        margin: 6px 15px 0 0;
        width: 120px;
        color: grey;
        height: 24px;
      }

      label.upload {
        padding: 7px 14px;
        font-size: 14px;
        border-radius: 3px;
        white-space: nowrap;
        user-select: none;
        border: 1px solid #6a103a;
        background-color: white;
        color: #6a103a;
        cursor: pointer;
        margin-left: 10px;
        margin-right: 10px;
      }

      label.upload:hover {
        background-color: #f6f6f6;
      }

      #file {
        display: none;
      }

      input {
      }

      input:disabled {
        background-color: #f6f6f6;
      }

      .infofield {
        margin: 6px 0 6px 0;
      }

      .remove {
        margin: 8px;
        cursor: pointer;
      }

      .input-rule {
        width: 800px;
      }

      .input-text {
        margin: 0;
        width: 786px;
        height: 80px;
      }
    }
  }
}

/*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  /*-webkit-transform: scale(1.1);*/
  /*transform: scale(1.1);*/
}

.error-data {
  white-space: pre-wrap;
  font-family: monospace;
  max-height: 30rem;
  overflow-y: auto;
}
</style>
