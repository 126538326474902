<template>
  <div>
    <label v-if="label">{{ label }}</label>
    <template v-for="supportedLanguage in supportedLanguages">
      <template
        v-if="
          inputType === 'input' && supportedLanguage === language.toLowerCase()
        "
      >
        <input
          :disabled="!enabled"
          :key="supportedLanguage"
          :placeholder="languageFallback(supportedLanguage, model, valueKey)"
          :type="type"
          :value="model[valueKey][supportedLanguage]"
          @input="setValue(supportedLanguage, $event.target.value)"
        />
      </template>
      <template
        v-if="
          inputType === 'textarea' &&
          supportedLanguage === language.toLowerCase()
        "
      >
        <textarea
          :disabled="!enabled"
          :key="supportedLanguage"
          :placeholder="languageFallback(supportedLanguage, model, valueKey)"
          :value="model[valueKey][supportedLanguage]"
          @input="setValue(supportedLanguage, $event.target.value)"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { supportedLanguages, getValue } from '@/languages';

export default {
  props: [
    'language',
    'withLanguages',
    'model',
    'valueKey',
    'enabled',
    'type',
    'label',
    'inputType',
  ],
  computed: {
    supportedLanguages() {
      return this.withLanguages ?? supportedLanguages;
    },
    // See vue/no-mutating-props rule in .eslintrc
    localModel() {
      return this.model;
    },
  },
  methods: {
    setValue(language, value) {
      this.localModel[this.valueKey][language] = value;

      this.$emit('afterInputChanged', value);
    },
    languageFallback(language, item, key) {
      return getValue(this.supportedLanguages, language, item[key]);
    },
  },
};
</script>
