<template>
  <div class="container">
    <div class="subheader">
      <div class="title">{{ title }}</div>
      <div class="buttons">
        <button
          type="button"
          class="btn btn-invisible-grey"
          @click="revertOrder"
          v-if="changed"
          title="Volgorde herstellen"
        >
          herstellen
        </button>
        <button
          type="button"
          class="btn btn-dark"
          @click="saveOrder"
          v-if="changed"
        >
          Volgorde opslaan
        </button>
        <button
          type="button"
          class="btn btn-dark"
          @click="exit"
          v-if="!changed"
        >
          Sluit bewerken
        </button>
      </div>
    </div>
    <div class="scroller">
      <div class="sections cards" v-if="initialized">
        <draggable
          v-model="sections"
          @start="startDrag"
          @end="endDrag"
          :forceFallback="true"
          animation="300"
          ghostClass="ghost"
          delay="50"
          touchStartThreshold="30"
        >
          <transition-group
            type="transition"
            :name="dragging ? '' : 'draglist'"
          >
            <div
              v-for="section in sections"
              :key="section.id"
              @click="open(section)"
              class="section card"
              :class="{ active: isActive(section) }"
            >
              {{ sectionTitle(section) }}
            </div>
          </transition-group>
        </draggable>
        <div
          @click="openNewSection()"
          class="section card new"
          :class="{ active: sectionId <= 0 }"
          :title="newLabel"
        >
          <font-awesome-icon class="icon" icon="plus-circle" size="2x" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api, { getQuestionSections } from '@/api';
import _ from 'lodash';
import languages, { getValue, supportedReportLanguages } from '@/languages';

export default {
  props: ['sectionId', 'resource', 'version', 'language', 'reportLanguage'],
  data() {
    return {
      sections: null,
      originalSections: null,
      dragging: false,
    };
  },
  created() {
    if (!this.enabled) {
      this.$router.push({
        name: this.resource,
        params: { versionId: this.version.id },
      });
    }
    this.load();
  },
  computed: {
    initialized() {
      return this.sections !== null;
    },
    changed() {
      return !_.isEqual(this.sections, this.originalSections);
    },
    enabled() {
      return this.version.open;
    },
    title() {
      return this.resource === 'exports' ? 'Bewerk exports' : 'Bewerk secties';
    },
    newLabel() {
      return this.resource === 'exports'
        ? 'Nieuwe export toevoegen'
        : 'Nieuwe sectie toevoegen';
    },
    sectionsRouteName() {
      let routeNames = {
        templates: 'templatessections',
        rules: 'rulessections',
        questions: 'questionssections',
        exports: 'exports',
        files: 'filesections',
      };
      return routeNames[this.resource];
    },
    closeRouteName() {
      let routeNames = {
        templates: 'templates',
        rules: 'rules',
        questions: 'questions',
        exports: 'exportfields',
        files: 'files',
      };
      return routeNames[this.resource];
    },
  },
  methods: {
    sectionTitle(section) {
      if (this.resource === 'questions') {
        return languages.getValue(this.language, section, 'title');
      } else if (this.resource === 'templates') {
        return getValue(
          supportedReportLanguages,
          this.reportLanguage,
          section.label
        );
      }

      return section.title || section.label || section.name;
    },
    load() {
      let calls = {
        questions: getQuestionSections,
        rules: api.getRuleSections,
        templates: api.getTemplateSections,
        exports: api.getExportTemplates,
        files: api.getFileSections,
      };
      calls[this.resource](this.version).then((response) => {
        this.sections = response.data;
        this.originalSections = _.cloneDeep(this.sections);
      });
    },
    open(section) {
      this.$router.push({
        name: this.sectionsRouteName,
        params: { versionId: this.version.id, sectionId: section.id },
      });
    },
    openNewSection() {
      this.$router.push({
        name: this.sectionsRouteName,
        params: { versionId: this.version.id, sectionId: 0 },
      });
    },
    exit() {
      this.$router.push({
        name: this.closeRouteName,
        params: { versionId: this.version.id },
      });
    },
    isActive(section) {
      return section.id === parseInt(this.sectionId);
    },
    startDrag() {
      this.dragging = true;
    },
    endDrag() {
      this.dragging = false;
    },
    saveOrder() {
      let calls = {
        questions: api.saveQuestionSectionsOrder,
        rules: api.saveRuleSectionsOrder,
        templates: api.saveTemplateSectionsOrder,
        exports: api.saveExportTemplatesOrder,
        files: api.saveFileSectionsOrder,
      };

      let call;
      if (this.resource === 'exports' || this.resource === 'files') {
        call = calls[this.resource](this.sections);
      } else {
        call = calls[this.resource](this.version, this.sections);
      }
      call.then(() => (this.originalSections = _.cloneDeep(this.sections)));
    },
    revertOrder() {
      this.sections = _.cloneDeep(this.originalSections);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  .scroller {
    overflow-y: auto;

    .sections {
      .draglist-move {
        transition: transform 0.7s;
      }

      font-size: 14px;
      font-weight: 400;

      .section {
        background-color: white;
        font-weight: 400;
        font-size: 14px;
        height: 30px;
        padding: 5px 15px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 0.25rem;
        text-decoration: none;
        color: black;
        cursor: pointer;

        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
</style>
