var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"subheader"},[(_vm.section)?_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.section.name))]):_vm._e(),_c('div',{staticClass:"buttons"},[(_vm.changed)?_c('button',{staticClass:"btn btn-invisible-grey",attrs:{"type":"button","title":"Volgorde herstellen"},on:{"click":_vm.revertOrder}},[_vm._v(" herstellen ")]):_vm._e(),(_vm.changed)?_c('button',{staticClass:"btn btn-dark",attrs:{"type":"button"},on:{"click":_vm.saveOrder}},[_vm._v(" Volgorde opslaan ")]):_vm._e()])]),_c('div',{staticClass:"scroller"},[(_vm.isLoaded)?_c('div',{staticClass:"exportfields cards",class:{ dragging: _vm.dragging }},[_c('draggable',{attrs:{"forceFallback":true,"animation":"300","ghostClass":"ghost","delay":"50","touchStartThreshold":"30","disabled":!_vm.version.open},on:{"start":_vm.startDrag,"end":_vm.endDrag},model:{value:(_vm.exportFields),callback:function ($$v) {_vm.exportFields=$$v},expression:"exportFields"}},[_c('transition-group',{attrs:{"type":"transition","name":_vm.dragging ? '' : 'draglist'}},_vm._l((_vm.exportFields),function(exportField){return _c('div',{key:exportField.id,staticClass:"files card",class:{ active: _vm.isActive(exportField) },on:{"click":function($event){return _vm.open(exportField)}}},[_c('div',{staticClass:"col left"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(exportField.name))]),_c('div',{staticClass:"ref"},[_vm._v(_vm._s(_vm.referenceLabel(exportField)))])]),_c('div',{staticClass:"col right"})])}),0)],1),(_vm.questions && _vm.rules)?_c('router-link',{staticClass:"files card new",class:{ active: _vm.fieldId <= 0 },attrs:{"to":{
          name: 'exportfields',
          params: {
            sectionId: _vm.sectionId,
            fieldId: 0,
          },
        },"title":"Nieuw veld toevoegen"}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"plus-circle","size":"2x"}})],1):_vm._e()],1):_vm._e(),(!_vm.isLoaded && _vm.sectionId)?_c('div',{staticClass:"center"},[_c('font-awesome-icon',{attrs:{"icon":"spinner","size":"2x"}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }