<template>
  <div class="container">
    <DuplicateExportFieldsModal
      v-if="showDuplicateExportModal"
      :exportTemplateId="currentSectionId"
      :action="duplicateExportTemplate"
      @close="showDuplicateExportModal = false"
      @refresh="editCounter++ && load()"
    />

    <div class="subheader">
      <div class="title">{{ title }}</div>
      <button
        class="btn btn-invisible-grey no-padding"
        v-if="hasDownload"
        @click="downloadPdf"
      >
        <font-awesome-icon
          icon="file-pdf"
          title="Download vragenlijst als pdf"
          v-if="!downloading"
        />
        <font-awesome-icon icon="spinner" v-if="downloading" />
      </button>

      <button
        class="btn btn-invisible-grey no-padding"
        v-if="hasDownload"
        @click="downloadExcel"
      >
        <font-awesome-icon
          icon="file-excel"
          title="Download vragenlijst als excel"
          v-if="!downloadingExcel"
        />
        <font-awesome-icon icon="spinner" v-if="downloadingExcel" />
      </button>

      <button
        v-if="resource === 'exports' && currentSectionId"
        type="button"
        class="btn btn-invisible-grey"
        title="Export template dupliceren"
        @click="showDuplicateExportModal = true"
      >
        <font-awesome-icon icon="copy" />
      </button>

      <div class="buttons" v-if="enabled">
        <button class="btn btn-invisible-grey" @click="edit">bewerken</button>
      </div>
    </div>
    <div class="scroller">
      <div class="center" v-if="isLoading">
        <font-awesome-icon icon="spinner" size="2x" />
      </div>
      <div class="sections" v-else-if="initialized" :key="editCounter">
        <div
          v-for="(node, index) in tree"
          :key="index + node.label + node.open"
          class="section"
          @click.stop.prevent="openSection(node)"
        >
          <div class="section-header" :class="{ active: isActive(node) }">
            <font-awesome-icon
              class="icon"
              icon="caret-up"
              size="1x"
              v-if="node.open && node.children.length > 0"
            />
            <font-awesome-icon
              class="icon"
              icon="caret-down"
              size="1x"
              v-if="!node.open && node.children.length > 0"
            />
            {{ node.label }}
          </div>

          <div
            class="section-children"
            v-if="node.open && node.children.length > 0"
          >
            <div
              v-for="(node, index) in node.children"
              :key="index + node.label + node.open"
              class="section"
              :class="{ active: isActive(node), inactive: !isActive(node) }"
              @click.stop.prevent="openSection(node)"
            >
              <div class="section-header" :class="{ active: isActive(node) }">
                <font-awesome-icon
                  class="icon"
                  icon="caret-up"
                  size="1x"
                  v-if="node.open && node.children.length > 0"
                />
                <font-awesome-icon
                  class="icon"
                  icon="caret-down"
                  size="1x"
                  v-if="!node.open && node.children.length > 0"
                />
                {{ node.label }}
              </div>

              <div
                class="section-children"
                v-if="node.open && node.children.length > 0"
              >
                <div
                  v-for="(node, index) in node.children"
                  :key="index + node.label + node.open"
                  class="section"
                  :class="{ active: isActive(node), inactive: !isActive(node) }"
                  @click.stop.prevent="openSection(node)"
                >
                  <div
                    class="section-header"
                    :class="{ active: isActive(node) }"
                  >
                    {{ node.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import api, { getQuestionSections } from '@/api';
import languages, { getValue, supportedReportLanguages } from '@/languages';
import DuplicateExportFieldsModal from '@/components/DuplicateExportFieldsModal';

export default {
  props: ['resource', 'sectionId', 'version', 'language', 'reportLanguage'],
  components: {
    DuplicateExportFieldsModal,
  },
  data() {
    return {
      downloading: false,
      downloadingExcel: false,
      editCounter: 0,
      isLoading: false,
      sections: null,
      showDuplicateExportModal: null,
      tree: [],
    };
  },
  created() {
    this.load();
  },
  computed: {
    currentSectionId() {
      return this.$route.params.sectionId;
    },
    initialized() {
      return this.sections !== null;
    },
    enabled() {
      return this.version.open;
    },
    hasDownload() {
      return this.resource === 'questions';
    },
    title() {
      return this.resource === 'exports' ? 'Exports' : 'Secties';
    },
  },
  watch: {
    initialized() {
      if (this.initialized) {
        this.calculateTree();
      }
    },
    sectionId() {
      if (this.tree.length > 0) {
        this.tree.forEach((node) => this.openNodeIfActive(node));
      }
    },
    language() {
      if (this.initialized) {
        this.calculateTree();
      }
    },
    reportLanguage() {
      if (this.initialized) {
        this.calculateTree();
      }
    },
  },
  methods: {
    downloadPdf() {
      this.downloading = true;
      api
        .exportQuestionsPdf(this.version, this.language)
        .then(() => (this.downloading = false));
    },
    async duplicateExportTemplate(exportTemplateId, name) {
      await api.duplicateExportTemplate(exportTemplateId, name);

      await this.load();
    },
    downloadExcel() {
      this.downloadingExcel = true;
      api
        .exportQuestionsExcel(this.version, this.language)
        .then(() => (this.downloadingExcel = false));
    },
    openNodeIfActive(node) {
      let childActive = false;
      node.children.forEach((child) => {
        if (this.openNodeIfActive(child)) {
          childActive = true;
        }
      });

      if (childActive || this.isActive(node)) {
        node.open = true;
        return true;
      }
      return false;
    },
    toggleNode(node) {
      node.open = !node.open;
      this.editCounter++;
    },
    openSection(node) {
      if (node.children.length > 0) {
        this.toggleNode(node);
      }
      if (node.section) {
        this.open(node.section);
      }
    },
    async load() {
      this.isLoading = true;
      this.sections = null;

      if (this.resource === 'questions') {
        this.sections = (await getQuestionSections(this.version)).data;
      }

      if (this.resource === 'rules') {
        await api
          .getRuleSections(this.version)
          .then((response) => (this.sections = response.data));
      }

      if (this.resource === 'templates') {
        await api.getTemplateSections(this.version).then((response) => {
          this.sections = response.data;
        });
      }

      if (this.resource === 'exports') {
        await api
          .getExportTemplates()
          .then((response) => (this.sections = response.data));
      }

      if (this.resource === 'files') {
        await api
          .getFileSections()
          .then((response) => (this.sections = response.data));
      }

      this.editCounter++;
      this.isLoading = false;
    },
    getLabel(section) {
      if (this.resource === 'questions') {
        return languages.getValue(this.language, section, 'title');
      } else if (this.resource === 'templates') {
        return getValue(
          supportedReportLanguages,
          this.reportLanguage,
          section.label
        );
      }

      return section.title || section.label || section.name;
    },
    open(section) {
      let routeNames = {
        templates: 'templates',
        rules: 'rules',
        questions: 'questions',
        exports: 'exportfields',
        files: 'files',
      };

      this.$router.push({
        name: routeNames[this.resource],
        params: { versionId: this.version.id, sectionId: section.id },
      });
    },
    isActive(node) {
      return node.section && node.section.id === parseInt(this.sectionId);
    },
    edit() {
      let routeNames = {
        templates: 'templatessections',
        rules: 'rulessections',
        questions: 'questionssections',
        exports: 'exports',
        files: 'filesections',
      };

      this.$router.push({
        name: routeNames[this.resource],
        params: { versionId: this.version.id },
      });
    },
    calculateTree() {
      if (this.sections === null) {
        return null;
      }

      let tree = [];
      let path = [];

      this.sections.forEach((section) => {
        // TODO What's the effect of our translation wrapper on this tree?
        const label = this.getLabel(section);
        const segments = label ? label.split(' - ') : [];

        let mismatch = false;
        let parent = null;

        segments.forEach((segment, index) => {
          if (
            !mismatch &&
            index < segments.length - 1 &&
            index < path.length &&
            segment === path[index].label
          ) {
            parent = path[index];
          } else {
            mismatch = true;
          }

          if (mismatch) {
            let node = { label: segment, open: false, children: [] };
            if (index === segments.length - 1) {
              node.section = section;
            }

            if (parent) {
              parent.children.push(node);
              path = path.slice(0, index);
              if (this.isActive(node)) {
                path.forEach((p) => (p.open = true));
              }
              path.push(node);
              parent = node;
            } else {
              tree.push(node);
              path = [node];
              parent = node;
            }
          }
        });
      });

      this.tree = tree;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  .scroller {
    overflow-y: auto;

    .sections {
      font-size: 14px;
      font-weight: 400;
      padding: 8px 8px;

      .section-header {
        padding: 7px 12px;
        background-color: white;
        color: #747474;
        cursor: pointer;
      }

      .section-header:hover {
        background-color: $bg-color-light-grey;
      }

      .section-header {
        .icon {
          color: #929292;
          transform: scale(0.9);
        }
      }

      .section-header.active {
        color: #4e4e4e;
        font-weight: 500;
      }

      .section-children {
        padding-left: 25px;
      }
    }
  }
}
</style>
